import { Button } from '@compass/components'
import { Add, ExpandMore } from '@mui/icons-material'
import { Collapse, Paper, Skeleton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import clsx from 'clsx'
import { addDays, addMonths } from 'date-fns'
import { formatDate } from 'features/commonUI'
import { BillingData, Product, Promotion } from 'models'
import { Fragment, useMemo, useState } from 'react'

import { DEFAULT_TRIAL_DAYS, SUBSCRIPTION_DISPLAY_PLANS, SubscriptionDisplayPlan } from '../../const'
import { applyDiscount, formatMoney, formatNum, formatPriceInDollars } from '../../helpers/format'
import { pluralize } from '../../helpers/pluralize'
import { Feature, FeatureList } from '../FeatureList/FeatureList'
import styles from './PurchaseSummary.module.scss'

export interface PurchaseSummaryProps {
  displayPlan?: SubscriptionDisplayPlan
  billingAnchor?: Date
  billingData?: BillingData
  coupon?: Promotion['coupon']
  isTrial?: boolean
  features?: Feature[]
  action?: string
  onAction?: () => void
  className?: string
}

export function PurchaseSummary({
  displayPlan,
  billingAnchor,
  billingData,
  coupon,
  isTrial,
  features,
  action,
  onAction,
  className,
}: PurchaseSummaryProps) {
  const today = new Date(Date.now())
  const trialStart = isTrial ? today : undefined
  const startOfBillingCycle = isTrial ? addDays(billingAnchor ?? today, DEFAULT_TRIAL_DAYS) : billingAnchor ?? today
  const firstPaymentDate = addMonths(startOfBillingCycle, 1)

  const [expanded, setExpanded] = useState(false)

  const prepaidThousands = billingData && `${formatNum(billingData.prepaidQuantity / 1000)}k`
  const pluralizedUnitLabel = billingData && `${billingData.unitLabel}s`

  const priceComponents = useMemo(
    () => [
      {
        label: 'Base cost',
        price: !billingData ? (
          <Skeleton variant='rectangular' width={256} />
        ) : (
          `${formatPriceInDollars(applyDiscount(billingData.flatAmount, coupon?.percentOff))}/month for ${formatNum(
            billingData.prepaidQuantity
          )} ${pluralizedUnitLabel}`
        ),
      },
      {
        label: 'Usage cost',
        price: !billingData ? (
          <Skeleton variant='rectangular' width={256} />
        ) : (
          getOverageChargesText(billingData.product, applyDiscount(billingData.overagePrice, coupon?.percentOff))
        ),
      },
    ],
    [billingData, pluralizedUnitLabel, coupon]
  )

  return (
    <Paper className={clsx(styles.root, className)}>
      {displayPlan && (
        <hgroup className={styles.header}>
          <Typography variant='h2' component='h1'>
            {SUBSCRIPTION_DISPLAY_PLANS[displayPlan].name}
          </Typography>
          <Typography variant='body3'>
            {displayPlan === SubscriptionDisplayPlan.Pro
              ? 'Identify more visitors with accuracy'
              : 'Make smarter decisions with device intelligence'}
          </Typography>
        </hgroup>
      )}

      <dl className={styles.priceComponents}>
        {priceComponents.map(({ label, price }, index) => (
          <Fragment key={label}>
            {index > 0 && <Add fontSize='small' className={styles.faded} />}

            <span className={styles.priceComponent}>
              <Typography variant='body3' className={styles.faded}>
                {label}
                {coupon && '*'}
              </Typography>
              <Typography variant='semiBody3' component='dt' style={{ fontSize: '14px' }}>
                {price}
              </Typography>
            </span>
          </Fragment>
        ))}
      </dl>

      {features && <FeatureList features={features} />}

      <div>
        <div
          title={`${expanded ? 'Collapse' : 'Expand'} plan details`}
          role='button'
          onClick={() => setExpanded(!expanded)}
          className={styles.planHeader}
        >
          <Typography variant='body3' component='h2'>
            Plan Details
          </Typography>
          <ExpandMore className={clsx(styles.expandMore, { [styles.expandLess]: expanded })} />
        </div>

        <Collapse in={expanded}>
          <div className={styles.notes}>
            <Table className={styles.table}>
              <TableBody>
                {trialStart && (
                  <TableRow>
                    <TableCell>Start of trial</TableCell>
                    <TableCell>{formatDate(trialStart)}</TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell>Start of first billing cycle</TableCell>
                  <TableCell>{formatDate(startOfBillingCycle)}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>First payment date</TableCell>
                  <TableCell>{formatDate(firstPaymentDate)}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Amount due</TableCell>
                  <TableCell>
                    {!billingData ? (
                      <Skeleton variant='rectangular' width={60} />
                    ) : (
                      formatPriceInDollars(applyDiscount(isTrial ? 0 : billingData.flatAmount, coupon?.percentOff))
                    )}
                  </TableCell>
                </TableRow>

                {isTrial && (
                  <TableRow>
                    <TableCell>Amount due {formatDate(firstPaymentDate)}</TableCell>
                    <TableCell>
                      {!billingData ? (
                        <Skeleton variant='rectangular' width={60} />
                      ) : (
                        formatPriceInDollars(applyDiscount(billingData.flatAmount, coupon?.percentOff))
                      )}
                    </TableCell>
                  </TableRow>
                )}

                {coupon && (
                  <TableRow>
                    <TableCell>Discount</TableCell>
                    <TableCell>
                      {pluralize(coupon.durationInMonths ?? 0, 'month')} - {coupon.percentOff}% off
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            {!billingData ? (
              <Skeleton variant='rectangular' height={48} />
            ) : (
              <>
                <Typography variant='body3' className={styles.note}>
                  Your subscription will automatically renew every month.
                </Typography>

                {coupon ? (
                  <Typography variant='body3' className={styles.note}>
                    *For the first {pluralize(coupon.durationInMonths ?? 0, 'month')}, you will be charged{' '}
                    {formatPriceInDollars(applyDiscount(billingData.flatAmount, coupon.percentOff))} per month for{' '}
                    {prepaidThousands} {pluralizedUnitLabel}. {pluralizedUnitLabel} after {prepaidThousands} will be
                    charged at {formatMoney(applyDiscount(billingData.overagePrice, coupon.percentOff) / 100)}. After{' '}
                    {pluralize(coupon.durationInMonths ?? 0, 'month')}, you will be charged{' '}
                    {formatPriceInDollars(billingData.flatAmount)} per month for {prepaidThousands}{' '}
                    {pluralizedUnitLabel} and {formatMoney(billingData.overagePrice / 100)} for {pluralizedUnitLabel}{' '}
                    after {prepaidThousands}.
                  </Typography>
                ) : (
                  <Typography variant='body3' className={styles.note}>
                    You will be charged {formatPriceInDollars(billingData.flatAmount)} per month for {prepaidThousands}{' '}
                    {pluralizedUnitLabel}. {pluralizedUnitLabel} after {prepaidThousands} will be charged at{' '}
                    {formatMoney(billingData.overagePrice / 100)} per {billingData.unitLabel}.
                  </Typography>
                )}

                <Typography variant='body3' className={styles.note}>
                  You may cancel at any time.
                </Typography>
              </>
            )}
          </div>
        </Collapse>
      </div>

      {action && onAction && (
        <Button
          size='lg'
          onPress={onAction}
          isDisabled={!billingData}
          data-testid='purchaseSummarySubmit'
          fullWidth
          className='px-6 py-2'
        >
          {action}
        </Button>
      )}
    </Paper>
  )
}

export function getOverageChargesText(product: Product, priceInCents: number) {
  switch (product) {
    case Product.ApiCalls:
      return `${formatPriceInDollars(priceInCents * 1000, false)}/month for 1,000 additional API calls`
    case Product.UniqueVisitors:
      return `${formatPriceInDollars(priceInCents, true)}/month for each unique visitor`
    default:
      return ''
  }
}
