import { Button } from '@compass/components'
import { Close, CloseOutlined, InfoOutlined } from '@mui/icons-material'
import { Box, IconButton, InputLabel, TextField, Tooltip, Typography } from '@mui/material'
import { differenceInCalendarDays, endOfDay, startOfDay } from 'date-fns'
import { ChartDateRangePicker } from 'features/subscriptionOverview'
import { getErrorParams } from 'helpers/data'
import { IPV4_CIDR_REGEX, IPV6_CIDR_REGEX, requestIdRegex, visitorIdRegex } from 'helpers/regex'
import { useLogPageView } from 'helpers/vendor'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import toPairs from 'lodash/toPairs'
import { VisitsFilter } from 'models'
import { ampli } from 'models/ampli'
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Filter } from '../../../../components/Filter/Filter'
import { ACCEPTED_DATE_RANGE_LABELS, DateRangeLabel } from '../../../../helpers/dateRange'
import { IdentificationUrlKeys, useQueryParamSync } from '../../hooks/useFormQueryParamSync'
import { before, defaultValues, since, today } from '../../utils/visitorFormUtils'
import styles from './VisitHistoryFilter.module.scss'

export interface VisitHistoryFilterProps {
  form: UseFormReturn<VisitsFilter>
  onFilterChange: (isFiltering: boolean) => void
}
export function VisitHistoryFilter({ form, onFilterChange }: VisitHistoryFilterProps) {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setIsOpen(true)
    ampli.attributeFilterDialogOpened({ datasetFocus: 'Identification Events' })
  }, [])
  const handleClose = useCallback(() => setIsOpen(false), [])

  const {
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    control,
    formState: { errors, dirtyFields },
  } = form

  // These are externally controlled, virtual fields.
  register('since')
  register('before')

  const [submittedValues, setSubmittedValues] = useState<VisitsFilter>(defaultValues)
  const dirtyCount = differenceWith(
    toPairs(omit(submittedValues, ['since', 'before'])),
    toPairs(omit(defaultValues, ['since', 'before'])),
    isEqual
  ).length

  useEffect(() => {
    onFilterChange(dirtyCount > 0)
  }, [dirtyCount, onFilterChange])

  const onSubmit = useCallback<(values: VisitsFilter) => void>(
    (values) => {
      setSubmittedValues(values)
      handleClose()
    },
    [handleClose]
  )

  const onManualSubmit = useCallback<(values: VisitsFilter) => void>(
    (values) => {
      onSubmit(values)
      const filters = {
        'IP address': values[IdentificationUrlKeys.IpAddress] != null && values[IdentificationUrlKeys.IpAddress] !== '',
        URL: values[IdentificationUrlKeys.Url] != null && values[IdentificationUrlKeys.Url] !== '',
        linkedID: values[IdentificationUrlKeys.LinkedId] != null && values[IdentificationUrlKeys.LinkedId] !== '',
        requestID: values[IdentificationUrlKeys.RequestId] != null && values[IdentificationUrlKeys.RequestId] !== '',
        visitorID: values[IdentificationUrlKeys.VisitorId] != null && values[IdentificationUrlKeys.VisitorId] !== '',
        origin: values[IdentificationUrlKeys.Origin] != null && values[IdentificationUrlKeys.Origin] !== '',
      }
      const setFilters = Object.entries(filters)
        .filter(([_, value]) => value === true)
        .map(([key]) => key as keyof typeof filters)
      ampli.attributeFilterApplied({ datasetFocus: 'Identification Events', attributeFilters: setFilters })
    },
    [onSubmit]
  )

  const queryParams = useQueryParamSync(form, onSubmit)
  const startDate = queryParams.since ? new Date(parseInt(queryParams.since)) : since
  const endDate = queryParams.before ? new Date(parseInt(queryParams.before)) : before

  useTrackInitialFilterParams(startDate, endDate)

  const externalSubmit = useCallback(() => {
    handleSubmit(onSubmit)()
  }, [handleSubmit, onSubmit])

  function handleClear() {
    reset()
    externalSubmit()
  }

  return (
    <>
      <ChartDateRangePicker
        initialDateRange={{
          startDate,
          endDate,
        }}
        initialMaxDate={before}
        maxRange={{ months: 3 }}
        today={today}
        onOpen={() => ampli.dateFilterSelectorOpened({ datasetFocus: 'Identification Events' })}
        onApplyRange={(range, label) => {
          if (range.startDate === undefined || range.endDate === undefined) {
            return
          }

          const startValue = range.startDate
            ? label === 'Last 24 hours'
              ? range.startDate.valueOf()
              : startOfDay(range.startDate).valueOf()
            : undefined
          const endValue = range.endDate ? endOfDay(range.endDate).valueOf() : undefined

          setValue('since', startValue)
          setValue('before', endValue)
          externalSubmit()

          ampli.dateFilterApplied({
            datasetFocus: 'Identification Events',
            dateRange:
              label !== undefined && ACCEPTED_DATE_RANGE_LABELS.includes(label) ? (label as DateRangeLabel) : 'Other',
            periodLengthDays: differenceInCalendarDays(range.endDate, range.startDate),
          })
        }}
        className={styles.datePicker}
      />
      <Filter
        triggerContent={
          <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
            Filter
            {dirtyCount > 0 ? (
              <div className={styles.dirtyCount}>
                <Typography variant='bodyS' color='inherit'>
                  {dirtyCount}
                </Typography>
              </div>
            ) : null}
          </Box>
        }
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        containerClassName={styles.paper}
      >
        <form onSubmit={handleSubmit(onManualSubmit)} className={styles.form} data-testid='visits-filter-form'>
          <header className={styles.header}>
            <Typography variant='h2' component='h2'>
              Filter by
            </Typography>

            <IconButton onClick={handleClose} title='Cancel' className={styles.closeButton}>
              <CloseOutlined />
            </IconButton>
          </header>

          <div className={styles.content}>
            {FILTER_CONFIGS.map(({ key, label, description, placeholder, validate }) => (
              <Fragment key={key}>
                <InputLabel htmlFor={key} className={styles.inputLabelContainer}>
                  <Typography variant='bodyM' className={styles.inputLabel}>
                    {label}
                  </Typography>
                  {description ? (
                    <Tooltip
                      className={styles.inputLabelTooltip}
                      title={
                        <Typography variant='bodyS' color='inherit'>
                          {description}
                        </Typography>
                      }
                    >
                      <Typography variant='bodyM' className={styles.inputLabelInfoIcon}>
                        <InfoOutlined fontSize='inherit' />
                      </Typography>
                    </Tooltip>
                  ) : null}
                </InputLabel>
                <Controller
                  name={key}
                  control={control}
                  rules={{ validate }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id={key}
                      variant='outlined'
                      placeholder={placeholder}
                      spellCheck={false}
                      onChange={onChange}
                      value={value}
                      {...getErrorParams(key, errors)}
                      InputProps={{
                        endAdornment: dirtyFields[key] ? (
                          <Tooltip title='Clear filter' arrow placement='top'>
                            <IconButton onClick={() => resetField(key)} edge='end' size='small'>
                              <Close />
                            </IconButton>
                          </Tooltip>
                        ) : null,
                      }}
                      className={styles.input}
                    />
                  )}
                />
              </Fragment>
            ))}
          </div>

          <footer className={styles.footer}>
            {dirtyCount > 0 ? (
              <Button variant='ghost' onPress={handleClear}>
                Clear all
              </Button>
            ) : null}
            <Button type='submit' aria-label='Apply filters'>
              Apply
            </Button>
          </footer>
        </form>
      </Filter>
    </>
  )
}

type FilterConfig = {
  key: keyof VisitsFilter
  label: string
  description?: ReactNode
  placeholder?: string
  validate?: RegisterOptions['validate']
}
const FILTER_CONFIGS: FilterConfig[] = [
  {
    key: 'ipAddress',
    label: 'IP Address',
    description:
      'Find all events originating from the specified IP address, or use CIDR notation to match IP address range.',
    placeholder: '127.0.0.0',
    validate: (ip: string) =>
      ip === '' ||
      (ip.includes('.') ? IPV4_CIDR_REGEX.test(ip) : IPV6_CIDR_REGEX.test(ip)) ||
      'The entered IP is not formatted correctly. Example: 192.168.1.0/22',
  },
  {
    key: 'url',
    label: 'URL',
    description: 'Find all web and mobile events by reported URL and package identifier, respectively.',
    placeholder: 'https://example.com/landing-page',
    validate: (url) => url.length <= 10_000 || 'The maximum character limit is 10,000.',
  },
  {
    key: 'origin',
    label: 'Origin',
    description: 'Find all events that were reported for the given origin. Enter website domain or mobile app bundle.',
    placeholder: 'example.com',
    validate: (origin) => origin.length <= 10_000 || 'The maximum character limit is 10,000.',
  },
  {
    key: 'linkedId',
    label: 'Linked ID',
    description: 'Find all events that were reported with the given linked ID.',
    placeholder: 'user_23567864',
    validate: (id) => id.length <= 256 || 'The maximum character limit is 256.',
  },
  {
    key: 'requestId',
    label: 'Request ID',
    description: 'Find an event by request ID.',
    placeholder: '1701234567890.ABcDef',
    validate: (id) => id === '' || requestIdRegex.test(id) || 'The provided ID is not formatted correctly.',
  },
  {
    key: 'visitorId',
    label: 'Visitor ID',
    description: 'Find all events that were reported with the given visitor ID.',
    placeholder: 'B4549twNaClX4RDz8TUr',
    validate: (id) => id === '' || visitorIdRegex.test(id) || 'The provided ID is not formatted correctly.',
  },
]

function useTrackInitialFilterParams(startDate: Date, endDate: Date) {
  const history = useHistory()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchOnLoad = useMemo(() => history.location.search, [])

  useLogPageView(() => {
    const searchParams = new URLSearchParams(searchOnLoad)

    const since = searchParams.get(IdentificationUrlKeys.Since)
    const before = searchParams.get(IdentificationUrlKeys.Before)
    const initialStartDate = since ? new Date(parseInt(since)) : startDate
    const initialEndDate = before ? new Date(parseInt(before)) : endDate

    const values = {
      'IP address': searchParams.has(IdentificationUrlKeys.IpAddress),
      URL: searchParams.has(IdentificationUrlKeys.Url),
      linkedID: searchParams.has(IdentificationUrlKeys.LinkedId),
      requestID: searchParams.has(IdentificationUrlKeys.RequestId),
      visitorID: searchParams.has(IdentificationUrlKeys.VisitorId),
      origin: searchParams.has(IdentificationUrlKeys.Origin),
    }
    const setFilters = Object.entries(values)
      .filter(([_, value]) => value === true)
      .map(([key]) => key as keyof typeof values)

    if (setFilters.length > 0) {
      ampli.dataWithFiltersAppliedViewed({
        datasetFocus: 'Identification Events',
        attributeFilters: setFilters,
        periodLengthDays: differenceInCalendarDays(initialEndDate, initialStartDate),
      })
    }
  })
}
