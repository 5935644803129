import { Button, LinkButton } from '@compass/components'
import { Add, Edit } from '@mui/icons-material'
import { Alert, AlertTitle, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { format } from 'date-fns'
import { Dialog, DialogTitle } from 'features/commonUI'
import { useCallback, useMemo, useState } from 'react'

import { DOCS_INTEGRATIONS, SUPPORT_EMAIL_MAILTO, SUPPORT_PAGE_URL } from '../../../../const'
import { CloudflareIntegration, ProxyIntegrationRequests } from '../../../../models'
import { extractCloudflareAuthError } from '../../cloudflareHelpers'
import CloudflareConfigurationValues from '../../components/CloudflareConfigurationValues/CloudflareConfigurationValues'
import { IntegrationCloudflareEditProps } from '../../components/IntegrationCloudflareModal/Edit/IntegrationCloudflareEdit'
import IntegrationCloudflareModal from '../../components/IntegrationCloudflareModal/IntegrationCloudflareModal'
import Logo from '../../components/IntegrationMetadata/icons/cloudflare.svg'
import IntegrationPage from '../../components/IntegrationPage/IntegrationPage'
import { IntegrationPageSectionDefinition } from '../../components/IntegrationPage/IntegrationPageSection/IntegrationPageSection'
import { IntegrationStatus } from '../../components/IntegrationPage/types'
import ChartIcon from '../../components/UsageChart/Icons/ChartIcon.svg'
import { ProxyIntegrationUsageChart } from '../../components/UsageChart/ProxyIntegrationUsageChart'
import CloudflareDescription from './CloudflareDescription'
import styles from './IntegrationCloudflareContents.module.scss'
import { IntegrationCloudflareRemoval } from './IntegrationCloudflareRemoval'

export type IntegrationCloudflareContentsProps = Pick<IntegrationCloudflareEditProps, 'validateToken'> & {
  status: IntegrationStatus
  error?: string
  subscriptionId: string
  cloudflareData?: CloudflareIntegration
  isLoading?: boolean
  onUpdate?: (apiToken: string) => Promise<void>
  onRemove: () => Promise<void>
  isDialogLoading?: boolean
  requestsMade?: ProxyIntegrationRequests
}

const TOKEN_PERMISSIONS_URL = `${DOCS_INTEGRATIONS.cloudflare}#cloudflare-api-token`

export default function IntegrationCloudflareContents({
  status,
  cloudflareData,
  isLoading,
  subscriptionId,
  error,
  onUpdate,
  isDialogLoading,
  validateToken,
  onRemove,
  requestsMade,
}: IntegrationCloudflareContentsProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const openRemovalModal = useCallback(() => setIsRemoving(true), [])
  const closeRemovalModal = useCallback(() => setIsRemoving(false), [])
  const closeDialog = useCallback(() => setIsDialogOpen(false), [])
  const openDialog = useCallback(() => setIsDialogOpen(true), [])

  const chartVisible =
    requestsMade &&
    requestsMade.proxyRequests?.length > 0 &&
    requestsMade.proxyRequests.map((it) => it.value).reduce((sum, curr) => (sum += curr), 0) > 0
  const lastXDays = Math.min(requestsMade?.allRequests?.length ?? 0, 30)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const authError = extractCloudflareAuthError(error)

  const statusLabel = useMemo(() => {
    switch (status) {
      case IntegrationStatus.ChangesPending:
        return `The API token that was recently provided is being updated. If successful, this badge will change back to green! `

      case IntegrationStatus.RemovalPending:
        return 'We are removing the associated Cloudflare Workers in the background. Check back in a few minutes.'

      case IntegrationStatus.Installed:
        return `Kudos! The integration has been successfully installed.`

      case IntegrationStatus.IssueDetected:
        return authError ? (
          `The API token provided is not valid. See below for details.`
        ) : (
          <>
            Error occurred, please{' '}
            <Link color='inherit' href={SUPPORT_PAGE_URL} target='_blank'>
              contact support
            </Link>{' '}
            for more details.
          </>
        )

      default:
        return null
    }
  }, [authError, status])

  const alertProps = useMemo(() => {
    if (cloudflareData?.lastRemovalError) {
      return {
        title: 'Deletion failed',
        content: (
          <Typography>
            We couldn&apos;t remove this integration as you requested. Please try again or{' '}
            <Link href={SUPPORT_EMAIL_MAILTO} color='inherit'>
              contact support
            </Link>{' '}
            if this issue persists.
          </Typography>
        ),
      }
    }

    if (!error) {
      return undefined
    }

    if (authError) {
      return {
        title: `The Cloudflare API token you're using seems to be invalid`,
        content: (
          <>
            To fix this, please add a token that holds the{' '}
            <Link color='inherit' href={TOKEN_PERMISSIONS_URL} target='_blank'>
              required permissions
            </Link>
            . Otherwise, this integration may fail to work properly.
          </>
        ),
      }
    }

    return {
      title: 'An error occurred in your integration',
      content: error,
    }
  }, [authError, error, cloudflareData])

  const sections: IntegrationPageSectionDefinition[] =
    status === IntegrationStatus.NotInstalled
      ? [
          chartVisible
            ? {
                key: 'usage',
                title: 'Cloudflare Usage',
                info: `Last ${lastXDays} Days`,
                content: <ProxyIntegrationUsageChart requests={requestsMade} integrationName='Cloudflare' />,
              }
            : {
                key: 'overview',
                title: 'Overview',
                description: 'This integration is actively maintained by the Fingerprint team.',
                content: <CloudflareDescription />,
              },
        ]
      : [
          chartVisible
            ? {
                key: 'usage',
                title: 'Cloudflare Usage',
                info: 'Last 30 Days',
                content: <ProxyIntegrationUsageChart requests={requestsMade} integrationName='Cloudflare' />,
              }
            : {
                key: 'usage',
                title: 'Cloudflare Usage',
                info: '',
                content: (
                  <div className={styles.noUsageDataContainer}>
                    <div className={styles.noDataIconContainer}>
                      <ChartIcon />
                    </div>
                    <div>
                      <Typography variant='semiBody1' component='h4' className={styles.noUsageDataTitle} align='center'>
                        No Usage Data Yet
                      </Typography>
                      <Typography variant='bodyM' className={styles.noUsageDataContainerText} align='center'>
                        We need more data to generate this chart. Make some API requests!
                      </Typography>
                    </div>
                    <LinkButton href={DOCS_INTEGRATIONS.cloudflare} target='_blank' className={styles.noUsageButton}>
                      View Documentation
                    </LinkButton>
                  </div>
                ),
              },
          {
            key: 'configuration',
            title: 'Configuration',
            description: 'Below is your Cloudflare worker configuration. Note, only the token is editable.',
            content: (
              <>
                {alertProps ? (
                  <Alert className={styles.alert} severity='error'>
                    <AlertTitle>{alertProps.title}</AlertTitle>
                    {alertProps.content}
                  </Alert>
                ) : null}
                <CloudflareConfigurationValues authError={authError} integrationData={cloudflareData!} />
              </>
            ),
            actions: (
              <Button
                isDisabled={status === IntegrationStatus.ChangesPending}
                size='lg'
                fullWidth={smDown}
                onPress={openDialog}
                variant='outline'
                className='ml-4'
              >
                <Edit fontSize='inherit' />
                Edit API Token
              </Button>
            ),
          },
          {
            title: 'Danger zone',
            key: 'danger',
            isDanger: true,
            content: (
              <Button
                isDisabled={status === IntegrationStatus.RemovalPending}
                onPress={openRemovalModal}
                variant='destructive'
              >
                Delete integration
              </Button>
            ),
          },
        ]

  return (
    <>
      <IntegrationPage
        statusLabel={statusLabel}
        sections={sections}
        docsUrl={DOCS_INTEGRATIONS.cloudflare}
        repoUrl='https://github.com/fingerprintjs/fingerprintjs-pro-cloudflare-worker'
        isLoading={isLoading}
        integrationStatus={status}
        connectButton={
          <Button onPress={openDialog}>
            <Add fontSize='inherit' />
            Connect
          </Button>
        }
        Logo={Logo}
        title='Cloudflare'
        subscriptionId={subscriptionId}
        metadata={
          cloudflareData && status !== IntegrationStatus.NotInstalled
            ? [
                {
                  title: 'Last deployed on',
                  value: cloudflareData.lastDeploymentCompletedAt
                    ? format(new Date(cloudflareData.lastDeploymentCompletedAt), 'MMM d, yyyy')
                    : undefined,
                },
                {
                  title: 'Last version',
                  value: cloudflareData.lastVersion,
                },
              ]
            : []
        }
      />
      {cloudflareData ? (
        <Dialog open={isRemoving} onClose={closeRemovalModal} scroll='body'>
          <DialogTitle onClose={closeRemovalModal} />
          <div className={styles.dialog}>
            <IntegrationCloudflareRemoval
              onClose={closeRemovalModal}
              integration={cloudflareData}
              onRemove={onRemove}
            />
          </div>
        </Dialog>
      ) : null}
      <Dialog isLoading={isDialogLoading} open={isDialogOpen} onClose={closeDialog} scroll='body'>
        <DialogTitle onClose={closeDialog} />
        <div className={styles.dialog}>
          <IntegrationCloudflareModal
            isAuthError={Boolean(authError)}
            validateToken={validateToken}
            onSubmit={onUpdate}
            onClose={closeDialog}
          />
        </div>
      </Dialog>
    </>
  )
}
