import { Link, Typography } from '@mui/material'
import { PRIVACY_URL, TERMS_URL } from 'const'
import { ampli } from 'models/ampli'
import { ReactNode, useCallback } from 'react'

import FullpageLoader from '../../../../components/Loader/FullpageLoader/FullpageLoader'
import { SUPPORT_PAGE_URL } from '../../../../const'
import { formatDate } from '../../../../helpers/format'
import { FakeDashboardBackground } from './FakeDashboardBackground'
import styles from './FakeDashboardInfoLayout.module.scss'

export type Props = {
  isLoading?: boolean
  children?: ReactNode
  srcPage?: string
  minimalist?: boolean
}

const getAmpliSource = (srcPage?: string) => {
  switch (srcPage) {
    case 'login':
      return 'login form'
    default:
      return 'layout footer'
  }
}

export function FakeDashboardInfoLayout({ isLoading, children, srcPage }: Props) {
  const onClickPrivacyPolicy = useCallback(() => {
    ampli.privacyPolicyClicked({ source: getAmpliSource(srcPage) })
  }, [srcPage])

  const onClickTermsOfService = useCallback(() => {
    ampli.termsOfServiceClicked({ source: getAmpliSource(srcPage) })
  }, [srcPage])

  const onContactSupport = useCallback(() => {
    ampli.contactSupportClicked({
      source: 'onboarding-header',
    })
    return false
  }, [])

  const date = formatDate(new Date(), 'yyyy')

  const links = (
    <div className={styles.links}>
      <Typography component='span' variant='body2' className={styles.copyright}>
        © {date} FingerprintJS, Inc
      </Typography>
      <Link
        href={PRIVACY_URL}
        color='primary'
        underline='hover'
        target='_blank'
        className={styles.link}
        onClick={onClickPrivacyPolicy}
      >
        Privacy Policy
      </Link>
      <Link
        href={TERMS_URL}
        color='primary'
        underline='hover'
        target='_blank'
        className={styles.link}
        onClick={onClickTermsOfService}
      >
        Terms of Service
      </Link>
      <Link
        href={SUPPORT_PAGE_URL}
        color='primary'
        underline='hover'
        target='_blank'
        className={styles.link}
        onClick={onContactSupport}
      >
        Contact Us
      </Link>
    </div>
  )

  return (
    <main className={styles.container}>
      <FakeDashboardBackground />
      <section className={styles.mainSection}>
        {isLoading ? <FullpageLoader /> : <div className={styles.mainContainer}>{children}</div>}
        {links}
      </section>
    </main>
  )
}
