import { Button } from '@compass/components'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link as LinkMui,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'

import { SUPPORT_PAGE_URL } from '../../../../const'
import { useUiPreferences } from '../../../../hooks'
import { Subscription, SubscriptionStatus, SubscriptionType } from '../../../../models'
import { formatDate } from '../../helpers/datetime'
import styles from './TrialExtendedModal.module.scss'

type TrialExtendedProps = {
  isOpen: boolean
  onClose: () => void
  trialEndAt: string | Date
}

type SubscriptionProps = Pick<Subscription, 'status' | 'type' | 'trialEndAt' | 'extendedTrialStartedAt'>

export function TrialExtendedModal({ type, status, trialEndAt, extendedTrialStartedAt }: SubscriptionProps) {
  const {
    uiPreferences: { isTrialExtendedModalDismissed },
    updateUiPreferences,
  } = useUiPreferences()

  const [isOpen, setOpen] = useState(!isTrialExtendedModalDismissed)

  useEffect(() => {
    setOpen(!isTrialExtendedModalDismissed)
  }, [isTrialExtendedModalDismissed])

  const handleClose = useCallback(() => {
    updateUiPreferences({ isTrialExtendedModalDismissed: true })
    setOpen(false)
  }, [updateUiPreferences, setOpen])

  if (
    type !== SubscriptionType.TrialOnly ||
    !trialEndAt ||
    !extendedTrialStartedAt ||
    new Date(extendedTrialStartedAt) >= new Date() ||
    (status !== SubscriptionStatus.Canceled && isTrialExtendedModalDismissed)
  ) {
    return null
  }

  return <TrialExtendedModalLayout isOpen={isOpen} onClose={handleClose} trialEndAt={trialEndAt} />
}

export function TrialExtendedModalLayout({ isOpen, onClose, trialEndAt }: TrialExtendedProps) {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const timeRemaining = trialEndAt ? (new Date(trialEndAt).getTime() - new Date().getTime()) / 1000 : 0
  const { seconds, minutes, hours, days } = makeTimer(Math.max(0, timeRemaining))
  const [, setRemaining] = useState(Math.max(0, timeRemaining))
  const [endingSoon, setEndingSoon] = useState(timeRemaining <= 3 * 24 * 60 * 60)
  const [timer, setTimer] = useState({ seconds, minutes, hours, days })

  useEffect(() => {
    const timer = setInterval(() => {
      setRemaining((sec) => {
        setTimer(makeTimer(sec - 1))
        setEndingSoon(sec - 1 <= 3 * 24 * 60 * 60)
        return sec - 1
      })
    }, 1000)
    return () => clearInterval(timer)
  })

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={smDown}>
      <IconButton className={styles.closeButton} aria-label='close' onClick={onClose} size='large'>
        <CloseIcon />
      </IconButton>

      <DialogTitle className={styles.title}>Free trial extended</DialogTitle>
      <DialogContent className={styles.modalContainer}>
        <div className={clsx(styles.counter, endingSoon ? styles.endingSoon : undefined)}>
          <Box>
            <div className={styles.counterValue}>{timer.days.toString().padStart(2, '0')}</div>
            <div className={styles.counterTitle}>days</div>
          </Box>
          <Box>
            <div className={styles.counterValue}>{timer.hours.toString().padStart(2, '0')}</div>
            <div className={styles.counterTitle}>hours</div>
          </Box>
          <Box>
            <div className={styles.counterValue}>{timer.minutes.toString().padStart(2, '0')}</div>
            <div className={styles.counterTitle}>minutes</div>
          </Box>
          <Box>
            <div className={styles.counterValue}>{timer.seconds.toString().padStart(2, '0')}</div>
            <div className={styles.counterTitle}>seconds</div>
          </Box>
        </div>

        <div className={styles.titleSection}>
          Your trial has been extended by 14 days and now ends on {formatDate(trialEndAt)}. Enjoy!
        </div>

        <Box width='100%' mb='56px' fontSize='14px' lineHeight='20px'>
          <b>Have questions?</b>
          <br />
          <LinkMui href={SUPPORT_PAGE_URL} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
            Reach out to us.
          </LinkMui>{' '}
          Our support engineers are happy to help.
        </Box>

        {smDown ? (
          <Box className={styles.buttonContainer} width='100%'>
            <Button onPress={onClose} fullWidth size='lg'>
              Okay, got it
            </Button>
          </Box>
        ) : (
          <Button onPress={onClose} fullWidth={smDown} size='lg'>
            Okay, got it
          </Button>
        )}
      </DialogContent>
    </Dialog>
  )
}

function makeTimer(time: number) {
  return {
    seconds: Math.max(0, Math.floor(time) % 60),
    minutes: Math.max(0, Math.floor(time / 60) % 60),
    hours: Math.max(0, Math.floor(time / 60 / 60) % 24),
    days: Math.max(0, Math.floor(time / 60 / 60 / 24)),
  }
}
