import { Box } from '@mui/material'
import Loader from 'components/Loader/Loader'
import PageHeader from 'components/PageHeader/PageHeader'
import { appSettingNavItems, Header, MainContent, TabNavigation } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle, useIsApplicationFeatureEnabled } from 'hooks'
import { useEntityLimit } from 'hooks/api/context'
import { useEncryptionKeys, useSubscriptionTokens } from 'hooks/api/tokens'
import { ApiKey, ApiKeyType, ApplicationFeatureName, Limits } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useMemo, useState } from 'react'

import { USE_ENCRYPTED_KEYS, USE_MANAGEMENT_KEYS } from '../../const'
import styles from './ApiKeysPage.module.scss'
import ActivateApiKeyDialog from './components/ActivateApiKeyDialog/ActivateApiKeyDialog'
import { default as CreateApiKeyDialog } from './components/CreateApiKeyDialog/CreateApiKeyDialog'
import { default as DeleteApiKeyDialog } from './components/DeleteApiKeyDialog/DeleteApiKeyDialog'
import { apiKeyDisplayNameMap, default as ApiKeysSection } from './components/KeySection/KeySection'
import { default as ManageApiKeyDialog } from './components/ManageApiKeyDialog/ManageApiKeyDialog'

export function ApiKeysPage() {
  useDocumentTitle('Application Api Keys')
  const { currentSubscriptionId } = useCurrentSubscription()

  const [isManageDialogOpen, setIsManageDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false)
  const [defaultCreateType, setDefaultCreateType] = useState(ApiKeyType.Public)
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey>()

  const isEncryptionEnabled = useIsApplicationFeatureEnabled(
    currentSubscriptionId,
    ApplicationFeatureName.ResultsEncryption
  )
  const { data: apiKeysData, isLoading: isLoadingApiKeys } = useSubscriptionTokens(currentSubscriptionId)
  const { data: encryptedApiKeysData, isLoading: isLoadingEncryptedApiKeys } = useEncryptionKeys(currentSubscriptionId)
  const maxApiKeyCreatedLimit = useEntityLimit(currentSubscriptionId, Limits.Tokens)

  const sortedApiKeys = Array.from(apiKeysData ?? []).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

  const encryptedApiKeys = Array.from(encryptedApiKeysData ?? [])
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .map(
      (encryptedKey) =>
        ({
          ...encryptedKey,
          type: 'encryption',
        } as ApiKey)
    )

  const apiKeys = useMemo(() => {
    return [...sortedApiKeys, ...encryptedApiKeys]
  }, [sortedApiKeys, encryptedApiKeys])

  const onClickCreateApiKey = useCallback((apiKeyType: ApiKeyType) => {
    ampli.apiKeyAddingStarted({ apiKeyType: apiKeyDisplayNameMap[apiKeyType] })
    setDefaultCreateType(apiKeyType)
    setIsCreateDialogOpen(true)
  }, [])

  const onClickActivateKey = useCallback(
    (apiKeyId: string) => {
      //ampli.apiKeyAddingStarted({ apiKeyType: apiKeyDisplayNameMap[apiKeyType] })
      setSelectedApiKey(apiKeys.find((key) => key.id === apiKeyId))
      setIsActivateDialogOpen(true)
    },
    [apiKeys]
  )

  const onClickManageKey = useCallback(
    (apiKeyId: string) => {
      setSelectedApiKey(apiKeys.find((key) => key.id === apiKeyId))
      setIsManageDialogOpen(true)
    },
    [apiKeys]
  )

  const onClickDeleteKey = useCallback(
    (apiKeyId: string) => {
      setSelectedApiKey(apiKeys.find((key) => key.id === apiKeyId))
      setIsDeleteDialogOpen(true)
    },
    [apiKeys]
  )

  const onCloseDialog = useCallback(() => {
    setIsDeleteDialogOpen(false)
    setIsManageDialogOpen(false)
    setIsActivateDialogOpen(false)
    setSelectedApiKey(undefined)
  }, [])

  const onCloseCreateDialog = useCallback((apiKeyType: ApiKeyType, completed: boolean) => {
    if (!completed) {
      ampli.apiKeyAddingCancelled({ apiKeyType: apiKeyDisplayNameMap[apiKeyType] })
    }
    setIsCreateDialogOpen(false)
  }, [])

  const sharedKeySectionProps = useMemo(
    () => ({
      isLoadingApiKeys,
      canCreateNewKeys: apiKeys.length < maxApiKeyCreatedLimit,
      onClickManageKey,
      onClickDeleteKey,
      onClickActivateKey,
    }),
    [isLoadingApiKeys, apiKeys.length, maxApiKeyCreatedLimit, onClickManageKey, onClickDeleteKey, onClickActivateKey]
  )

  return (
    <>
      {isLoadingApiKeys && isLoadingEncryptedApiKeys && <Loader testId='api-keys-loader' />}
      <Header title='App Settings' />
      <TabNavigation items={appSettingNavItems} />
      <MainContent>
        <PageHeader title='API keys' />
        <Box className={styles.layout}>
          {isCreateDialogOpen && (
            <CreateApiKeyDialog open onCloseCreateDialog={onCloseCreateDialog} createType={defaultCreateType} />
          )}
          {isManageDialogOpen && selectedApiKey && (
            <ManageApiKeyDialog open onClose={onCloseDialog} apiKey={selectedApiKey} />
          )}
          {isActivateDialogOpen && selectedApiKey && (
            <ActivateApiKeyDialog open onClose={onCloseDialog} apiKey={selectedApiKey} />
          )}
          {isDeleteDialogOpen && selectedApiKey && (
            <DeleteApiKeyDialog open onClose={onCloseDialog} apiKey={selectedApiKey} />
          )}
          <ApiKeysSection
            apiKeyTableType={ApiKeyType.Public}
            apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.Public)}
            onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.Public)}
            {...sharedKeySectionProps}
          />
          <ApiKeysSection
            apiKeyTableType={ApiKeyType.Secret}
            apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.Secret)}
            onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.Secret)}
            {...sharedKeySectionProps}
          />
          <ApiKeysSection
            apiKeyTableType={ApiKeyType.BrowserProxy}
            apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.BrowserProxy)}
            onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.BrowserProxy)}
            {...sharedKeySectionProps}
          />
          {USE_ENCRYPTED_KEYS && isEncryptionEnabled && (
            <ApiKeysSection
              apiKeyTableType={ApiKeyType.Encryption}
              apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.Encryption)}
              onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.Encryption)}
              {...sharedKeySectionProps}
            />
          )}
          {USE_MANAGEMENT_KEYS && (
            <ApiKeysSection
              apiKeyTableType={ApiKeyType.Management}
              apiKeys={apiKeys.filter((key) => key.type === ApiKeyType.Management)}
              onClickCreateKey={() => onClickCreateApiKey(ApiKeyType.Management)}
              {...sharedKeySectionProps}
            />
          )}
        </Box>
      </MainContent>
    </>
  )
}
