import { Button } from '@compass/components'
import { Add, FileCopyOutlined, InfoOutlined } from '@mui/icons-material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TableHead,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import clsx from 'clsx'
import { Table, TableBody, TableBodyData, TableCell, TableRow } from 'components/Table/Table'
import Tag from 'components/Tag/Tag'
import { EntityEmptyState, formatDate } from 'features/commonUI'
import { copyToClipboard } from 'helpers/clipboard'
import { usePermissions, useToast } from 'hooks'
import { ApiKey, ApiKeyType, ApiKeyTypeLogMap, Limits, WorkspaceEnvironment } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useState } from 'react'

import { USE_WORKSPACE_ENVIRONMENT } from '../../../../const'
import { useEntityLimit } from '../../../../hooks/api/context'
import { useWorkspaceEnvironments } from '../../../../hooks/api/environment'
import { useCurrentSubscription } from '../../../subscription'
import { keysLimitReached, ownerRestricted } from '../../content'
import { EncryptionKeyCalloutBox } from '../EncryptionKeyCalloutBox/EncryptionKeyCalloutBox'
import { ShortKeyDescription } from '../KeyDescription/KeyDescription'
import styles from './KeySection.module.scss'

export interface KeySectionProps {
  apiKeyTableType: ApiKeyType
  apiKeys: ApiKey[]
  isLoadingApiKeys: boolean
  canCreateNewKeys: boolean
  onClickCreateKey: () => void
  onClickManageKey: (apiKeyId: string) => void
  onClickActivateKey: (apiKeyId: string) => void
  onClickDeleteKey: (apiKeyId: string) => void
}

export default function KeySection({
  apiKeyTableType,
  apiKeys,
  isLoadingApiKeys,
  canCreateNewKeys,
  onClickCreateKey,
  onClickManageKey,
  onClickActivateKey,
  onClickDeleteKey,
}: KeySectionProps) {
  const isEmpty = apiKeys.length === 0 && !isLoadingApiKeys
  const typeDisplayName = apiKeyDisplayNameMap[apiKeyTableType]
  const isEncryption = apiKeys[0]?.type === ApiKeyType.Encryption
  const hasAtLeastOneActive = apiKeys.some((apiKey) => !apiKey.disabledAt)
  const { currentSubscriptionId } = useCurrentSubscription()
  const maxApiKeyCreatedLimit = useEntityLimit(currentSubscriptionId, Limits.Tokens)
  const { data: workspaceEnvironmentsData } = useWorkspaceEnvironments(currentSubscriptionId)

  const {
    apiKeyPermissions: { canManage: canManageApiKeys },
  } = usePermissions()

  const CreateButton = ({ type }: { type: ApiKeyType }) => {
    const isDisabled = !canManageApiKeys(type) || !canCreateNewKeys
    const button = (
      <Button
        variant='primary'
        className={styles.keySectionHeaderButton}
        isDisabled={isDisabled}
        onPress={onClickCreateKey}
        data-testid={`button-apikeys-create-${type}`}
      >
        {isEmpty ? null : <Add fontSize='inherit' />}
        Create {typeDisplayName.toLowerCase()} key
      </Button>
    )

    const disabledTooltipMessage = !canManageApiKeys ? ownerRestricted(type) : keysLimitReached(maxApiKeyCreatedLimit)

    if (isDisabled) {
      return (
        <Tooltip title={disabledTooltipMessage} placement='top'>
          <span>{button}</span>
        </Tooltip>
      )
    }

    return <>{button}</>
  }

  return (
    <Paper className={styles.keySectionContainer}>
      <Box className={clsx(styles.keySectionHeader, { [styles.isEmpty]: styles.isEmpty })}>
        <Box gap='4px' display='flex' flexDirection='column'>
          <Typography variant='h3'>{apiKeyDisplayNameMap[apiKeyTableType] + ' Keys'}</Typography>
          {!isEmpty && (
            <Typography variant='bodyM' textAlign='left'>
              <ShortKeyDescription keyType={apiKeyTableType} />
            </Typography>
          )}
        </Box>
        {!isEmpty && <CreateButton type={apiKeyTableType} />}
      </Box>
      {!isEmpty ? (
        <>
          <EncryptionKeyCalloutBox isEncryption={isEncryption} hasAtLeastOneActive={hasAtLeastOneActive} />
          <br />
          {workspaceEnvironmentsData && (
            <ApiKeyResponsiveView
              apiKeys={apiKeys}
              onClickManageKey={onClickManageKey}
              onClickActivateKey={onClickActivateKey}
              onClickDeleteKey={onClickDeleteKey}
              workspaceEnvironments={workspaceEnvironmentsData}
            />
          )}
        </>
      ) : (
        <EntityEmptyState
          entity='api_key'
          title={`You have no ${typeDisplayName.toLowerCase()} keys`}
          description={<ShortKeyDescription keyType={apiKeyTableType} />}
          onClick={onClickCreateKey}
          buttonText={`Create ${typeDisplayName.toLowerCase()} key`}
          isButtonDisabled={!canManageApiKeys(apiKeyTableType)}
          buttonTestId={`button-apikeys-create-${apiKeyTableType}`}
          disabledTooltip={ownerRestricted(apiKeyTableType)}
        />
      )}
    </Paper>
  )
}

export const apiKeyDisplayNameMap: Record<ApiKeyType, 'Public' | 'Secret' | 'Proxy' | 'Encryption' | 'Management'> = {
  [ApiKeyType.Public]: 'Public',
  [ApiKeyType.Secret]: 'Secret',
  [ApiKeyType.BrowserProxy]: 'Proxy',
  [ApiKeyType.Encryption]: 'Encryption',
  [ApiKeyType.Management]: 'Management',
}

const ApiKeyNameBlankText = 'No key name provided'

export interface ApiKeyDisplayProps {
  apiKeys: ApiKey[]
  workspaceEnvironments?: WorkspaceEnvironment[]
  onClickManageKey: (apiKeyId: ApiKey['id']) => void
  onClickActivateKey: (apiKeyId: ApiKey['id']) => void
  onClickDeleteKey: (apiKeyId: ApiKey['id']) => void
}

function ApiKeyResponsiveView(props: ApiKeyDisplayProps) {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  if (isLargeScreen) {
    return <ApiKeysFullTableView {...props} />
  }

  return <ApiKeysCompactView {...props} />
}

function ApiKeysCompactView({
  apiKeys,
  onClickManageKey,
  onClickActivateKey,
  onClickDeleteKey,
  workspaceEnvironments,
}: ApiKeyDisplayProps) {
  const TokenDetails = ({ apiKey }: { apiKey: ApiKey }) => {
    const isProxy = apiKey.type === ApiKeyType.BrowserProxy
    const isEncryption = apiKey.type === ApiKeyType.Encryption
    let apiKeyName = apiKey.name ? apiKey.name.trim() : ''
    apiKeyName = apiKeyName.length > 0 ? apiKeyName : ApiKeyNameBlankText
    const hasEmptyName = apiKeyName === ApiKeyNameBlankText

    const idToNameMap: { [id: string]: string } = {}
    workspaceEnvironments &&
      workspaceEnvironments.forEach((workspaceEnvironment: WorkspaceEnvironment) => {
        idToNameMap[workspaceEnvironment.id] = workspaceEnvironment.name
      })

    const hasEnvironment = USE_WORKSPACE_ENVIRONMENT && [ApiKeyType.Public, ApiKeyType.Secret].includes(apiKeys[0].type)
    return (
      <ul>
        <li className={clsx({ [styles.emptyNameText]: hasEmptyName })}>{apiKeyName}</li>
        {hasEnvironment && apiKey.workspaceEnvironmentId && <li>{idToNameMap[apiKey.workspaceEnvironmentId]}</li>}
        {!isEncryption && <li>{isProxy ? '-' : `${Number(apiKey.rateLimit)} req/s`}</li>}
        {isEncryption && <li>Created {formatDate(apiKey.createdAt)} </li>}
      </ul>
    )
  }

  return (
    <Box className={styles.compactKeysContainer}>
      {apiKeys.map((apiKey) => (
        <Box key={apiKey.id} className={styles.detailsLayout}>
          <Box className={styles.leftDetails}>
            {REDACTED_TYPES.includes(apiKey.type) ? (
              <ApiKeyRedactedElement apiKey={apiKey} />
            ) : (
              <ApiKeyCopyElement apiKey={apiKey} />
            )}
            <TokenDetails apiKey={apiKey} />
          </Box>
          <Box>
            <StatusTag apiKey={apiKey} />
            <ApiKeySettingsMenu
              apiKey={apiKey}
              onClickDeleteKey={() => onClickDeleteKey(apiKey.id)}
              onClickActivateKey={() => onClickActivateKey(apiKey.id)}
              onClickManageKey={() => onClickManageKey(apiKey.id)}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

function ApiKeysFullTableView({
  apiKeys,
  onClickDeleteKey,
  onClickActivateKey,
  onClickManageKey,
  workspaceEnvironments,
}: ApiKeyDisplayProps) {
  // prevents the default empty state from showing while loading, since we have a custom one
  if (apiKeys.length === 0) {
    return null
  }

  const idToNameMap: { [id: string]: string } = {}
  workspaceEnvironments &&
    workspaceEnvironments.forEach((workspaceEnvironment: WorkspaceEnvironment) => {
      idToNameMap[workspaceEnvironment.id] = workspaceEnvironment.name
    })

  const isEncryption = apiKeys[0].type === ApiKeyType.Encryption
  const isProxy = apiKeys[0].type === ApiKeyType.BrowserProxy
  const hasEnvironment = USE_WORKSPACE_ENVIRONMENT && [ApiKeyType.Public, ApiKeyType.Secret].includes(apiKeys[0].type)

  return (
    <Table classes={{ root: styles.table }}>
      <TableHead>
        <TableRow>
          <TableCell width='18%'>Name</TableCell>
          <TableCell width='25%'>Key</TableCell>
          {!isEncryption && <TableCell width='10%'>Rate Limit</TableCell>}
          <TableCell width='13%'>Created</TableCell>
          {hasEnvironment && <TableCell width='15%'>Environment</TableCell>}
          <TableCell width='10%'>Status</TableCell>
          <TableCell align='right' width='5%'>
            {''}
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody columnCount={6}>
        <TableBodyData>
          {apiKeys.map((apiKey) => {
            let apiKeyName = apiKey.name ? apiKey.name.trim() : ''
            apiKeyName = apiKeyName.length > 0 ? apiKeyName : ApiKeyNameBlankText
            const hasEmptyName = apiKeyName === ApiKeyNameBlankText

            return (
              <TableRow key={apiKey.id}>
                <TableCell>
                  <Typography
                    variant={hasEmptyName ? 'bodyM' : 'bodyMMedium'}
                    fontStyle={hasEmptyName ? 'italic' : 'inherit'}
                  >
                    {apiKeyName}
                  </Typography>
                </TableCell>
                <TableCell monospace>
                  {REDACTED_TYPES.includes(apiKey.type) ? (
                    <ApiKeyRedactedElement apiKey={apiKey} />
                  ) : (
                    <ApiKeyCopyElement apiKey={apiKey} />
                  )}
                </TableCell>
                {!isEncryption && (
                  <TableCell align='left'>
                    <Typography variant='bodyM'>{isProxy ? '-' : `${Number(apiKey.rateLimit)} req/s`}</Typography>
                  </TableCell>
                )}
                <TableCell align='left'>
                  <Typography variant='bodyM'>{formatDate(apiKey.createdAt, 'shortWithYear')}</Typography>
                </TableCell>

                {hasEnvironment && idToNameMap && apiKey.workspaceEnvironmentId && (
                  <TableCell align='left'>
                    <Typography variant='bodyM'>{idToNameMap[apiKey.workspaceEnvironmentId]}</Typography>
                  </TableCell>
                )}
                <TableCell align='left'>
                  <StatusTag apiKey={apiKey} />
                </TableCell>
                <TableCell align='right'>
                  <ApiKeySettingsMenu
                    apiKey={apiKey}
                    onClickDeleteKey={() => onClickDeleteKey(apiKey.id)}
                    onClickActivateKey={() => onClickActivateKey(apiKey.id)}
                    onClickManageKey={() => onClickManageKey(apiKey.id)}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}

interface ApiKeySettingsMenuProps {
  apiKey: ApiKey
  onClickManageKey: () => void
  onClickActivateKey: () => void
  onClickDeleteKey: () => void
}

function ApiKeySettingsMenu({
  apiKey,
  onClickManageKey,
  onClickActivateKey,
  onClickDeleteKey,
}: ApiKeySettingsMenuProps) {
  const { isReadOnly } = usePermissions()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleManageKey() {
    onClickManageKey()
    handleClose()
  }

  function handleActivateKey() {
    onClickActivateKey()
    handleClose()
  }

  function handleDeleteKey() {
    onClickDeleteKey()
    handleClose()
  }

  return (
    <>
      <IconButton
        aria-label='api-key-settings-button'
        data-testid='api-key-settings-button'
        disabled={isReadOnly}
        size='large'
        onClick={handleClick}
        className={styles.menuButton}
      >
        <MoreHorizIcon fontSize='small' className={clsx(styles.buttonIcon, { [styles.disabled]: isReadOnly })} />
      </IconButton>

      <Menu
        className={styles.menu}
        id='api-key-settings-menu'
        aria-labelledby='api-key-settings-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleManageKey} data-testid='api-key-manage'>
          <Typography variant='bodyMMedium'>Manage key</Typography>
        </MenuItem>
        {apiKey.type === ApiKeyType.Encryption && apiKey.disabledAt && (
          <MenuItem onClick={handleActivateKey}>
            <Typography variant='bodyMMedium'>Activate Key</Typography>
          </MenuItem>
        )}
        {(apiKey.type !== ApiKeyType.Encryption || apiKey.disabledAt) && (
          <MenuItem onClick={handleDeleteKey} className={styles.deleteItem}>
            <Typography variant='bodyMMedium' data-testid='api-key-delete'>
              Delete key
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

function StatusTag({ apiKey }: { apiKey: ApiKey }) {
  return (
    <Tag
      compact
      color={apiKey.disabledAt ? 'red' : 'green'}
      label={apiKey.disabledAt ? 'Inactive' : 'Active'}
      bold
      className={styles.statusTag}
    />
  )
}

function ApiKeyCopyElement({ apiKey }: { apiKey: ApiKey }) {
  const { showToast } = useToast()
  const token = apiKey.type === ApiKeyType.Encryption ? apiKey.encodedKey : apiKey.token

  const onClick = useCallback(() => {
    const keyToCopy = apiKey.encodedKey ? apiKey.encodedKey : apiKey.token

    ampli.apiKeyCopied({ apiKeyType: ApiKeyTypeLogMap[apiKey.type] })
    copyToClipboard(keyToCopy ?? '')
    showToast({ message: `${apiKeyDisplayNameMap[apiKey.type]} key copied to clipboard!`, severity: 'success' })
  }, [apiKey, showToast])

  return (
    <Button
      variant='ghost'
      className={styles.apiKeyMonoText}
      onPress={onClick}
      aria-label='Copy API key'
      data-testid='copy-api-key'
    >
      <span>{token}</span>
      <FileCopyOutlined color='primary' fontSize='inherit' />
    </Button>
  )
}

function ApiKeyRedactedElement({ apiKey }: { apiKey: ApiKey }) {
  return (
    <span className={styles.apiKeyRedacted}>
      ····················
      <Tooltip
        title={`For security reasons, ${apiKeyDisplayNameMap[
          apiKey.type
        ].toLowerCase()} keys are visible only once immediately after they are generated.`}
        placement='top-start'
      >
        <InfoOutlined fontSize='tiny' />
      </Tooltip>
    </span>
  )
}

const REDACTED_TYPES = [ApiKeyType.Secret, ApiKeyType.BrowserProxy, ApiKeyType.Management]
