import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Chip, Grid, MenuItem, Select, SelectChangeEvent, Tab, Typography } from '@mui/material'
import InfoDrawer from 'components/InfoDrawer/InfoDrawer'
import PageHeader from 'components/PageHeader/PageHeader'
import {
  DOCS_REQUEST_FILTERING_URL,
  SHOW_MOBILE_REQUEST_FILTERING,
  USE_DISABLE_BOTS_FEATURE,
  USE_WORKSPACE_ENVIRONMENT,
} from 'const'
import { appSettingNavItems, Header, MainGrid, TabNavigation } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle, useIsApplicationFeatureEnabled, useTrafficRules } from 'hooks'
import { useEntityLimit } from 'hooks/api/context'
import { ApplicationFeatureName, Limits, TrafficRuleType, WorkspaceEnvironment } from 'models'
import { ampli } from 'models/ampli'
import { useEffect, useState } from 'react'

import { useWorkspaceEnvironments } from '../../hooks/api/environment'
import BotsSection from './components/BotsSection/BotsSection'
import MobileSection from './components/MobileSection/MobileSection'
import WebSection from './components/WebSection/WebSection'
import styles from './RequestFilteringPage.module.scss'

export function RequestFilteringPage() {
  useDocumentTitle('Request Filtering')

  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const isFilterSearchBotsEnabled = useIsApplicationFeatureEnabled(
    subscriptionId,
    ApplicationFeatureName.FilterSearchBots
  )

  const { data: trafficRules, isLoading } = useTrafficRules(subscriptionId)

  const allowedTrafficRulesAmount = useEntityLimit(subscriptionId, Limits.TrafficRules)
  const isCreationLimitExceeded = !!trafficRules && trafficRules.length >= allowedTrafficRulesAmount

  const [activeTab, setActiveTab] = useState<'web' | 'mobile' | 'bots'>('web')
  const { data: workspaceEnvironments, isLoading: isLoadingEnvironments } = useWorkspaceEnvironments(subscriptionId)

  const [currentEnvironment, setCurrentEnvironment] = useState<WorkspaceEnvironment | undefined>()

  const trafficRulesWithoutAsteriskRules = trafficRules?.filter((rule) => rule.value !== '*')

  const webRulesCount = trafficRulesWithoutAsteriskRules?.filter(
    (rule) =>
      rule.workspaceEnvironmentId === currentEnvironment?.id &&
      [(TrafficRuleType.HTTPHeader, TrafficRuleType.Origin)].includes(rule.type)
  ).length

  const mobileRulesCount = trafficRulesWithoutAsteriskRules?.filter(
    (rule) =>
      rule.workspaceEnvironmentId === currentEnvironment?.id && [TrafficRuleType.AppPackageName].includes(rule.type)
  ).length

  const tabs = [
    { label: 'Web', value: 'web', activeRuleCount: webRulesCount },
    {
      label: 'Mobile Apps',
      value: 'mobile',
      activeRuleCount: mobileRulesCount,
      hidden: !SHOW_MOBILE_REQUEST_FILTERING,
    },
    {
      label: 'Bots',
      value: 'bots',
      activeRuleCount: Number(isFilterSearchBotsEnabled),
      hidden: !USE_DISABLE_BOTS_FEATURE,
    },
  ]

  useEffect(() => {
    if (workspaceEnvironments && workspaceEnvironments.length > 0) {
      setCurrentEnvironment(workspaceEnvironments[0])
    }
  }, [workspaceEnvironments])

  const handleEnvironmentChange = (event: SelectChangeEvent) => {
    ampli.environmentSelected({ featureContext: 'Request Filtering' })

    const chosenEnvironment = workspaceEnvironments?.find(
      (workspaceEnvironment) => workspaceEnvironment.id === event.target.value
    )

    if (chosenEnvironment) {
      setCurrentEnvironment(chosenEnvironment)
    }
  }

  const onEnvironmentClick = () => {
    ampli.environmentSelectionOpened({ featureContext: 'Request Filtering' })
  }

  return (
    <>
      <Header title='App Settings' />
      <TabNavigation items={appSettingNavItems} />
      <MainGrid>
        <Grid item xs={12} className={styles.headerGrid}>
          <PageHeader title='Request Filtering' infoDrawer={<TrafficRulesInfoDrawer />} className={styles.header} />
          {USE_WORKSPACE_ENVIRONMENT && workspaceEnvironments && currentEnvironment && (
            <Select
              variant='outlined'
              value={currentEnvironment.id}
              onChange={handleEnvironmentChange}
              onClick={onEnvironmentClick}
              MenuProps={{ disablePortal: true }}
              className={styles.select}
            >
              {workspaceEnvironments?.map((environment) => (
                <MenuItem key={environment.id} value={environment.id}>
                  {environment.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <TabContext value={activeTab}>
          <Grid item xs={12} padding={0}>
            <TabList
              onChange={(_e, tab) => setActiveTab(tab)}
              scrollButtons='auto'
              variant='scrollable'
              className={styles.tabList}
            >
              {tabs.map((tab) => {
                if (tab.hidden) {
                  return null
                }

                return (
                  <Tab
                    key={tab.value}
                    label={
                      <>
                        <span>{tab.label}</span>
                        {Number(tab.activeRuleCount) > 0 && (
                          <Chip
                            className={styles.ruleCount}
                            label={`${tab.activeRuleCount} rule${Number(tab.activeRuleCount) > 1 ? 's' : ''}`}
                            size='small'
                          />
                        )}
                      </>
                    }
                    value={tab.value}
                    classes={{ root: styles.tabLabel, selected: styles.selectedTab }}
                    disableRipple
                  />
                )
              })}
            </TabList>
          </Grid>
          {!isLoadingEnvironments && currentEnvironment && (
            <Grid item xs={12}>
              <TabPanel value='web' className={styles.tabContent}>
                <WebSection
                  isLoading={isLoading}
                  trafficRules={trafficRules ?? []}
                  isCreationLimitExceeded={isCreationLimitExceeded}
                  currentEnvironment={currentEnvironment}
                />
              </TabPanel>
              <TabPanel value='mobile' className={styles.tabContent}>
                <MobileSection
                  isLoading={isLoading}
                  trafficRules={trafficRules ?? []}
                  isCreationLimitExceeded={isCreationLimitExceeded}
                  currentEnvironment={currentEnvironment}
                />
              </TabPanel>
              <TabPanel value='bots' className={styles.tabContent}>
                <BotsSection />
              </TabPanel>
            </Grid>
          )}
        </TabContext>
      </MainGrid>
    </>
  )
}

function TrafficRulesInfoDrawer() {
  return (
    <InfoDrawer title='Request filtering' action='Read more' actionHref={DOCS_REQUEST_FILTERING_URL}>
      <Typography variant='body1'>
        You can filter out unwanted visitor identifications using the request filtering rules.
      </Typography>

      <Typography variant='body1'>
        Identification requests can be filtered by origin using the <b>websites</b> section, or by HTTP headers using
        the <b>Forbidden HTTP Headers</b> section.
      </Typography>
    </InfoDrawer>
  )
}
