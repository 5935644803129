import { CheckCircleOutline } from '@mui/icons-material'
import { Link, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { useCurrentSubscription } from 'features/subscription'
import { trackSubscriptionUpgraded } from 'helpers/vendor'
import { BillingData, Promotion, SubscriptionTier } from 'models'
import { ampli } from 'models/ampli'
import { FormProvider, useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import FullpageLoader from '../../../../components/Loader/FullpageLoader/FullpageLoader'
import { PurchaseSummary } from '../../../../components/PurchaseSummary/PurchaseSummary'
import StatusDialog from '../../../../components/StatusDialog/StatusDialog'
import ProtectedByStripe from '../../../../components/Stripe/ProtectedByStripe/ProtectedByStripe'
import StripeForm from '../../../../components/Stripe/StripeForm/StripeForm'
import UpgradeToEnterprise from '../../../../components/UpgradeToEnterprise/UpgradeToEnterprise'
import {
  DEFAULT_PRODUCT,
  PLAN_FEATURES,
  PRO_PLUS_99_BILLING_DATA,
  PRO_PLUS_BILLING_DATA,
  SUBSCRIPTION_DISPLAY_PLANS,
  SUBSCRIPTION_TIER_TO_DISPLAY_PLAN,
} from '../../../../const'
import { usePaymentMethods, usePrice, useSubscription } from '../../../../hooks'
import { useChangePlan } from '../../../../hooks/changePlan'
import { ChangePlanProps, ChangePlanStep } from './ChangePlan'

export interface UpgradeProps extends ChangePlanProps {
  newPlan: SubscriptionTier
  promotionId?: number
}

export type UpgradeFormData = {
  cardHolderName?: string
  paymentMethodId?: string
}

export default function Upgrade({ newPlan, step, onChangeStep }: UpgradeProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(subscriptionId)
  const promotion = subscription?.promotions?.find(
    (el) => el.priceLookupKey === newPlan && new Date() > new Date(el.startAt) && new Date() < new Date(el.endAt)
  )
  const { changePlan, isLoading: isChangingPlan } = useChangePlan(subscriptionId, 'upgrade application page')
  const { price } = usePrice(DEFAULT_PRODUCT)

  function handleUpgrade(data: UpgradeFormData) {
    changePlan({
      data: {
        newPlan,
        promotionId: promotion?.id,
        ...data,
      },
      onSuccess: () => {
        trackSubscriptionUpgraded()
        ampli.subscriptionPurchased({
          // TODO: make this more generic. Campaigns code looks useful, but now it's too many hardcode here.
          campaignName: promotion ? 'Q2-2023: Promotional Price' : undefined,
          priceDiscount: promotion ? (newPlan === SubscriptionTier.Pro ? '-25%' : '-50%') : undefined,
          subscriptionID: subscriptionId,
          source: 'upgrade application page',
          applicationType: 'paid',
          billingPlanName: newPlan === SubscriptionTier.Pro ? 'Fingerprint Pro' : 'Fingerprint Pro Plus',
        })
        onChangeStep(ChangePlanStep.Confirmation)
      },
    })
  }

  switch (step) {
    case ChangePlanStep.Overview:
      return (
        <Overview
          tier={newPlan}
          coupon={promotion?.coupon}
          price={
            newPlan === SubscriptionTier.Pro
              ? price
              : newPlan === SubscriptionTier.Plus99
              ? PRO_PLUS_99_BILLING_DATA
              : PRO_PLUS_BILLING_DATA
          }
          onConfirm={() => onChangeStep(ChangePlanStep.Action)}
        />
      )
    case ChangePlanStep.Action:
      return <Action onConfirm={handleUpgrade} isLoading={isChangingPlan} />
    case ChangePlanStep.Confirmation:
      return <Confirmation />
    default:
      return null
  }
}

interface OverviewProps {
  tier: SubscriptionTier
  price?: BillingData
  coupon?: Promotion['coupon']
  onConfirm: () => void
}

function Overview({ tier, price, coupon, onConfirm }: OverviewProps) {
  const displayPlan = SUBSCRIPTION_TIER_TO_DISPLAY_PLAN[tier]

  return (
    <>
      <hgroup>
        <Typography variant='h2' component='h1'>
          {SUBSCRIPTION_DISPLAY_PLANS[displayPlan].name}
        </Typography>
        <Typography variant='body3' style={{ color: '#757575' }}>
          {tier === SubscriptionTier.Pro ? (
            'Identify more visitors with accuracy'
          ) : (
            <>
              <strong>99.5% accurate</strong> device intelligence and flexibility to fight sophisticated fraud types
            </>
          )}
        </Typography>
      </hgroup>

      <PurchaseSummary
        features={PLAN_FEATURES[displayPlan]}
        billingData={price}
        coupon={coupon}
        action='Proceed to payment'
        onAction={onConfirm}
      />

      <UpgradeToEnterprise pathname={AppRoute.ChangePlan} />
    </>
  )
}

interface ActionProps {
  onConfirm: (data: UpgradeFormData) => Promise<void> | void
  isLoading?: boolean
}

function Action({ onConfirm, isLoading }: ActionProps) {
  const { data: paymentMethods = [], isLoading: isLoadingPaymentMethods } = usePaymentMethods()
  const formMethods = useForm<UpgradeFormData>()
  const { handleSubmit } = formMethods

  return (
    <>
      <hgroup>
        <Typography variant='h2' component='h1'>
          Payment Information
        </Typography>
        <ProtectedByStripe />
      </hgroup>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onConfirm)}>
          <StripeForm isLoading={isLoading || isLoadingPaymentMethods} paymentMethods={paymentMethods} />
        </form>
      </FormProvider>

      {isLoading && <FullpageLoader testId='change-plan-loader' />}
    </>
  )
}

function Confirmation() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  return (
    <StatusDialog
      icon={<CheckCircleOutline color='primary' />}
      title='Your Plan Has Been Upgraded!'
      subtitle={
        <>
          If you need to change your payment information, go to the{' '}
          <Link component={RouterLink} to={AppRoute.Billing} underline='none'>
            billing page
          </Link>
          .
        </>
      }
      primaryAction={{
        name: 'Done',
        actionTo: buildRoute(AppRoute.SubscriptionOverview, { subscriptionId }),
      }}
    />
  )
}
