import { LinkButton } from '@compass/components'
import { Check, ChevronRight, Close, InfoOutlined, LockOutlined } from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { CircularProgress, IconButton, Paper, TableHead, Tooltip } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import EntityTableBodyEmpty from 'components/Table/CustomStatus/EntityTable'
import {
  Table,
  TableBody,
  TableBodyData,
  TableBodyEmpty,
  TableCell,
  TableContainer,
  TableRow,
} from 'components/Table/Table'
import Tag from 'components/Tag/Tag'
import { COMPANY_NAME, DOCS_WEBHOOKS_URL, GenericError } from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { isSafari } from 'helpers/browser'
import { useConfirmationDialog } from 'hooks'
import { usePermissions } from 'hooks/permissions'
import { EntityData, SubscriptionWebhook, WebhookVerificationStatus } from 'models'
import { ampli } from 'models/ampli'
import { memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import styles from './WebhooksTable.module.scss'

export interface WebhooksProps {
  entityData: EntityData
  className?: string
  webhooks: SubscriptionWebhook[]
  webhooksLimit: number
  isLoading?: boolean
  error?: GenericError | null
  onWebhookAdd: () => void
  onWebhookEdit: (id: string) => void
  onWebhookDelete: (id: string) => void
}

export default memo(function WebhooksTable({
  entityData,
  webhooks,
  isLoading,
  error,
  onWebhookEdit,
  onWebhookDelete,
}: WebhooksProps) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { openDialog } = useConfirmationDialog()
  const { isReadOnly } = usePermissions()

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width='40%'>Url</TableCell>
            <TableCell width='25%'>
              Verification status
              <Tooltip title='Only verified webhooks will receive events' placement='top' arrow>
                <InfoOutlined color='inherit' fontSize='small' className={styles.infoIcon} />
              </Tooltip>
            </TableCell>
            <TableCell width='20%'>Status</TableCell>
            <TableCell width='10%'>Actions</TableCell>
            <TableCell width='5%' />
          </TableRow>
        </TableHead>

        <TableBody columnCount={5} isLoading={isLoading} error={error}>
          <TableBodyData>
            {webhooks.map(({ id, url, verificationStatus, active, basicAuthUser }) => {
              const verified = verificationStatus === WebhookVerificationStatus.Verified
              const authenticated = Boolean(basicAuthUser)
              const eventLinkProps = {
                component: RouterLink,
                to: buildRoute(AppRoute.WebhookEvents, { subscriptionId: currentSubscriptionId, webhookId: id }),
              }

              return (
                <TableRow key={id} interactive {...eventLinkProps}>
                  <TableCell className={clsx({ [styles.unverified]: !verified }, styles.url)}>
                    {authenticated && <LockOutlined className={styles.lock} />} <DisplayUrl url={url} />
                  </TableCell>
                  <TableCell className={styles.verificationCell}>
                    <VerificationLabel status={verificationStatus} />
                    {!verified && (
                      <LinkButton
                        href={buildRoute(AppRoute.WebhookVerification, {
                          subscriptionId: currentSubscriptionId,
                          webhookId: id,
                        })}
                        className={styles.actionButton}
                      >
                        Verify
                      </LinkButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <StatusLabel isActive={active} />
                  </TableCell>
                  <TableCell>
                    <Tooltip title='Edit' placement='top' arrow>
                      <IconButton
                        aria-label='Edit Webhook'
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          ampli.editWebhookClicked()
                          onWebhookEdit(id)
                        }}
                        disabled={isReadOnly}
                        size='small'
                        color='inherit'
                        className={styles.actionIcon}
                      >
                        <EditIcon
                          color='inherit'
                          fontSize='small'
                          className={clsx({ [styles.disabled]: isReadOnly })}
                        />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title='Delete' placement='top' arrow>
                      <IconButton
                        aria-label='Delete Webhook'
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          ampli.deleteWebhookClicked()
                          openDialog({
                            label: 'Delete Webhook?',
                            onConfirm: () => {
                              onWebhookDelete(id)
                            },
                          })
                        }}
                        disabled={isReadOnly}
                        size='small'
                        color='inherit'
                        className={styles.actionIcon}
                      >
                        <DeleteOutlineIcon
                          color='inherit'
                          fontSize='small'
                          className={clsx({ [styles.disabled]: isReadOnly })}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title='Webhook events' placement='top' arrow>
                      <ChevronRight fontSize='small' className={styles.chevron} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBodyData>

          <TableBodyEmpty>
            <EntityTableBodyEmpty
              entityData={entityData}
              title='Webhooks'
              body={
                <>
                  Webhook is when {COMPANY_NAME} makes an HTTP POST request to your application&apos;s API when an event
                  occurs. This way, {COMPANY_NAME} is able to immediately notify you when an event occurs and your
                  application doesn&apos;t have to perform complicated polling to determine if something new happened.
                  Webhooks are asynchronous and don&apos;t incur performance overhead when enabled.
                </>
              }
              docsUrl={DOCS_WEBHOOKS_URL}
              showAddButton
            />
          </TableBodyEmpty>
        </TableBody>
      </Table>
    </TableContainer>
  )
})

interface VerificationLabelProps {
  status?: SubscriptionWebhook['verificationStatus']
}

function VerificationLabel({ status }: VerificationLabelProps) {
  switch (status) {
    case 'unverified':
      return <Tag label='Unverified' color='gray' />
    case 'verifying':
      return (
        <Tag
          icon={
            <>
              <CircularProgress color='inherit' size={14} />
            </>
          }
          label='Verifying'
          color='yellow'
        />
      )
    case 'verified':
      return <Tag icon={<Check />} color='green' />
    case 'failed':
    default:
      return <Tag icon={<Close />} color='red' />
  }
}

interface StatusLabelProps {
  isActive?: boolean
}

function StatusLabel({ isActive }: StatusLabelProps) {
  return isActive ? <Tag label='Enabled' color='blue' /> : <Tag label='Disabled' color='gray' />
}

interface DisplayUrlProps {
  url: string
}

function DisplayUrl({ url }: DisplayUrlProps) {
  const willOverflow = url.length > 66

  // When something triggers text-overflow: ellipsis; Safari already shows a tooltip with the full text by default.
  return willOverflow && !isSafari() ? (
    <Tooltip title={url} placement='bottom-start'>
      <span>{url}</span>
    </Tooltip>
  ) : (
    <span>{url}</span>
  )
}
