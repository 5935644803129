import { ForwardedRef, forwardRef } from 'react'

import { Button, type ButtonProps } from './Button/Button'
import { LinkButton, type LinkButtonProps } from './LinkButton'

export type ButtonOrLinkProps = (ButtonProps & { href: never }) | (LinkButtonProps & { href?: string })

export const ButtonOrLink = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonOrLinkProps>((props, ref) =>
  props.href !== undefined ? (
    <LinkButton {...props} ref={ref as ForwardedRef<HTMLAnchorElement>} />
  ) : (
    <Button {...props} ref={ref as ForwardedRef<HTMLButtonElement>} />
  )
)
