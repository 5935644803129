import { Button } from '@compass/components'
import { HttpOutlined, LanguageOutlined, SmartphoneOutlined, VpnKeyOutlined } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'

import styles from './EntityEmptyState.module.scss'

interface EntityEmptyStateProps {
  entity: 'api_key' | 'origin_traffic_rule' | 'mobile_traffic_rule' | 'header_traffic_rule'
  title: string
  description: React.ReactNode
  buttonText: string
  buttonTestId?: string
  isButtonDisabled?: boolean
  disabledTooltip?: string
  onClick: () => void
}

export function EntityEmptyState({
  entity,
  title,
  description,
  buttonText,
  buttonTestId,
  isButtonDisabled,
  disabledTooltip,
  onClick,
}: EntityEmptyStateProps) {
  const Icon = () => {
    switch (entity) {
      case 'api_key':
        return <VpnKeyOutlined />
      case 'header_traffic_rule':
        return <HttpOutlined />
      case 'mobile_traffic_rule':
        return <SmartphoneOutlined />
      case 'origin_traffic_rule':
      default:
        return <LanguageOutlined />
    }
  }

  const button = (
    <Button className={styles.button} onPress={onClick} isDisabled={isButtonDisabled} data-testid={buttonTestId}>
      {buttonText}
    </Button>
  )

  return (
    <Box className={styles.emptyState}>
      <Box className={styles.emptyStateIcon}>
        <Icon />
      </Box>
      <Box className={styles.emptyStateTextContent}>
        <Typography variant='semiBody1'>{title}</Typography>
        <Typography variant='bodyM' textAlign='center'>
          {description}
        </Typography>
      </Box>

      {isButtonDisabled ? (
        <Tooltip title={disabledTooltip ?? ''} placement='top'>
          <span>{button}</span>
        </Tooltip>
      ) : (
        button
      )}
    </Box>
  )
}
