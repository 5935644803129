import { Button } from '@compass/components'
import { Add, Delete } from '@mui/icons-material'
import { Grid, Link, Paper, TextField, Typography } from '@mui/material'
import { useState } from 'react'

import { DEFAULT_ENVIRONMENT, DOCS_MULTIPLE_ENVIRONMENTS_URL } from '../../../../const'
import { usePermissions, useToast } from '../../../../hooks'
import { useEntityLimit } from '../../../../hooks/api/context'
import { useWorkspaceEnvironments } from '../../../../hooks/api/environment'
import { useSubscriptionTokens } from '../../../../hooks/api/tokens'
import { ApiKey, Limits, WorkspaceEnvironment } from '../../../../models'
import { ampli } from '../../../../models/ampli'
import { useCurrentSubscription } from '../../../subscription'
import CreateWorkspaceEnvironmentDialog from '../CreateEnvironmentDialog/CreateWorkspaceEnvironmentDialog'
import DeleteWorkspaceEnvironmentDialog from '../DeleteEnvironmentDialog/DeleteWorkspaceEnvironmentDialog'
import styles from './WorkspaceEnvironmentTable.module.scss'

export function WorkspaceEnvironmentTable() {
  const { showToast } = useToast()
  const { isReadOnly } = usePermissions()
  const { currentSubscriptionId } = useCurrentSubscription()
  const maxEnvironmentsLimit = useEntityLimit(currentSubscriptionId, Limits.WorkspaceEnvironments)

  const [isDeleteEnvironmentDialogOpen, setIsDeleteEnvironmentDialogOpen] = useState(false)
  const [isCreateEnvironmentDialogOpen, setIsCreateEnvironmentDialogOpen] = useState(false)
  const [selectedEnv, setSelectedEnv] = useState<WorkspaceEnvironment | null>(null)

  const { data: workspaceEnvironmentsData, isLoading } = useWorkspaceEnvironments(currentSubscriptionId)

  const envLimitReached =
    !isLoading && workspaceEnvironmentsData != null && maxEnvironmentsLimit <= workspaceEnvironmentsData.length

  //TODO Need to get api keys related only to the environment that is being checked to be deleted
  const { data: apiKeysData, isLoading: isLoadingApiKeys } = useSubscriptionTokens(currentSubscriptionId)

  const apiKeyPerEnv: { [id: string]: boolean } = {}
  apiKeysData &&
    apiKeysData.forEach((apiKey: ApiKey) => {
      if (apiKey.workspaceEnvironmentId) {
        apiKeyPerEnv[apiKey.workspaceEnvironmentId] = true
      }
    })

  const handleDeleteEnvironment = (workspaceEnvironment: WorkspaceEnvironment) => {
    ampli.environmentDeleteStarted()
    setSelectedEnv(workspaceEnvironment)
    setIsDeleteEnvironmentDialogOpen(true)
  }

  const onAddEnvironment = () => {
    setIsCreateEnvironmentDialogOpen(true)
  }

  const onCloseDialog = () => {
    setIsCreateEnvironmentDialogOpen(false)
    setIsDeleteEnvironmentDialogOpen(false)
  }

  const canBeDeleted = (workspaceEnvironment: WorkspaceEnvironment) => {
    return (
      !isReadOnly &&
      workspaceEnvironment.name !== DEFAULT_ENVIRONMENT &&
      apiKeysData &&
      !apiKeyPerEnv[workspaceEnvironment.id]
    )
  }

  const alertDeleteBlocked = (workspaceEnvironment: WorkspaceEnvironment) => {
    let message: string
    if (isReadOnly) {
      message = "You don't have permission to update environments configuration"
    } else if (workspaceEnvironment.name === DEFAULT_ENVIRONMENT) {
      message = 'The default environment cannot be deleted.'
    } else {
      message =
        'Environments with API keys cannot be deleted. You must delete all API keys under this environment to delete it.'
    }

    showToast({
      severity: 'warning',
      message: message,
    })
  }

  return (
    <Paper className={styles.card}>
      <div className={styles.headingContainer}>
        <div className={styles.headingContent}>
          <div>
            <Typography variant='h3' component='h2' color='danger' className={styles.deleteText}>
              Environments
            </Typography>
            <Typography variant='bodyM'>
              Use environments to contain API keys and request filtering rules, like &quot;staging&quot;.{' '}
              <Link href={DOCS_MULTIPLE_ENVIRONMENTS_URL} target='_blank'>
                Learn more about environments
              </Link>
              .
            </Typography>
          </div>
          <div className={styles.buttonDiv}>
            <Button onPress={onAddEnvironment} className={styles.button} isDisabled={isReadOnly || envLimitReached}>
              <Add fontSize='inherit' />
              Create environment
            </Button>
          </div>
        </div>
      </div>

      <Grid container spacing={2}>
        {!isLoading &&
          !isLoadingApiKeys &&
          workspaceEnvironmentsData?.map((workspaceEnvironment, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TextField
                value={workspaceEnvironment.name}
                fullWidth
                className={styles.environmentField}
                InputProps={{
                  endAdornment: (
                    <div
                      className={`${styles.deleteIcon} ${canBeDeleted(workspaceEnvironment) ? '' : styles.disabled}`}
                    >
                      <Button
                        isIcon
                        variant='ghost'
                        aria-label='Delete environment'
                        onPress={
                          canBeDeleted(workspaceEnvironment)
                            ? () => handleDeleteEnvironment(workspaceEnvironment)
                            : () => alertDeleteBlocked(workspaceEnvironment)
                        }
                      >
                        <Delete fontSize='inherit' />
                      </Button>
                    </div>
                  ),
                }}
              />
            </Grid>
          ))}
      </Grid>
      {workspaceEnvironmentsData && isCreateEnvironmentDialogOpen && (
        <CreateWorkspaceEnvironmentDialog open onClose={onCloseDialog} environments={workspaceEnvironmentsData} />
      )}
      {isDeleteEnvironmentDialogOpen && selectedEnv && (
        <DeleteWorkspaceEnvironmentDialog open onClose={onCloseDialog} workspaceEnvironment={selectedEnv} />
      )}
    </Paper>
  )
}
