import { ButtonOrLink } from '@compass/components'
import { Close, InfoOutlined } from '@mui/icons-material'
import { Drawer, Hidden, IconButton, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'

import styles from './InfoDrawer.module.scss'

export interface InfoDrawerProps {
  title: string
  children: React.ReactNode
  action?: string
  isOpen?: boolean
  actionHref?: string
  onAction?: () => void
  onClose?: (event: {}) => void
}

export default function InfoDrawer({
  title,
  children,
  action,
  isOpen,
  actionHref,
  onAction,
  onClose,
}: InfoDrawerProps) {
  const [isOpenInternal, setIsOpenInternal] = useState(isOpen ?? false)

  function handleClose(event: {}) {
    onClose?.(event)
    setIsOpenInternal(false)
  }

  const content = (
    <>
      <header className={styles.header}>
        <Typography variant='h2' component='h3'>
          {title}
        </Typography>
        <IconButton onClick={handleClose} size='small'>
          <Close className={styles.icon} />
        </IconButton>
      </header>

      <div className={styles.content}>{children}</div>

      <div className='mt-8'>
        {action ? (
          <ButtonOrLink
            variant='outline'
            onPress={onAction}
            href={actionHref}
            target={actionHref ? '_blank' : undefined}
          >
            {action}
          </ButtonOrLink>
        ) : null}
      </div>
    </>
  )

  return (
    <>
      <Tooltip title='Info'>
        <IconButton onClick={() => setIsOpenInternal(true)} size='small' className={styles.infoButton}>
          <InfoOutlined className={styles.info} />
        </IconButton>
      </Tooltip>

      <Hidden smUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor='right'
          open={isOpen ?? isOpenInternal}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          onClose={handleClose}
          classes={{ paper: styles.infoDrawer }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xlDown implementation='css'>
        <Drawer
          variant='temporary'
          anchor='right'
          open={isOpen ?? isOpenInternal}
          onClose={handleClose}
          classes={{ paper: styles.infoDrawer }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}
