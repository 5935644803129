import { LinkButton } from '@compass/components'
import { Link, Typography } from '@mui/material'

import { DOCS_INTEGRATIONS, SUPPORT_EMAIL_MAILTO } from '../../../../const'
import { ProxyIntegrationRequests, SdkHealthSdkDetails } from '../../../../models'
import { SemanticVersion } from '../../../health/utils/SemanticVersion'
import Logo from '../../components/IntegrationMetadata/icons/fastly-vcl.svg'
import IntegrationPage from '../../components/IntegrationPage/IntegrationPage'
import { IntegrationPageSectionDefinition } from '../../components/IntegrationPage/IntegrationPageSection/IntegrationPageSection'
import { IntegrationPageAlertProps } from '../../components/IntegrationPage/IntegrationTopFrame/IntegrationTopFrame'
import { IntegrationStatus } from '../../components/IntegrationPage/types'
import ChartIcon from '../../components/UsageChart/Icons/ChartIcon.svg'
import { ProxyIntegrationUsageChart } from '../../components/UsageChart/ProxyIntegrationUsageChart'
import styles from './IntegrationFastlyContents.module.scss'

export type IntegrationFastlyVCLContentsProps = {
  subscriptionId: string
  requestsMade?: ProxyIntegrationRequests
  health?: SdkHealthSdkDetails
  isLoading?: boolean
}

function extractStatusFromFastlyVCLUsageData(requestsMade?: ProxyIntegrationRequests): IntegrationStatus {
  if (!requestsMade) {
    return IntegrationStatus.NotInstalled
  }

  const requestsOverPeriod = requestsMade.proxyRequests.reduce((sum, curr) => (sum += curr.value), 0)
  if (requestsOverPeriod > 0) {
    return IntegrationStatus.Installed
  }
  return IntegrationStatus.NotInstalled
}

function extractStatusLabelFromStatus(integrationStatus: IntegrationStatus): string {
  switch (integrationStatus) {
    case IntegrationStatus.Installed:
      return `Kudos! The integration has been successfully installed.`
    default:
    case IntegrationStatus.NotInstalled:
      return `Click (or tap) the “View documentation” button to get started.`
  }
}

export function IntegrationFastlyVCLContents({
  subscriptionId,
  isLoading,
  health,
  requestsMade,
}: IntegrationFastlyVCLContentsProps) {
  const repositoryVersion = health?.latestVersion
  const lastCustomerVersionFromUsage = health?.versions.sort((a, b) => {
    return SemanticVersion.compare(SemanticVersion.fromString(b.version), SemanticVersion.fromString(a.version))
  })[0]?.version

  const status = extractStatusFromFastlyVCLUsageData(requestsMade)
  const statusLabel = extractStatusLabelFromStatus(status)

  const chartVisible =
    requestsMade &&
    requestsMade.proxyRequests?.length > 0 &&
    requestsMade.proxyRequests.map((it) => it.value).reduce((sum, curr) => (sum += curr), 0) > 0

  const lastXDays = Math.min(requestsMade?.allRequests?.length ?? 0, 30)

  const versionDiff = SemanticVersion.getDifference(
    SemanticVersion.fromString(lastCustomerVersionFromUsage ?? ''),
    SemanticVersion.fromString(repositoryVersion ?? '')
  )

  let alertTitle = ''
  switch (versionDiff) {
    case 'major':
      alertTitle = 'Update required'
      break
    case 'minor':
      alertTitle = 'Update needed'
      break
    case 'patch':
      alertTitle = 'Update recommended'
      break
  }

  const getAlertProps = (): IntegrationPageAlertProps | undefined => {
    // Show warning if user is using outdated integration
    if (versionDiff !== 'same') {
      return {
        alertSeverity: 'warning',
        alertTitle,
        alertContent: (
          <div>
            <Typography variant='bodyM' className={styles.updateAlertText}>
              Outdated client SDKs lower accuracy and may cause errors. An update is recommended to achieve maximum
              Fingerprint strength.
            </Typography>
          </div>
        ),
      }
    }

    return undefined
  }

  const sections: IntegrationPageSectionDefinition[] =
    status === IntegrationStatus.NotInstalled
      ? [
          chartVisible
            ? {
                key: 'usage',
                title: 'Fastly VCL Usage',
                info: `Last ${lastXDays} Days`,
                content: <ProxyIntegrationUsageChart requests={requestsMade} integrationName='Fastly VCL' />,
              }
            : {
                key: 'overview',
                title: 'Overview',
                description: 'This integration is actively maintained by the Fingerprint team.',
                content: (
                  <div className={styles.notInstalledContainer}>
                    <Typography variant='body1'>
                      Fingerprint Fastly Proxy Integration is responsible for proxying device intelligence and
                      agent-download requests between your website and Fingerprint through your Fastly infrastructure.
                      This improves both effectiveness and accuracy of visitor identification.
                    </Typography>
                    <Typography variant='body1' className={styles.notInstalledParagraphSupport}>
                      The best way to get started is with our dedicated Fastly Proxy Integration guide. If you have any
                      questions while setting it up, please{' '}
                      <Link href={SUPPORT_EMAIL_MAILTO} target='_blank'>
                        contact support
                      </Link>
                      .
                    </Typography>
                  </div>
                ),
              },
        ]
      : [
          chartVisible
            ? {
                key: 'usage',
                title: 'Fastly VCL Usage',
                info: 'Last 30 Days',
                content: <ProxyIntegrationUsageChart requests={requestsMade} integrationName='Fastly VCL' />,
              }
            : {
                key: 'usage',
                title: 'Fastly VCL Usage',
                info: '',
                content: (
                  <div className={styles.noUsageDataContainer}>
                    <div className={styles.noDataIconContainer}>
                      <ChartIcon />
                    </div>
                    <div>
                      <Typography variant='semiBody1' component='h4' className={styles.noUsageDataTitle} align='center'>
                        No Usage Data Yet
                      </Typography>
                      <Typography variant='bodyM' className={styles.noUsageDataContainerText} align='center'>
                        We need more data to generate this chart. Make some API requests!
                      </Typography>
                    </div>
                    <LinkButton href={DOCS_INTEGRATIONS.fastlyVcl} target='_blank'>
                      View Documentation
                    </LinkButton>
                  </div>
                ),
              },
        ]

  return (
    <>
      <IntegrationPage
        {...getAlertProps()}
        isLoading={isLoading}
        integrationStatus={status}
        title='Fastly VCL'
        subscriptionId={subscriptionId}
        statusLabel={statusLabel}
        docsUrl={DOCS_INTEGRATIONS.fastlyVcl}
        repoUrl='https://github.com/fingerprintjs/fingerprint-pro-fastly-vcl-integration'
        Logo={Logo}
        metadata={
          chartVisible && status !== IntegrationStatus.NotInstalled
            ? [
                {
                  title: 'Your version',
                  value: lastCustomerVersionFromUsage,
                  versionBadge: versionDiff !== 'same' ? versionDiff : undefined,
                },
                {
                  title: 'Latest version',
                  value: repositoryVersion,
                },
              ]
            : [
                {
                  title: 'Latest version',
                  value: repositoryVersion,
                },
              ]
        }
        sections={sections}
      />
    </>
  )
}
