import { Button } from '@compass/components'
import { InfoOutlined } from '@mui/icons-material'
import { Box, Paper, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'

import FullpageLoader from '../../../../components/Loader/FullpageLoader/FullpageLoader'
import Loader from '../../../../components/Loader/Loader'
import { stringifyValues } from '../../../../helpers/common'
import { ConfirmationDialog } from '../../../../hooks'
import { Platform, SuspectScore } from '../../../../models'
import { PLATFORM_NAMES } from '../../content'
import { SuspectScoreItem } from '../SuspectScoreItem/SuspectScoreItem'

export type SuspectScoreFormData = Partial<Record<SuspectScore, number>>

export interface SuspectScoreListProps {
  platform: Platform
  weights: SuspectScoreFormData
  defaultWeights: SuspectScoreFormData
  onSave: (data: Record<string, string>) => void | Promise<void>
  onReset: () => void | Promise<void>
  onDirtyStateChanged?: (dirtyState: boolean) => void
  isLoading?: boolean
}

export function SuspectScoreList({
  platform,
  weights,
  defaultWeights,
  onSave,
  onReset,
  onDirtyStateChanged,
  isLoading,
}: SuspectScoreListProps) {
  // Dirty state tracking does not work on number inputs unless the form values are converted to strings.
  // https://github.com/react-hook-form/react-hook-form/issues/3213
  const stringifiedWeights = useMemo(() => stringifyValues(weights), [weights])
  const stringifiedDefaults = useMemo(() => stringifyValues(defaultWeights), [defaultWeights])

  const formMethods = useForm({
    values: stringifiedWeights,
    defaultValues: stringifiedWeights,
  })
  const { reset, formState, handleSubmit } = formMethods
  const { isDirty } = formState

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    // Form defaults are the values that are currently saved, not the default weights.
    reset(stringifiedWeights)
  }, [stringifiedWeights, reset])

  useEffect(() => {
    onDirtyStateChanged?.(isDirty)
  }, [onDirtyStateChanged, isDirty])

  return (
    <Container isLoading={isLoading}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit((data) => onSave(data))}>
          <Stack padding={smDown ? '16px 0' : '24px'} rowGap={smDown ? '8px' : 0}>
            {!smDown && <Header />}
            {Object.keys(weights).map((key) => (
              <SuspectScoreItem
                id={key as SuspectScore}
                platform={platform}
                defaultWeight={defaultWeights?.[key as SuspectScore] ?? 0}
                key={key}
              />
            ))}
          </Stack>
          <Actions
            platform={platform}
            stringifiedDefaults={stringifiedDefaults}
            onReset={onReset}
            isLoading={isLoading}
          />
        </form>
      </FormProvider>
    </Container>
  )
}

interface ContainerProps extends PropsWithChildren {
  isLoading?: boolean
}

function Container({ children, isLoading }: ContainerProps) {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return smDown ? (
    <>
      {children}
      {isLoading && <FullpageLoader data-testid='suspect-score-loader' />}
    </>
  ) : (
    <Paper sx={{ position: 'relative', overflow: 'hidden' }}>
      {children}
      {isLoading && <Loader data-testid='suspect-score-loader' />}
    </Paper>
  )
}

function Header() {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 96px 120px',
        columnGap: '16px',
        alignItems: 'center',
      }}
    >
      <Typography variant='caption'>Smart signal</Typography>
      <Stack direction='row' alignItems='center'>
        <Typography variant='caption'>Weight</Typography>
        <Tooltip
          placement='top'
          title='Customize the weight of the Smart Signal to adjust how indicative it is of suspicious or fraudulent activity.'
        >
          <InfoOutlined fontSize='tiny' sx={{ color: '#757575', marginLeft: '4px' }} />
        </Tooltip>
      </Stack>
    </Box>
  )
}

interface ActionsProps {
  platform: Platform
  stringifiedDefaults: Record<string, string>
  onReset: () => void | Promise<void>
  isLoading?: boolean
}

function Actions({ platform, stringifiedDefaults, onReset, isLoading }: ActionsProps) {
  const { formState, reset } = useFormContext()
  const { isDirty } = formState

  const [isResetting, setIsResetting] = useState(false)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  function resetDefaults() {
    reset(stringifiedDefaults)
    onReset()
    setIsResetting(false)
  }

  function getDisplayName(platform: Platform) {
    const name = PLATFORM_NAMES[platform]

    if (platform === Platform.Browser) {
      return name.toLowerCase()
    }
    return name
  }

  return (
    <>
      <Stack
        padding={smDown ? '16px 0' : '24px'}
        direction={smDown ? 'column-reverse' : 'row'}
        columnGap='16px'
        rowGap='16px'
        justifyContent='flex-end'
        sx={{ borderTop: '1px solid #E0E0E0' }}
      >
        <Button variant='ghost' onPress={() => setIsResetting(true)} isDisabled={isLoading}>
          Restore {PLATFORM_NAMES[platform]} defaults
        </Button>
        <Button type='submit' isDisabled={!isDirty || isLoading}>
          Save changes for {PLATFORM_NAMES[platform]}
        </Button>
      </Stack>

      <ConfirmationDialog
        title='Are you sure?'
        content={
          <Typography variant='bodyM'>
            Restoring to defaults will remove your custom weights for all {getDisplayName(platform)} signals.
          </Typography>
        }
        confirmText='Restore defaults'
        onConfirm={resetDefaults}
        onReject={() => setIsResetting(false)}
        open={isResetting}
      />
    </>
  )
}
