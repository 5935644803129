import { useSearchParams } from 'hooks/useSearchParams'
import { VisitsFilter } from 'models'
import { useEffect } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'

import { useSubmittedFilteredFormValues } from './useSubmittedFilteredFormValues'

export enum IdentificationUrlKeys {
  RequestId = 'requestId',
  VisitorId = 'visitorId',
  IpAddress = 'ipAddress',
  Url = 'url',
  LinkedId = 'linkedId',
  Origin = 'origin',
  Since = 'since',
  Before = 'before',
}

const MANAGED_KEYS: Array<keyof VisitsFilter> = [
  IdentificationUrlKeys.RequestId,
  IdentificationUrlKeys.VisitorId,
  IdentificationUrlKeys.IpAddress,
  IdentificationUrlKeys.Url,
  IdentificationUrlKeys.LinkedId,
  IdentificationUrlKeys.Origin,
  IdentificationUrlKeys.Since,
  IdentificationUrlKeys.Before,
]

export const useQueryParamSync = (
  form: UseFormReturn<VisitsFilter>,
  onSubmit: SubmitHandler<VisitsFilter> = () => {}
) => {
  const filters = useSubmittedFilteredFormValues(form)
  const [currentSearchParams, setSearchParams] = useSearchParams<VisitsFilter>(MANAGED_KEYS)

  useEffect(
    () => {
      for (const [key, value] of Object.entries(currentSearchParams)) {
        form.setValue(key as keyof VisitsFilter, value, { shouldDirty: true })
      }
      form.handleSubmit(onSubmit)()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    setSearchParams(filters)
  }, [filters, setSearchParams])

  return currentSearchParams
}
