import { Button } from '@compass/components'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, IconButton, TextField, Typography } from '@mui/material'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import styles from './UpdateGeneralSettingDialog.module.scss'

export interface UpdateGeneralSettingDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: { newValue: string }) => void
  title: string
  editField: string
  defaultValue: string | undefined
  validationRules: Record<string, unknown>
  disabled: boolean
}

type FormValues = { newValue: string }

export default function UpdateGeneralSettingDialog({
  isOpen,
  onClose,
  onSubmit,
  title,
  editField,
  defaultValue,
  validationRules,
  disabled,
}: UpdateGeneralSettingDialogProps) {
  const { register, handleSubmit, formState, reset: resetForm } = useForm<FormValues>()

  useEffect(() => {
    return () => resetForm()
  }, [resetForm])

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      className={styles.dialog}
      PaperProps={{ className: styles.dialogPaper }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.dialogContent}>
        <Box className={styles.closeSection}>
          <IconButton className={styles.closeButton} aria-label='close' onClick={onClose} size='large'>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant='h2' component='h2'>
          {title}
        </Typography>
        <TextField
          placeholder={`Application ${editField}`}
          defaultValue={defaultValue}
          autoComplete='off'
          fullWidth
          {...muiRegister(register, 'newValue', validationRules)}
          {...getErrorParams('newValue', formState.errors)}
        />
        <Box className={styles.actions}>
          <Button onPress={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button type='submit' isDisabled={disabled}>
            Change
          </Button>
        </Box>
      </form>
    </Dialog>
  )
}
