import { Typography } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'

import styles from './PageHeader.module.scss'

export interface PageHeaderProps {
  title: string
  subTitle?: string | ReactNode
  infoDrawer?: ReactNode
  actions?: ReactNode | ReactNode[]
  className?: string
}

export default function PageHeader({
  title,
  subTitle,
  infoDrawer,
  actions,
  className,
}: PropsWithChildren<PageHeaderProps>) {
  return (
    <header className={clsx(styles.header, className)}>
      <div className={styles.titleSection}>
        <hgroup className={styles.title}>
          <Typography variant={subTitle ? 'h2' : 'h5'} component='h2'>
            {title}
          </Typography>
          {subTitle && (
            <Typography variant='body2' component='p' color='text.secondary'>
              {subTitle}
            </Typography>
          )}
        </hgroup>
        {infoDrawer}
      </div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </header>
  )
}
