import { MenuItemProps } from '@mui/material'

import { DISCORD_INVITE_URL } from '../../../../const'
import DiscordLogo from '../../../../icons/Discord.svg'
import { GenericMenuItem, GenericMenuItemOverrideProps } from './GenericMenuItem'

export const ExternalMenuItems = (props: MenuItemProps & GenericMenuItemOverrideProps) => {
  return (
    <>
      <GenericMenuItem
        icon={<DiscordLogo fill='none' stroke='currentColor' />}
        label='Join Discord'
        href={DISCORD_INVITE_URL}
        {...props}
      />
    </>
  )
}
