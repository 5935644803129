import { ComponentProps, forwardRef, LegacyRef } from 'react'
import { composeRenderProps, Link as AriaLink } from 'react-aria-components'

import { ButtonExtraProps, buttonStyles } from './Button/Button'

export type LinkButtonProps = ComponentProps<typeof AriaLink> & ButtonExtraProps
function LinkButton(
  {
    variant = 'primary',
    tone,
    alt,
    size = 'default',
    isIcon,
    fullWidth,
    isLoading,
    tooltip,
    ...props
  }: LinkButtonProps,
  ref?: LegacyRef<HTMLAnchorElement>
) {
  const styleProps = { variant, tone, alt, size, fullWidth, isIcon } as const
  return (
    <AriaLink
      {...props}
      ref={ref}
      isDisabled={props.isDisabled || isLoading}
      className={composeRenderProps(props.className, (className, renderProps) =>
        buttonStyles({
          ...renderProps,
          ...styleProps,
          className,
        })
      )}
    />
  )
}

const _LinkButton = forwardRef(LinkButton)

export { _LinkButton as LinkButton }
