import { Link } from '@mui/material'
import { BotdFilterFormType } from 'components/Filter/Botd/BotdFilter'
import { CounterQueryType, DOCS_BOTD_QUICK_START_URL } from 'const'
import { addDays } from 'date-fns'
import { Header, interpretBotDDateRange, MainContent } from 'features/commonUI'
import { useCurrentSubscription, useCurrentSubscriptionData } from 'features/subscription'
import { date } from 'helpers/data'
import { currentDateInZonedTime, getDateInUserTZ } from 'helpers/date'
import { calculateGranularityFromRange, DateRange } from 'helpers/dateRange'
import { isProductEnabled } from 'helpers/subscription'
import { ValueOf } from 'helpers/types'
import { useDocumentTitle } from 'hooks'
import { useUsageCounters } from 'hooks/api/usage_counters'
import { useCurrentUser } from 'hooks/api/users'
import { BotdVisitFilter, usePaginatedBotdVisits } from 'hooks/botdVisits'
import { AppProduct, BotdVisit, SortingOptions, UsageCounterPeriod } from 'models'
import { ampli } from 'models/ampli'
import { useState } from 'react'

import BotDIntroduction from './components/BotDIntroduction/BotDIntroduction'
import { BotdOverview } from './components/BotdOverview/BotdOverview'
import styles from './components/BotdOverview/BotdOverview.module.scss'
import IntroductionModal from './components/BotdOverview/IntroductionModal/IntroductionModal'

export function BotdOverviewPage() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { subscription, isLoading: isLoadingSubscription } = useCurrentSubscriptionData()
  const createdAtDate = getDateInUserTZ(date(subscription?.createdAt ?? ''))

  const { data: currentUser } = useCurrentUser()
  const isBotdDisabled = subscriptionId && !isLoadingSubscription && !isProductEnabled(subscription, AppProduct.Botd)
  useDocumentTitle(isBotdDisabled ? 'Introducing Bot Detection' : 'Bot Detection Overview')

  const [dateRange, setDateRange] = useState<Required<DateRange>>(() => {
    const now = new Date(Date.now())
    return { startDate: addDays(now, -1), endDate: now }
  })
  const [granularity, setGranularity] = useState(UsageCounterPeriod.Hour)
  const { startDate, endDate } = interpretBotDDateRange(dateRange, currentUser?.timezone)

  const [tableOrderParams, setTableOrderParams] = useState<SortingOptions<BotdVisit>>({
    sortBy: 'timestamp',
    order: 'desc',
  })
  const [filter, setFilter] = useState<BotdVisitFilter | undefined>()

  const paginatedVisits = usePaginatedBotdVisits({
    subscriptionId,
    from: startDate?.valueOf(),
    before: endDate?.valueOf(),
    reverse: tableOrderParams.sortBy === 'timestamp' && tableOrderParams.order === 'asc',
    // Botd visits can only be filtered by requestId.
    requestId: filter?.value,
  })

  const {
    data: counters,
    isLoading: isLoadingCounters,
    error: countersError,
  } = useUsageCounters({
    subscriptionId,
    from: startDate,
    to: endDate,
    queryType: CounterQueryType.Botd,
    period: granularity,
    timezone: currentUser?.timezone,
    enabled: !isBotdDisabled,
    keepPreviousData: true,
  })

  const handleApplyDateRange = (range: Required<DateRange>) => {
    // Filters cannot be used together with date ranges.
    setFilter(undefined)

    setDateRange(range)
    setGranularity(calculateGranularityFromRange(range))
  }

  const handleFilter = (key?: keyof BotdFilterFormType, value?: ValueOf<BotdFilterFormType>) => {
    setFilter(key && value ? { key, value } : undefined)
  }

  if (isBotdDisabled) {
    return <BotDIntroduction />
  }

  return (
    <>
      <IntroductionModal />
      <Header
        title='Bot Detection'
        info={
          <Link
            href={DOCS_BOTD_QUICK_START_URL}
            target='_blank'
            underline='hover'
            className={styles.link}
            onClick={() => {
              ampli.botDDocumentationViewed()
            }}
            style={{ marginLeft: 24 }}
          >
            Get started {'>'}
          </Link>
        }
      />
      <MainContent>
        <BotdOverview
          counters={counters}
          tableProps={{
            ...paginatedVisits,
            ...tableOrderParams,
            onSort: (sortBy, order) => setTableOrderParams({ sortBy, order }),
            onRequestNextEntries: () => {
              ampli.botDPaginationClicked({ direction: 'forward' })
              paginatedVisits.onRequestNextEntries()
            },
            onRequestPreviousEntries: () => {
              ampli.botDPaginationClicked({ direction: 'backward' })
              paginatedVisits.onRequestPreviousEntries()
            },
          }}
          today={currentDateInZonedTime(currentUser?.timezone)}
          dateRange={dateRange}
          granularity={granularity}
          timezone={currentUser?.timezone}
          onApplyRange={handleApplyDateRange}
          appliedFilter={filter}
          onFilter={handleFilter}
          subscriptionCreatedAt={createdAtDate}
          subscriptionId={subscriptionId}
          error={countersError}
          isLoading={isLoadingSubscription}
          isLoadingCounters={isLoadingCounters}
        />
      </MainContent>
    </>
  )
}
