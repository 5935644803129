import { Button } from '@compass/components'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import styles from './Distribution.module.scss'
import { DistributionRow } from './DistributionRow'

const LOADING_ROWS = [
  <DistributionRow key='1' index={1} percentage={1} />,
  <DistributionRow key='2' index={2} percentage={1} />,
  <DistributionRow key='3' index={3} percentage={1} />,
  <DistributionRow key='4' index={4} percentage={1} />,
  <DistributionRow key='5' index={5} percentage={1} />,
]

const SHOWN_DISTRIBUTION_ROWS = 5
export function DistributionLimiter({
  header,
  rows,
  className,
}: {
  header: ReactNode
  rows?: ReactNode[]
  className?: HTMLAttributes<HTMLDivElement>['className']
}) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(false)
  const [maxHeight, setMaxHeight] = useState<number>()

  const displayedRows = rows ?? LOADING_ROWS

  const first = displayedRows.slice(0, SHOWN_DISTRIBUTION_ROWS)
  const rest = displayedRows.slice(SHOWN_DISTRIBUTION_ROWS)
  const showExpand = !expanded && (rows == null || rows.length > SHOWN_DISTRIBUTION_ROWS)

  const expand = useCallback(() => {
    setMaxHeight(containerRef.current ? containerRef.current.getBoundingClientRect().height + 16 : undefined)
    setExpanded(true)
  }, [])

  useEffect(() => {
    setExpanded(false)
  }, [rows])

  return (
    <>
      <div
        className={clsx(className, styles.padded)}
        style={
          expanded
            ? { maxHeight, overflowY: 'scroll', overflowX: 'visible', paddingBottom: 20 }
            : { paddingBottom: showExpand ? 0 : 20 }
        }
      >
        <div ref={containerRef} className={className}>
          {header}
          {first}
          {showExpand ? (
            <div className={styles.seeMore}>
              {rows == null ? (
                <Skeleton width={100} height={40} />
              ) : (
                <Button variant='ghost' alt fullWidth onPress={expand}>
                  See more
                </Button>
              )}
            </div>
          ) : null}
        </div>
        {expanded ? rest : null}
      </div>
    </>
  )
}
