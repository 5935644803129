import { Button } from '@compass/components'
import CloseIcon from '@mui/icons-material/Close'
import { DialogTitle, IconButton, Link, Typography } from '@mui/material'
import clsx from 'clsx'
import { DOCS_BOTD_QUICK_START_URL } from 'const'
import Botd from 'icons/Botd.svg'

import styles from './BotdMessagePane.module.scss'

interface Classes {
  root?: string
  title?: string
  subtitle?: string
  linkContainer?: string
  button?: string
}

export interface BotDMessagePaneProps {
  title: string
  subtitle: string
  buttonText?: string
  onClose: () => void
  onClickButton?: () => void
  classes?: Classes
}

export default function BotdMessagePane({
  onClose,
  title,
  subtitle,
  buttonText,
  onClickButton,
  classes,
}: BotDMessagePaneProps) {
  return (
    <div className={clsx([styles.root, classes?.root])}>
      <IconButton className={styles.closeButton} aria-label='close' onClick={onClose} size='large'>
        <CloseIcon />
      </IconButton>
      <div className={styles.contentWrapper}>
        <div className={styles.logoWrapper}>
          <Botd className={styles.botdLogo} />
        </div>
        <div className={styles.infoSection}>
          <DialogTitle className={clsx([styles.title, classes?.title])}>{title}</DialogTitle>
          <Typography variant='body1' className={clsx([styles.subTitle, classes?.subtitle])}>
            {subtitle}
          </Typography>
          <div className={clsx([styles.linkContainer, classes?.linkContainer])}>
            <Link underline='hover' className={styles.link} href={DOCS_BOTD_QUICK_START_URL} target={'_blank'}>
              Learn more about Bots {'>'}
            </Link>
          </div>
        </div>
        {buttonText && (
          <Button fullWidth size='lg' className={classes?.button} onPress={onClickButton}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  )
}
