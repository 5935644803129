import { Button } from '@compass/components'
import { Close } from '@mui/icons-material'
import { IconButton, InputLabel, TextField, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import { FilterDescriptor } from 'models'
import { ampli } from 'models/ampli'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { getErrorParams } from '../../../helpers/data'
import { getMatchingFilter } from '../../../helpers/filter'
import { muiRegister } from '../../../helpers/reactHookForm'
import { requestIdRegex, visitorIdRegex } from '../../../helpers/regex'
import { WebhookEventsFilterData, WebhookEventsIdFilter } from '../../../models'
import { Filter } from '../Filter'
import styles from './WebhookEventsFilter.module.scss'

type WebhookEventsFilterFormData = {
  id: string
}

export interface WebhookEventsFilterProps {
  isApplied?: boolean
  onFilter: (data: WebhookEventsFilterData) => void
}

export default function WebhookEventsFilter({ isApplied, onFilter }: WebhookEventsFilterProps) {
  const defaultValues = {
    id: '',
  } as WebhookEventsFilterFormData

  const [isOpen, setIsOpen] = useState(false)
  const [appliedValue, setAppliedValue] = useState<WebhookEventsFilterFormData>(defaultValues)

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm<WebhookEventsFilterFormData>({
    defaultValues,
  })

  const id = watch('id')

  const isDirty = id !== appliedValue.id

  const match = getMatchingFilter(filters, id)

  function handleOpen() {
    setIsOpen(true)
  }

  function handleClose() {
    setIsOpen(false)
  }

  function handleFilterChange(data: WebhookEventsFilterFormData) {
    ampli.webhookEventsFilterClicked()
    const filterData: WebhookEventsFilterData = {}

    const matchingFilter = getMatchingFilter(filters, data.id)
    if (matchingFilter?.key) {
      filterData.id = {
        key: matchingFilter.key,
        value: data.id,
      }
    }

    handleClose()

    onFilter(filterData)
    setAppliedValue(data)
  }

  function handleClearIdFilter() {
    ampli.webhooksFilterCleared()
    setValue('id', defaultValues.id)
    trigger('id')

    handleFilterChange({ ...appliedValue, id: '' })
  }

  return (
    <Filter isOpen={isOpen} onOpen={handleOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(handleFilterChange)} className={styles.container}>
        <header className={styles.header}>
          <Typography variant='semiBody1' component='h1'>
            Filter
          </Typography>
          {isApplied && (
            <Button variant='ghost' onPress={handleClearIdFilter} className={styles.clearButton}>
              Clear filter
            </Button>
          )}
        </header>

        <InputLabel htmlFor='id'>{match?.label ?? filterLabels}</InputLabel>
        <TextField
          id='id'
          autoFocus
          variant='outlined'
          placeholder={`Enter ${filterLabels}`}
          spellCheck={false}
          {...getErrorParams('id', errors)}
          {...muiRegister(register, 'id', {
            validate: (value) => (value.length === 0 || getMatchingFilter(filters, value) ? true : 'Invalid format.'),
          })}
          InputProps={{
            classes: {
              input: clsx({ [styles.monospace]: id?.length > 0 }),
            },
            endAdornment: appliedValue.id.length > 0 && (
              <Tooltip title='Clear filter' arrow placement='top'>
                <IconButton onClick={handleClearIdFilter} title='Clear filter' edge='end' size='small'>
                  <Close />
                </IconButton>
              </Tooltip>
            ),
          }}
          className={styles.input}
        />

        <footer className={styles.footer}>
          <Button variant='ghost' onPress={handleClose} aria-label='Cancel'>
            Cancel
          </Button>
          <Button
            variant='primary'
            type='submit'
            aria-label='Apply filter'
            isDisabled={!isDirty}
            className={styles.button}
          >
            Filter
          </Button>
        </footer>
      </form>
    </Filter>
  )
}

const filters: FilterDescriptor<WebhookEventsIdFilter>[] = [
  { key: 'requestID', label: 'requestID', selector: (data) => requestIdRegex.test(data) },
  { key: 'visitorID', label: 'visitorID', selector: (data) => visitorIdRegex.test(data) },
]

const filterLabels = filters.map(({ label }) => label).join(' or ')
