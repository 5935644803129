import { Button } from '@compass/components'
import { Edit } from '@mui/icons-material'
import { Paper, Skeleton, Typography } from '@mui/material'
import { BillingInfo } from 'models'

import styles from './BillingInfoCard.module.scss'

export interface BillingInfoCardProps {
  billingInfo?: Pick<BillingInfo, 'email' | 'taxId' | 'addressLine1'>
  isLoading?: boolean
  onEdit: () => void
}

export default function BillingInfoCard({ billingInfo, isLoading, onEdit }: BillingInfoCardProps) {
  return (
    <Paper component='section' className={styles.card}>
      <header className={styles.header}>
        <hgroup className={styles.title}>
          <Typography variant='h3' component='h1'>
            Billing Info
          </Typography>
          <Typography variant='bodyS'>Manage the details which appear on invoices.</Typography>
        </hgroup>

        <Button variant='secondary' onPress={onEdit}>
          <Edit fontSize='inherit' />
          Edit billing info
        </Button>
      </header>

      <Info
        entries={[
          { label: 'Account email', value: billingInfo?.email },
          { label: 'Tax ID', value: billingInfo?.taxId },
          { label: 'Billing address', value: billingInfo?.addressLine1 },
        ]}
        isLoading={isLoading}
      />
    </Paper>
  )
}

interface InfoProps {
  entries: Array<{
    label: string
    value?: string
  }>
  isLoading?: boolean
}

function Info({ entries, isLoading }: InfoProps) {
  return (
    <dl className={styles.info}>
      {entries.map(({ label, value }) => (
        <span key={label} className={styles.entry}>
          <Typography variant='bodyMMedium' component='dt'>
            {isLoading ? <Skeleton /> : value ?? '-'}
          </Typography>
          <Typography variant='bodyS' component='dd'>
            {label}
          </Typography>
        </span>
      ))}
    </dl>
  )
}
