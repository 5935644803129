import { LinkButton } from '@compass/components'
import {
  Divider,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import CardDescription from 'components/CardDescription/CardDescription'
import CardLogo from 'components/CardLogo'
import { DEFAULT_TRIAL_DAYS, PRODUCT_NAME, SUBSCRIPTION_DISPLAY_PLANS, SUBSCRIPTION_TIER_TO_DISPLAY_PLAN } from 'const'
import { formatMoney, formatNum } from 'helpers/format'
import { BillingData, PurchaseData, PurchaseType, SubscriptionTier } from 'models'
import { Link as RouterLink } from 'react-router-dom'

import styles from './PurchaseConfirmation.module.scss'

export default function PurchaseConfirmation({ purchaseData, billingData }: PlanDetailsProps) {
  const title = getTitle(purchaseData.type)

  return (
    <div className={styles.root}>
      <Typography component='h2' className={styles.title}>
        {title}
      </Typography>
      <Typography variant='body2' component='h3' className={styles.subtitle}>
        If you need to change your payment information go to the{' '}
        <Link component={RouterLink} to={AppRoute.Billing} underline='hover'>
          billing page
        </Link>
        .
      </Typography>

      <PlanDetails purchaseData={purchaseData} billingData={billingData} />

      <LinkButton
        href={buildRoute(AppRoute.SubscriptionOverview, { subscriptionId: purchaseData.subscriptionId })}
        className={styles.button}
      >
        Done
      </LinkButton>
    </div>
  )
}

export interface PlanDetailsProps {
  purchaseData: PurchaseData
  billingData?: BillingData
}

function PlanDetails({ purchaseData, billingData }: PlanDetailsProps) {
  const isPaid = [PurchaseType.PaidSubscription, PurchaseType.SubscriptionUpgrade, PurchaseType.Trial].includes(
    purchaseData.type
  )

  let planDescription: string | undefined
  let purchaseNotes: string[] | undefined

  if (billingData) {
    const { description, notes } = getPurchaseDetails(purchaseData.type, billingData, purchaseData.tier)

    planDescription = description
    purchaseNotes = notes
  }

  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Paper className={styles.planDetails}>
      <Typography variant='semiBody1' component='h2'>
        Plan details
      </Typography>

      <Table className={styles.table}>
        <TableBody>
          <TableRow>
            <TableCell>Plan</TableCell>
            <TableCell>{planDescription ? planDescription : <Skeleton />}</TableCell>
          </TableRow>

          {isPaid && purchaseData.paidWith && (
            <TableRow>
              <TableCell>Payment Method</TableCell>
              <TableCell>
                <CardLogo cardBrand={purchaseData.paidWith.cardBrand} className={styles.cardLogo} />
                <CardDescription paymentMethod={purchaseData.paidWith} compact={lgDown} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Divider />

      <div className={styles.notes}>
        {purchaseNotes ? (
          purchaseNotes.map((note) => (
            <Typography variant='body2' key={note} className={styles.note}>
              {note}
            </Typography>
          ))
        ) : (
          <Skeleton />
        )}
      </div>
    </Paper>
  )
}

function getTitle(purchaseType: PurchaseType) {
  switch (purchaseType) {
    case PurchaseType.PaidSubscription:
    case PurchaseType.FreeSubscription:
      return 'Subscription has been created!'
    case PurchaseType.SubscriptionUpgrade:
      return 'Your plan has been updated to On-Demand!'
    case PurchaseType.Trial:
      return `You started ${DEFAULT_TRIAL_DAYS} days free trial!`
  }
}

function getPurchaseDetails(purchaseType: PurchaseType, price: BillingData, tier?: SubscriptionTier) {
  // Overage price is in cents per API call. Convert to dollars per 1,000 API calls ( overagePrice * 1000 / 100).
  const monthlyOverage = `${formatMoney(price.overagePrice * 10)}/month per 1,000 additional ${price.unitLabel}s`
  const productName = tier ? SUBSCRIPTION_DISPLAY_PLANS[SUBSCRIPTION_TIER_TO_DISPLAY_PLAN[tier]].name : PRODUCT_NAME

  switch (purchaseType) {
    case PurchaseType.SubscriptionUpgrade:
    case PurchaseType.PaidSubscription:
      return {
        description: `${productName}: On-Demand Plan. Included: ${formatNum(
          price.prepaidQuantity
        )} API Calls. ${monthlyOverage}*`,
        notes: [
          `*When you exceed your limit of ${formatNum(
            price.prepaidQuantity
          )} API calls/per month, you will incur additional charges of  ${monthlyOverage}.`,
          `Your subscription will automatically renew every month. You will be charged ${formatMoney(
            price.flatAmount / 100
          )} plus any additional visitor charges until you cancel in your account settings.`,
        ],
      }
    case PurchaseType.FreeSubscription:
      return {
        description: `${productName}: Free Plan. Included: ${formatNum(price.prepaidQuantity)} ${
          price.unitLabel
        }s / month*`,
        notes: [
          `*When you exceed your limit of ${formatNum(price.prepaidQuantity)} ${
            price.unitLabel
          }s per month, your subscription will be restricted until the end of your billing period.`,
        ],
      }
    case PurchaseType.Trial:
      return {
        description: `${productName}: Free Trial. Included: unlimited ${price.unitLabel}s / ${DEFAULT_TRIAL_DAYS} days*`,
        notes: [`*After ${DEFAULT_TRIAL_DAYS} days, your subscription will be converted to the On-Demand plan.`],
      }
  }
}
