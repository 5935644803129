import { Button } from '@compass/components'
import { ErrorOutline } from '@mui/icons-material'
import { Alert, Paper, Tooltip, Typography } from '@mui/material'
import { accountSettingsNavItems, Header, Layout, MainContent, TabNavigation } from 'features/commonUI'
import { useState } from 'react'

import VerifyOtpDialog from '../../components/VerifyOtpDialog/VerifyOtpDialog'
import { useAuth, useDocumentTitle, usePaymentMethods, useSubscriptions } from '../../hooks'
import { useDeleteAccount } from '../../hooks/api/account'
import { useUserContext } from '../../hooks/api/context'
import { useSendOneTimePassword } from '../../hooks/api/email'
import { useUsers } from '../../hooks/api/users'
import { ExpandedSubscription, SubscriptionType, UserRole } from '../../models'
import styles from './AccountDeletePage.module.scss'
import { OtpConfirmationDialog } from './components/OtpConfirmationDialog'

export function AccountDeletePage() {
  useDocumentTitle('Delete Account')
  const {
    mutate: sendOneTimePassword,
    isLoading: isSendingOneTimePassword,
    error: oneTimePasswordError,
  } = useSendOneTimePassword()
  const { accessTokenPayload } = useAuth()
  const currentRole = accessTokenPayload?.role
  const { mutate: deleteAccount, isLoading: isDeletingAccount } = useDeleteAccount()
  const { data: context } = useUserContext()
  const { data: users } = useUsers()
  const { data: subscriptions } = useSubscriptions()
  const { data: paymentMethods } = usePaymentMethods()
  const [isOtpSendDialogOpen, setIsOtpSendDialogOpen] = useState(false)
  const [isOtpConfirmationDialogOpen, setIsOtpConfirmationDialogOpen] = useState(false)

  const onOpenConfirm = () => {
    setIsOtpConfirmationDialogOpen(true)
  }

  function onDeleteAccount() {
    setIsOtpConfirmationDialogOpen(false)
    sendOneTimePassword(
      { data: { oneTimePasswordFlow: 'account_deletion' } },
      {
        onSuccess: () => {
          setIsOtpSendDialogOpen(true)
        },
      }
    )
  }

  function onConfirmDeleteAccount(otp: string) {
    deleteAccount({ data: { password: otp } }, {})
  }

  const handleClose = () => {
    setIsOtpSendDialogOpen(false)
    setIsOtpConfirmationDialogOpen(false)
  }

  const hasPaidAccount = (subscriptions: ExpandedSubscription[] | undefined) => {
    return (
      subscriptions &&
      subscriptions.some(
        (subscription) => subscription.type === SubscriptionType.Prepaid || subscription.type === SubscriptionType.Paid
      )
    )
  }

  const canDeleteAccount = () => {
    return currentRole === UserRole.Owner || currentRole === UserRole.Admin || currentRole === UserRole.Administrator
  }

  const errorMessage = () => {
    if (!canDeleteAccount()) {
      return (
        <>
          Heads up, account deletion is restricted for account owners and admins. Please,{' '}
          <a href='https://fingerprint.com/support/' target='_blank' rel='noreferrer'>
            contact support{' '}
          </a>
          if you wish to delete your account.
        </>
      )
    } else if (users && users.length > 1) {
      return (
        <>
          Heads up, account deletion is restricted for accounts with team members. Please remove existing team members
          or{' '}
          <a href='https://fingerprint.com/support/' target='_blank' rel='noreferrer'>
            contact support
          </a>{' '}
          if you wish to delete your account.
        </>
      )
    } else if (hasPaidAccount(subscriptions)) {
      return (
        <>
          Heads up, account deletion is restricted for accounts with a history of payments. Please,{' '}
          <a href='https://fingerprint.com/support/' target='_blank' rel='noreferrer'>
            contact support
          </a>{' '}
          if you wish to delete your account
        </>
      )
    } else if (paymentMethods && paymentMethods.length > 0) {
      return (
        <>
          Heads up, accounts with payment methods cannot be deleted. Please,{' '}
          <a href='https://fingerprint.com/support/' target='_blank' rel='noreferrer'>
            contact support
          </a>{' '}
          if you wish to delete your account
        </>
      )
    }

    return null
  }

  return (
    <Layout>
      <Header title='Account Settings' />
      <TabNavigation items={accountSettingsNavItems} />
      <MainContent>
        {errorMessage() && (
          <Alert severity='warning' icon={<ErrorOutline />} className={styles.alert}>
            {' '}
            {errorMessage()}{' '}
          </Alert>
        )}
        <Paper className={styles.card}>
          <div className={styles.headingContainer}>
            <div>
              <Typography variant='h3' component='h2' color='danger' className={styles.deleteText}>
                Delete Account
              </Typography>
              <Typography variant='bodyM'>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Permanently delete your team's Fingerprint account. This cannot be undone, so please be certain.
              </Typography>
            </div>
          </div>

          {errorMessage() ? (
            <Tooltip
              title='Please contact support if you wish to delete your account'
              classes={{ tooltip: styles.errorToolTip }}
              placement='top'
              arrow
            >
              <div className={styles.disabledButtonDiv}>
                <Button variant='destructive' isDisabled={true}>
                  Permanently delete account
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button variant='destructive' onPress={onOpenConfirm}>
              Permanently delete account
            </Button>
          )}

          <OtpConfirmationDialog
            calloutBox={{
              title: 'Proceed with caution!',
              message: (
                <div>
                  Deleting your account is a permanent action and <strong> cannot be undone</strong>. We will
                  temporarily store your information in our backup systems for 30 days and then the backups will be
                  deleted. If you need to preserve events data, we recommend exporting.{' '}
                </div>
              ),
            }}
            extraInfo='Click “Continue” and we’ll send you a one-time password to confirm deletion.'
            confirmText='Continue'
            cancelText='Keep account'
            title='Delete account'
            onConfirm={onDeleteAccount}
            onClose={handleClose}
            open={isOtpConfirmationDialogOpen}
          />

          {context && (
            <VerifyOtpDialog
              context={context}
              onVerifyOtp={(otp) => onConfirmDeleteAccount(otp)}
              onSendOtp={onDeleteAccount}
              onClose={handleClose}
              isLoading={isSendingOneTimePassword || isDeletingAccount}
              error={oneTimePasswordError}
              open={isOtpSendDialogOpen}
            />
          )}
        </Paper>
      </MainContent>
    </Layout>
  )
}
