import { Button, ButtonProps } from '@compass/components'
import { MoreHoriz } from '@mui/icons-material'
import { Menu, MenuItem, Typography } from '@mui/material'
import { useRef, useState } from 'react'

import styles from './UserManagementActionMenu.module.scss'

export interface UserManagementActionMenuProps {
  isPendingTransfer?: boolean
  onTransfer: () => void
  onCancelTransfer: () => void
  className?: string
  variant?: ButtonProps['variant']
}

export function UserManagementActionMenu({
  isPendingTransfer,
  onTransfer,
  onCancelTransfer,
  className,
  variant = 'ghost',
}: UserManagementActionMenuProps) {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [menuOpened, setMenuOpened] = useState(false)

  const openMenu = () => {
    setMenuOpened(true)
  }

  const closeMenu = () => {
    setMenuOpened(false)
  }

  return (
    <>
      <Button
        variant={variant}
        isIcon
        aria-label='More actions'
        onPress={openMenu}
        id='more-actions-button'
        className={className}
        ref={anchorRef}
      >
        <MoreHoriz fontSize='16' />
      </Button>

      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorRef.current && menuOpened}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'more-actions-button',
        }}
        classes={{ list: styles.menuList }}
      >
        {isPendingTransfer && (
          <MenuItem
            onClick={() => {
              onCancelTransfer()
              closeMenu()
            }}
            classes={{ root: styles.menuItem }}
          >
            <Typography variant='bodyMMedium'>Cancel ownership transfer</Typography>
          </MenuItem>
        )}

        {!isPendingTransfer && (
          <MenuItem
            onClick={() => {
              onTransfer()
              closeMenu()
            }}
            classes={{ root: styles.menuItem }}
          >
            <Typography variant='bodyMMedium'>Transfer ownership</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
