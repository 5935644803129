import { Button, LinkButton } from '@compass/components'
import { Box, Chip, Link, Paper, PaperProps, Stack, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { SubscriptionDisplayPlan, SUPPORT_PAGE_URL } from 'const'
import { humanizeTimeToDate, labelToUnitMap } from 'helpers/date'
import { getSubscriptionDisplayPlan } from 'helpers/subscription'
import { Subscription, SubscriptionStatus, SubscriptionType } from 'models'
import { PropsWithChildren } from 'react'

import { useSubscriptionTrialSelfExtend, useToast } from '../../../../hooks'
import { formatDate } from '../../helpers/datetime'

const planToLabelMap = {
  [SubscriptionDisplayPlan.Pro]: 'Pro',
  [SubscriptionDisplayPlan.Plus]: 'Pro Plus',
  [SubscriptionDisplayPlan.Plus99]: 'Pro Plus',
  [SubscriptionDisplayPlan.Enterprise]: 'Enterprise',

  // Do not display anything for free plans
  [SubscriptionDisplayPlan.Free]: undefined,
}

export type Props = {
  subscription: Subscription
} & PaperProps

export function SidebarPlanBanner({ subscription, ...paperProps }: Props) {
  const { type, status, currentPeriodEndsAt, cancelAt } = subscription
  const { mutate: extendTrial, isLoading: extendTrialLoading } = useSubscriptionTrialSelfExtend()
  const { showToast } = useToast()

  const isCanceled = status === SubscriptionStatus.Canceled
  const willBeCanceled = !isCanceled && !!cancelAt
  const isEndedTrial = type === SubscriptionType.TrialOnly && isCanceled
  const isTrialing = type === SubscriptionType.TrialOnly && !isEndedTrial
  const isPoc = status === SubscriptionStatus.ProofOfConcept || type === SubscriptionType.ProofOfConcept
  const isEndedPoC = isPoc && currentPeriodEndsAt && new Date(currentPeriodEndsAt) < new Date()
  const isActivePoC = isPoc && !isEndedPoC

  const planLabel = planToLabelMap[getSubscriptionDisplayPlan(subscription)]

  if (isEndedPoC) {
    return (
      <SidebarPlanBannerWrapper {...paperProps}>
        <Typography variant='h3'>Proof of concept concluded</Typography>
        <Typography variant='bodyM' component='div' mt={1}>
          Your trial of Fingerprint Enterprise ended on {formatDate(currentPeriodEndsAt ?? new Date(), 'standard')}.
          Please reach out to your sales representative.
        </Typography>
      </SidebarPlanBannerWrapper>
    )
  }

  if (isActivePoC) {
    return (
      <SidebarPlanBannerWrapper {...paperProps}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='h3'>Proof of concept</Typography>
          <Chip label={humanizeTimeToDate(currentPeriodEndsAt)} size='small' color='blueSubtle' />
        </Stack>

        <Typography variant='bodyM' component='div' mt={1}>
          You are trialing Fingerprint Enterprise.
        </Typography>
      </SidebarPlanBannerWrapper>
    )
  }

  if (isEndedTrial) {
    return (
      <SidebarPlanBannerWrapper {...paperProps}>
        <Typography variant='h3'>Trial ended</Typography>

        <Typography variant='bodyM' component='div' my={1}>
          Your free trial of Fingerprint {planLabel ?? 'Pro Plus'} has ended.
        </Typography>

        <LinkButton
          href={buildRoute(AppRoute.SubscriptionPlan, { subscriptionId: subscription.id })}
          fullWidth
          size='lg'
        >
          Upgrade now
        </LinkButton>
        {!subscription.extendedTrialStartedAt && shouldShowExtensionButton(subscription.trialEndAt) && (
          <Box mt='8px'>
            <Button
              isDisabled={extendTrialLoading}
              onPress={() => {
                extendTrial(
                  { params: { subscriptionId: subscription.id } },
                  {
                    onError: () => {
                      showToast({
                        severity: 'error',
                        message: 'An error occured when extending your free trial. Please try again.',
                      })
                    },
                  }
                )
              }}
              fullWidth
              variant='outline'
              size='lg'
            >
              Extend trial - free
            </Button>
          </Box>
        )}
      </SidebarPlanBannerWrapper>
    )
  }

  if (isTrialing) {
    return (
      <SidebarPlanBannerWrapper {...paperProps}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='h3'>Trial</Typography>
          <Chip
            label={humanizeTimeToDate(subscription.trialEndAt)}
            size='small'
            color={shouldShowExtensionButton(subscription.trialEndAt) ? 'yellowSubtle' : 'blueSubtle'}
          />
        </Stack>

        <Typography variant='bodyM' component='div' my={1}>
          You are trialing Fingerprint {planLabel ?? 'Pro Plus'}. Upgrade now to avoid interruption.
        </Typography>

        <LinkButton
          href={buildRoute(AppRoute.SubscriptionPlan, { subscriptionId: subscription.id })}
          fullWidth
          size='lg'
        >
          Upgrade now
        </LinkButton>

        {!subscription.extendedTrialStartedAt && shouldShowExtensionButton(subscription.trialEndAt) && (
          <Box mt='8px'>
            <Button
              isDisabled={extendTrialLoading}
              onPress={() => {
                extendTrial(
                  { params: { subscriptionId: subscription.id } },
                  {
                    onError: () => {
                      showToast({
                        severity: 'error',
                        message: 'An error occured when extending your free trial. Please try again.',
                      })
                    },
                  }
                )
              }}
              fullWidth
              variant='outline'
              size='lg'
            >
              Extend trial &ndash; free
            </Button>
          </Box>
        )}
      </SidebarPlanBannerWrapper>
    )
  }

  // Probably a new or legacy plan so we don't want to show anything
  if (!planLabel) {
    return null
  }

  if (willBeCanceled) {
    return (
      <SidebarPlanBannerWrapper {...paperProps}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='h3'>{planLabel}</Typography>
          <Chip label={`Cancels ${formatDate(cancelAt, 'short')}`} size='small' color='orangeSubtle' />
        </Stack>

        <Typography variant='bodyM' component='div' mt={1}>
          Your subscription will cancel on {formatDate(cancelAt)}. You can still access it until then.
        </Typography>
      </SidebarPlanBannerWrapper>
    )
  }

  if (isCanceled) {
    return (
      <SidebarPlanBannerWrapper {...paperProps}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='h3'>{planLabel}</Typography>
          <Chip label='Canceled' size='small' color='orangeSubtle' />
        </Stack>

        <Typography variant='bodyM' component='div' mt={1}>
          Your subscription has been canceled. If you want to resume it,{' '}
          <Link target='_blank' color='inherit' href={SUPPORT_PAGE_URL}>
            contact support
          </Link>
          .
        </Typography>
      </SidebarPlanBannerWrapper>
    )
  }

  // Regular active plan
  return (
    <SidebarPlanBannerWrapper {...paperProps}>
      <Typography variant='bodyM'>
        Your application is utilizing
        <br />
        <Typography variant='bodyMMedium'>Fingerprint {planLabel}</Typography>
      </Typography>
    </SidebarPlanBannerWrapper>
  )
}

function SidebarPlanBannerWrapper({ children, sx, ...paperProps }: PropsWithChildren<PaperProps>) {
  return (
    <Paper variant='outlined' sx={{ borderColor: '#F2F2F2', my: 4, ...sx }} {...paperProps}>
      <Box padding={1}>{children}</Box>
    </Paper>
  )
}

function shouldShowExtensionButton(date: string | Date | undefined) {
  if (!date) {
    return false
  }
  const difference = new Date(date).getTime() - Date.now()
  return difference < 3 * labelToUnitMap.day
}
