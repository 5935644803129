import { InfoOutlined } from '@mui/icons-material'
import { Alert, Link } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { interpretBotDDateRange } from 'features/commonUI'
import { ChartDateRangePicker } from 'features/subscriptionOverview'
import { BotdCounterType, UsageCounterDataPoint, UsageCounterPeriod, UsageCounterType } from 'models'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import BotdFilter, { BotdFilterProps } from '../../../../../components/Filter/Botd/BotdFilter'
import Loader from '../../../../../components/Loader/Loader'
import { filterObjectByKeys } from '../../../../../helpers/common'
import { DateRange } from '../../../../../helpers/dateRange'
import { BotdVisitFilter } from '../../../../../hooks/botdVisits'
import BotdChart from '../../BotdChart/BotdChart'
import LabeledCounter from '../LabeledCounter/LabeledCounter'
import styles from './HistoryWidget.module.scss'

export interface HistoryWidgetProps {
  counters?: ChartData
  granularity: UsageCounterPeriod
  timezone?: string
  dateRange?: DateRange
  onApplyRange: (range: DateRange) => void
  appliedFilter?: BotdVisitFilter
  onFilter: BotdFilterProps['onFilter']
  subscriptionCreatedAt: Date
  subscriptionId: string
  today: Date
  isLoadingCounters?: boolean
}

export function HistoryWidget({
  counters,
  dateRange,
  onApplyRange,
  appliedFilter,
  onFilter,
  granularity,
  timezone,
  today,
  subscriptionCreatedAt,
  subscriptionId,
  isLoadingCounters,
}: HistoryWidgetProps) {
  const totalSumByCounter = countersToDisplay.reduce(
    (acc, { type }) => ({
      ...acc,
      [type]: counters?.[type]?.reduce((acc, { value }) => acc + value, 0) ?? 0,
    }),
    {} as Record<CounterTypesToDisplay, number>
  )

  const [activeCounterTypes, setActiveCounterTypes] = useState(countersToDisplay.map(({ type }) => type))

  function toggleCounter(counter: CounterTypesToDisplay) {
    if (activeCounterTypes.includes(counter)) {
      setActiveCounterTypes((activeCounters) => activeCounters.filter((existingCounter) => existingCounter !== counter))
    } else {
      setActiveCounterTypes((activeCounters) => [...activeCounters, counter])
    }
  }

  const hasVisibleUsage = activeCounterTypes.some(
    (counterType) => counterType !== UsageCounterType.ApiCalls && totalSumByCounter?.[counterType]
  )

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <div className={styles.counters}>
          {countersToDisplay.map(({ type, label, color, canHide }) => (
            <LabeledCounter
              key={type}
              disabled={canHide && !activeCounterTypes.includes(type)}
              onToggle={canHide ? () => toggleCounter(type) : undefined}
              label={label}
              value={totalSumByCounter[type]}
              total={totalSumByCounter[UsageCounterType.ApiCalls]}
              color={color}
            />
          ))}
        </div>

        <div className={styles.chartControls}>
          <BotdFilter onFilter={onFilter} appliedFilter={appliedFilter} className={styles.control} />

          {counters && (
            <ChartDateRangePicker
              onApplyRange={onApplyRange}
              initialDateRange={dateRange}
              initialMinDate={subscriptionCreatedAt}
              initialMaxDate={today}
              today={today}
              className={styles.control}
              timezone={timezone}
              rangeInterpreter={(dateRange: Required<DateRange>) => interpretBotDDateRange(dateRange, timezone)}
            />
          )}
        </div>
      </header>

      {counters && dateRange && (
        <section className={styles.chart}>
          {hasVisibleUsage ? (
            <BotdChart
              counters={filterObjectByKeys(counters, (counter) => activeCounterTypes.includes(counter))}
              granularity={granularity}
              timezone={timezone}
            />
          ) : (
            <Alert severity='info' icon={<InfoOutlined />}>
              No bot detection API calls for the current filters.{' '}
              <Link
                component={RouterLink}
                to={buildRoute(AppRoute.Integrations, { subscriptionId: subscriptionId })}
                underline='always'
                color='inherit'
              >
                Install Fingerprint now
              </Link>
              .
            </Alert>
          )}
        </section>
      )}

      {isLoadingCounters && <Loader testId='botd-history-loader' />}
    </div>
  )
}

type CounterTypesToDisplay =
  | BotdCounterType.BadBots
  | BotdCounterType.GoodBots
  | BotdCounterType.Humans
  | UsageCounterType.ApiCalls

type ChartData = Partial<Record<CounterTypesToDisplay, UsageCounterDataPoint[]>>

interface CounterDescriptor {
  type: CounterTypesToDisplay
  label: string
  color?: 'red' | 'green' | 'gray'
  canHide?: boolean
}

const countersToDisplay: CounterDescriptor[] = [
  { type: BotdCounterType.BadBots, label: 'Malicious', color: 'red', canHide: true },
  { type: BotdCounterType.GoodBots, label: 'Friendly', color: 'green', canHide: true },
  { type: BotdCounterType.Humans, label: 'Human', color: 'gray', canHide: true },
  { type: UsageCounterType.ApiCalls, label: 'Total', canHide: false },
]
