import { isFreeSubscription } from 'helpers/subscription'
import { useToast } from 'hooks'
import { ExpandedSubscription, SSLCertificate, SubscriptionStatus } from 'models'
import { useEffect, useMemo } from 'react'

interface UseCertificatesListProps {
  isLoading?: boolean
  errorMessage?: string
  certificates: SSLCertificate[]
  certificatesLimit: number
  subscription?: ExpandedSubscription
  onCertificateAddClick: () => void
}

export function useCertificatesList({
  isLoading,
  errorMessage,
  certificates,
  certificatesLimit,
  subscription,
  onCertificateAddClick,
}: UseCertificatesListProps) {
  const { showToast } = useToast()

  useEffect(() => {
    if (errorMessage) {
      showToast({ message: errorMessage })
    }
  }, [errorMessage, showToast])

  const certificateData = useMemo(
    () => ({
      name: 'subdomain',
      count: certificates.length,
      limit: certificatesLimit,
      onAddClick: onCertificateAddClick,
    }),
    [certificates.length, certificatesLimit, onCertificateAddClick]
  )

  const isCanceled = subscription?.status === SubscriptionStatus.Canceled
  const disabledMessage = isCanceled
    ? 'You cannot add a subdomain to a canceled subscription'
    : isLoading
    ? 'Loading...'
    : undefined
  const showFreeLimitAlert = !isLoading && isFreeSubscription(subscription) && !isCanceled
  const showRequestMoreCertificatesCTA = !isLoading && certificateData.count >= certificateData.limit && !isCanceled

  return {
    showToast,
    certificateData,
    isCanceled,
    disabledMessage,
    showFreeLimitAlert,
    showRequestMoreCertificatesCTA,
  }
}
