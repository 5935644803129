import { AppRoute, buildRoute } from 'appRoutes'
import { useCurrentSubscription } from 'features/subscription'
import { useSubscription } from 'hooks'
import { useQueryParams } from 'hooks/queryParams'
import { Limits } from 'models'
import { ampli } from 'models/ampli'
import { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useEntityLimit } from '../../../hooks/api/context'
import { useCertificates, useDeleteCertificateMutation } from '../../../hooks/api/ssl_certificates'
import CertificatesList from '../components/CertificatesList/CertificatesList'

export const CustomSubdomainListPage = memo(function () {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { errorMessage } = useQueryParams()
  const { isLoading, data: certificates = [] } = useCertificates(subscriptionId)

  const history = useHistory()
  const certificatesLimit = useEntityLimit(subscriptionId, Limits.SslCertificates)
  const { data: subscription, isLoading: isSubscriptionLoading } = useSubscription(subscriptionId)

  const canCreateNew = !isLoading && certificates.length < certificatesLimit

  const { mutate: deleteCertificate, isLoading: isDeleteLoading } = useDeleteCertificateMutation()

  const handleCertDelete = useCallback(
    (certId: string) => {
      ampli.subdomainDeleted()
      deleteCertificate({ data: undefined, params: { id: certId, subscriptionId } })
    },
    [deleteCertificate, subscriptionId]
  )

  const handleCertificateAddClick = useCallback(() => {
    ampli.subdomainAddingStarted()
    history.push(buildRoute(AppRoute.CustomSubdomainNew, { subscriptionId }))
  }, [history, subscriptionId])

  return (
    <CertificatesList
      certificates={certificates}
      isLoading={isLoading || isSubscriptionLoading || isDeleteLoading}
      errorMessage={errorMessage}
      canCreateNew={canCreateNew}
      certificatesLimit={certificatesLimit}
      subscription={subscription}
      onCertificateDelete={handleCertDelete}
      onCertificateAddClick={handleCertificateAddClick}
      subscriptionId={subscriptionId}
    />
  )
})
