import { LinkButton } from '@compass/components'
import { Grid, Link, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import Loader from 'components/Loader/Loader'
import { appSettingNavItems, Header, MainGrid, TabNavigation } from 'features/commonUI'
import { useCurrentSubscription, useCurrentSubscriptionData } from 'features/subscription'
import { RouterPath } from 'helpers/types'
import { useAccountPreferences, useDocumentTitle, usePermissions } from 'hooks'
import { AccountBannerName } from 'models'
import { ampli } from 'models/ampli'
import { useCallback } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import { PRODUCTBOARD_PUBLIC_ROADMAP_SUBMISSION_URL } from '../../../../const/urls'
import IntegrationList from '../../components/IntegrationList/IntegrationList'
import { useIntegrationsMetadata } from '../../integrationHooks'
import styles from './IntegrationList.module.scss'

export function IntegrationListPage() {
  useDocumentTitle('Integrations')

  const history = useHistory()
  const {
    userPermissions: { canCreate: canCreateUser },
  } = usePermissions()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { subscription } = useCurrentSubscriptionData()
  const { integrationsMetadata } = useIntegrationsMetadata(currentSubscriptionId)
  const { getBannerState: getAccountBannerState, handleCloseBanner: handleCloseAccountBanner } = useAccountPreferences()
  const { isHidden: isBannerClosed } = getAccountBannerState(AccountBannerName.InviteUser)

  const handleOnInviteClickAction = useCallback(
    (handleClose: () => void) => {
      handleClose()
      ampli.inviteUserClicked({ 'App Page Path': AppRoute.Integrations })
      history.push(AppRoute.UserManagement, { isUserDialogOpen: true })
    },
    [history]
  )

  return (
    <>
      <Header title='App Settings' />
      <TabNavigation items={appSettingNavItems} />
      <MainGrid>
        {!subscription ? (
          <Loader />
        ) : (
          <>
            <Grid item xs={12}>
              <header className={styles.header}>
                <span className={styles.title}>
                  <Typography variant='h2'>Integrations</Typography>
                  <Typography variant='bodyM'>
                    Enhance Fingerprint reliability and availability with seamless integrations to your favorite
                    platforms.
                  </Typography>
                </span>
                <LinkButton
                  variant='secondary'
                  className={styles.button}
                  href={PRODUCTBOARD_PUBLIC_ROADMAP_SUBMISSION_URL}
                  target='_blank'
                  rel='noreferrer'
                >
                  Request integration
                </LinkButton>
              </header>
            </Grid>
            {canCreateUser && !isBannerClosed && (
              <Grid item xs={12}>
                <DashboardBanner
                  title='Invite a teammate'
                  action='Add user'
                  onClickAction={() =>
                    handleOnInviteClickAction(() => handleCloseAccountBanner(AccountBannerName.InviteUser))
                  }
                  dismissTitle='Invite a teammate'
                  dismissBody={
                    <Typography variant='body1'>
                      You can invite a teammate later in{' '}
                      <Link component={RouterLink} to={addUserPath} underline='hover'>
                        user management settings
                      </Link>
                      .
                    </Typography>
                  }
                  secondaryAction='Not now'
                  secondaryActionOnClose={() => handleCloseAccountBanner(AccountBannerName.InviteUser)}
                >
                  Invite somebody who can help you set up your application.
                </DashboardBanner>
              </Grid>
            )}
            <Grid item xs={12}>
              <IntegrationList subscription={subscription} integrations={integrationsMetadata} />
            </Grid>
          </>
        )}
      </MainGrid>
    </>
  )
}

const addUserPath: RouterPath = {
  pathname: AppRoute.UserManagement,
  state: {
    isUserDialogOpen: true,
  },
}
