import { SearchOutlined, SentimentNeutralOutlined } from '@mui/icons-material'
import { Alert, Paper, Skeleton, TableHead, Typography } from '@mui/material'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import IpLocation from 'components/IpLocation/IpLocation'
import Loader from 'components/Loader/Loader'
import {
  ColumnDescriptor,
  Table,
  TableBody,
  TableBodyData,
  TableBodyEmpty,
  TableBodyError,
  TableBodyLoading,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TablePagination,
  TableRow,
} from 'components/Table/Table'
import { GenericError } from 'const'
import { parseISO } from 'date-fns'
import { formatDatetime, GenericEmptyState } from 'features/commonUI'
import { useCurrentSubscription, useCurrentSubscriptionData } from 'features/subscription'
import { RouterPath } from 'helpers/types'
import { usePaginationForQuery } from 'hooks/usePaginationForQuery'
import { TOTAL_HITS_MAX_LIMIT } from 'hooks/visits'
import { PaginatedVisitsResponse, Visit } from 'models'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { IntegrationStep } from '../../../../models/integrationStatus'
import styles from './VisitsTable.module.scss'

export interface VisitsTableProps {
  query: UseInfiniteQueryResult<PaginatedVisitsResponse, GenericError>
  title?: string
  emptyAlert?: string
  columns?: ColumnDescriptor<Visit>[]
  selectedRequestId?: string
  withContainer?: boolean
  error?: GenericError | null
  getVisitPath?: (visit: Visit) => RouterPath
  className?: string
  actions?: ReactNode
  isFiltering?: boolean
}

export function VisitsTable({
  title,
  query,
  columns = defaultColumns,
  selectedRequestId,
  withContainer,
  error,
  getVisitPath,
  className,
  actions,
  isFiltering = false,
}: VisitsTableProps) {
  const columnCount = columns?.length ?? defaultColumns.length
  const columnWidth = `${100 / columnCount}%`

  const { data, isFetching } = query
  const {
    values: visits,
    currentPageNumber,
    hasPreviousEntries,
    goToPreviousPage,
    hasNextEntries,
    goToNextPage,
  } = usePaginationForQuery(query, (page) => page.visits)

  const pages = data?.pages

  const shownItemsStart = pages ? (pages[0]?.visits.length ?? 0) * currentPageNumber + 1 : undefined
  const shownItemsEnd =
    shownItemsStart !== undefined && visits !== undefined ? shownItemsStart + visits.length - 1 : undefined
  const totalHitsIsNotExact = pages?.[0]?.totalHits == null || pages?.[0]?.totalHits === TOTAL_HITS_MAX_LIMIT
  const lastPageIsFull = pages?.[0]?.visits.length === visits?.length
  const largestValueOnScreen = Math.max(pages?.[0]?.totalHits ?? 0, shownItemsEnd ?? 0)
  const totalItems =
    pages?.[0]?.totalHits === undefined
      ? undefined
      : `${largestValueOnScreen}${totalHitsIsNotExact && lastPageIsFull && hasNextEntries ? '+' : ''}`
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { subscription } = useCurrentSubscriptionData()
  const hasMadeRequests = subscription?.integrationStep !== IntegrationStep.ApiCalls

  return (
    <>
      <TableContainer component={withContainer ? Paper : undefined} className={clsx(styles.root, className)}>
        <TableHeader title={title ?? 'Visits'} actions={actions} />

        <Table style={{ position: 'relative', minWidth: 'unset' }}>
          {query.isFetching ? <Loader className={styles.loader} testId='visits-table-loader' /> : null}
          <TableHead>
            <TableRow>
              {visits !== undefined && visits.length === 0
                ? null
                : columns.map(({ label, align }) => (
                    <TableCell key={label} width={columnWidth} align={align ?? 'left'}>
                      {label}
                    </TableCell>
                  ))}
            </TableRow>
          </TableHead>

          <TableBody
            columnCount={columnCount}
            isLoading={isFetching}
            showBodyLoader={visits === undefined}
            error={error}
          >
            <TableBodyLoading>
              <div style={{ minHeight: 688, display: 'flex', flexDirection: 'column', gap: 36 }}>
                {new Array(10).fill('').map((_, index) => (
                  <Skeleton key={index} animation={false} />
                ))}
              </div>
            </TableBodyLoading>
            <TableBodyData>
              {visits?.map((visit) => {
                const isSelected = selectedRequestId === visit.requestId
                const isInteractive = !!getVisitPath && !isSelected

                let rowProps = {}
                if (isInteractive) {
                  rowProps = { component: RouterLink, to: getVisitPath(visit) }
                }

                return (
                  <TableRow
                    {...rowProps}
                    key={visit.requestId}
                    className={clsx({ [styles.selected]: isSelected })}
                    interactive={isInteractive}
                  >
                    {columns.map(({ key, monospace, align }) => {
                      return (
                        <TableCell key={key} align={align ?? 'left'} monospace={monospace}>
                          <>{preprocess(key, visit)}</>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBodyData>

            <TableBodyEmpty>
              {isFiltering || hasMadeRequests ? (
                <GenericEmptyState
                  icon={<SearchOutlined />}
                  title='No results were found'
                  description='Try expanding the date range, adjusting any filters, or perhaps take a coffee break.'
                />
              ) : (
                <GenericEmptyState
                  icon={<SentimentNeutralOutlined />}
                  title="There's nothing here"
                  description='Events will appear here once your app is sending requests.'
                  buttonProps={{
                    children: 'Install Fingerprint now',
                  }}
                  buttonTo={buildRoute(AppRoute.Integrations, { subscriptionId })}
                />
              )}
            </TableBodyEmpty>

            <TableBodyError>
              <Alert severity='error'>{error?.message ?? 'There was an error getting the visits.'}</Alert>
            </TableBodyError>
          </TableBody>
        </Table>

        <TableFooter className={styles.footer}>
          {shownItemsEnd ? (
            <Typography variant='bodyM'>
              {totalItems === undefined ? (
                <>
                  Showing events {shownItemsStart}-{shownItemsEnd}
                </>
              ) : (
                <>
                  Showing {shownItemsStart}-{shownItemsEnd} of {totalItems} events
                </>
              )}
            </Typography>
          ) : null}
          {!error && (
            <TablePagination
              hasNextEntries={hasNextEntries}
              hasPreviousEntries={hasPreviousEntries}
              onRequestNextEntries={goToNextPage}
              onRequestPreviousEntries={goToPreviousPage}
              isLoading={isFetching}
              className={styles.pagination}
            />
          )}
        </TableFooter>
      </TableContainer>
    </>
  )
}

const defaultColumns: ColumnDescriptor<Visit>[] = [
  { key: 'visitorId', label: 'Visitor ID', monospace: true },
  { key: 'ip', label: 'IP Address' },
  { key: 'requestId', label: 'Request ID', monospace: true },
  { key: 'time', label: 'Date' },
]

const preprocess = (key: keyof Visit, visit?: Visit) => {
  switch (key) {
    case 'time': {
      const time = visit?.time
      return time ? formatDatetime(parseISO(time), 'precise') : ''
    }

    case 'ip': {
      const ipAddress = visit?.ip
      return ipAddress ? <IpLocation ipAddress={ipAddress} ipLocation={visit?.ipLocation} source='visits' /> : '-'
    }

    default:
      return visit?.[key]
  }
}
