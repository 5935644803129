import { Button } from '@compass/components'
import { DeleteOutline, Edit as EditIcon } from '@mui/icons-material'
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import Tag from 'components/Tag/Tag'
import { USER_ROLES } from 'const'
import { AccountTransferRequest, User } from 'models'

import { UserInvitedSince } from '../UserInvitedSince/UserInvitedSince'
import { ManagedUser, USER_ROLE_COLORS } from '../UserManagement/UserManagementData'
import { UserManagementActionMenu } from '../UserManagementActionMenu/UserManagementActionMenu'
import styles from './UsersTable.module.scss'

export interface UsersTableProps {
  currentUser?: User
  users?: ManagedUser[]
  onEditUser: (userId: string) => void
  onDeleteUser: (userId: string) => void

  pendingAccountTransfer?: AccountTransferRequest
  onTransferOwnership: (user: ManagedUser) => void
  onCancelOwnershipTransfer: (user: ManagedUser) => void
}

export function UsersTable({
  currentUser,
  users,
  onEditUser,
  onDeleteUser,

  pendingAccountTransfer,
  onTransferOwnership,
  onCancelOwnershipTransfer,
}: UsersTableProps) {
  return (
    <>
      <div className={styles.tableWrapper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell className={styles.header}>User</TableCell>
              <TableCell className={styles.header}>Since</TableCell>
              <TableCell className={styles.header}>Role</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!users ? (
              <RowSkeleton />
            ) : (
              <>
                {users.map((user) => {
                  const { id, name, role, email, isEditable, isDeletable, canReceiveOwnership, isPendingTransfer } =
                    user
                  const shouldShowExtraActions = canReceiveOwnership || isPendingTransfer

                  return (
                    <TableRow key={id}>
                      <TableCell>
                        <Typography variant='bodyMMedium' component='span'>
                          {name} {currentUser?.id === id && '(you)'}
                        </Typography>
                        <br />
                        <Typography variant='bodyM' component='span'>
                          {email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <UserInvitedSince pendingAccountTransfer={pendingAccountTransfer} user={user} />
                      </TableCell>
                      <TableCell>
                        <Tag bold label={USER_ROLES[role].name} color={USER_ROLE_COLORS[role]} />
                      </TableCell>

                      <TableCell className={styles.actions}>
                        {isEditable && (
                          <Button
                            variant='ghost'
                            isIcon
                            alt
                            aria-label='Edit user'
                            onPress={() => onEditUser(id)}
                            className={styles.actionButton}
                          >
                            <EditIcon fontSize='inherit' />
                          </Button>
                        )}

                        {isDeletable && (
                          <Button
                            variant='ghost'
                            isIcon
                            alt
                            aria-label='Delete user'
                            onPress={() => onDeleteUser(id)}
                            className={styles.actionButton}
                          >
                            <DeleteOutline fontSize='inherit' />
                          </Button>
                        )}

                        {shouldShowExtraActions && (
                          <UserManagementActionMenu
                            isPendingTransfer={isPendingTransfer}
                            onTransfer={() => onTransferOwnership(user)}
                            onCancelTransfer={() => onCancelOwnershipTransfer(user)}
                            className={styles.actionButton}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

function RowSkeleton() {
  return (
    <tr data-testid='user-management-skeleton'>
      {Array(3)
        .fill(undefined)
        .map((_, index) => (
          <td key={index}>
            <Skeleton height='32px' />
          </td>
        ))}
    </tr>
  )
}
