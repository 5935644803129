import { CircularProgress } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { useCurrentSubscription } from 'features/subscription'
import { ComponentProps, memo, useCallback } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { useAddCertificateMutation, useCertificate } from '../../../hooks/api/ssl_certificates'
import CertificateCreate from '../components/CertificateCreate/CertificateCreate'

export const CustomSubdomainCreatePage = memo(function () {
  const history = useHistory()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const certificateId = searchParams.get('certificate') || ''
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const {
    data: certificate,
    error: loadingError,
    isLoading,
  } = useCertificate(subscriptionId, certificateId, true, true)

  const { mutate: addCertificate, error: creationError, isLoading: isCreating } = useAddCertificateMutation()

  const handleCertificateCreate = useCallback<ComponentProps<typeof CertificateCreate>['onCertificateCreate']>(
    (data) => {
      addCertificate(
        {
          data: {
            domain: data.domain,
          },
          params: { subscriptionId },
        },
        {
          onSuccess: (cert) => {
            history.push(buildRoute(AppRoute.CustomSubdomainValidate, { subscriptionId }, { certificate: cert.id }))
          },
        }
      )
    },
    [addCertificate, subscriptionId, history]
  )

  if (!subscriptionId || (certificateId && isLoading)) {
    return <CircularProgress color='primary' />
  }

  if (loadingError?.message) {
    return (
      <Redirect
        to={buildRoute(AppRoute.CustomSubdomains, { subscriptionId }, { errorMessage: loadingError.message })}
      />
    )
  }

  if (creationError?.message) {
    return (
      <Redirect
        to={buildRoute(AppRoute.CustomSubdomains, { subscriptionId }, { errorMessage: creationError.message })}
      />
    )
  }

  return (
    <CertificateCreate
      certificate={certificate}
      onCertificateCreate={handleCertificateCreate}
      isCreating={isCreating}
    />
  )
})
