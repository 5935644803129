import { Button } from '@compass/components'
import { ShieldOutlined } from '@mui/icons-material'
import { Link, Typography } from '@mui/material'
import Loader from 'components/Loader/Loader'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { UserContext } from 'models'
import { useEffect, useState } from 'react'

import { GenericError } from '../../const'
import { EmailVerificationInput } from '../index'
import styles from './VerifyOtpDialog.module.scss'

const CODE_LENGTH = 6

enum SendState {
  Sent = 1,
  Sending,
  Cooldown,
  CanSend,
}

export interface VerifyOtpDialogProps extends DialogProps {
  context: UserContext
  onVerifyOtp: (otp: string) => void
  onSendOtp: (onSuccess?: () => void, onError?: () => void) => void
  isLoading?: boolean
  error?: GenericError | null
}

export default function VerifyOtpDialog({
  context,
  onVerifyOtp,
  onSendOtp,
  isLoading,
  error,
  onClose,
  ...props
}: VerifyOtpDialogProps) {
  const [cooldown, setCooldown] = useState(0)
  const [confirmationCooldown, setConfirmationCooldown] = useState(0)
  const [sendState, setSendState] = useState(SendState.CanSend)
  const [code, setCode] = useState('')

  useEffect(() => {
    switch (sendState) {
      case SendState.Cooldown:
        if (cooldown <= 0) {
          setSendState(SendState.CanSend)
        }
        break
      case SendState.Sent:
        if (confirmationCooldown <= 0) {
          setSendState(SendState.Cooldown)
        }
        break
    }
  }, [sendState, cooldown, confirmationCooldown])

  useEffect(() => {
    if (isLoading) {
      setSendState(SendState.Sending)
    } else {
      setSendState(SendState.Sent)
    }
  }, [isLoading])

  useEffect(() => {
    const handle = setInterval(() => {
      setCooldown((previous) => --previous)
      setConfirmationCooldown((previous) => --previous)
    }, 1000)

    return () => clearInterval(handle)
  }, [])

  return (
    <Dialog onClose={onClose} classes={{ paper: styles.dialog }} {...props}>
      {isLoading && <Loader testId='otp-dialog-loader' />}
      <DialogTitle className={styles.title}>
        <span className={styles.icon}>
          <ShieldOutlined color='primary' fontSize='medium' />
        </span>

        <Typography component='span' variant='h2'>
          Enter the 6-digit code we sent you
        </Typography>
        <Typography component='span' variant='bodyM'>
          It should be in your inbox.{' '}
          <SendAgain
            sendState={sendState}
            onSendAgain={() =>
              onSendOtp(
                () => {
                  setCooldown(60)
                  setConfirmationCooldown(2)

                  setSendState(SendState.Sent)
                },
                () => {
                  setSendState(SendState.CanSend)
                }
              )
            }
            sendCooldown={cooldown}
            sentTo={context.email}
          />
        </Typography>
      </DialogTitle>

      <DialogContent className={styles.content}>
        <EmailVerificationInput
          error={error}
          label='Verification code'
          digits={CODE_LENGTH}
          onChanged={(code) => setCode(code)}
          onSubmit={(code) => {
            if (code.length === CODE_LENGTH) {
              onVerifyOtp(code)
            }
          }}
        />
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant='ghost' onPress={onClose}>
          Cancel
        </Button>
        <Button isDisabled={code.length !== CODE_LENGTH || isLoading} onPress={() => onVerifyOtp(code)}>
          Verify
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface SendAgainProps {
  sendState: SendState
  sentTo?: string
  sendCooldown?: number
  onSendAgain: () => void
}

function SendAgain({ sendState, sentTo, sendCooldown, onSendAgain }: SendAgainProps) {
  switch (sendState) {
    case SendState.Sent:
      return (
        <Typography component='span' variant='bodyM' className={styles.sent}>
          Sent to {sentTo}!
        </Typography>
      )
    case SendState.Sending:
      return (
        <Typography component='span' variant='bodyM' className={styles.sending}>
          Sending...
        </Typography>
      )
    case SendState.Cooldown:
      return (
        <Typography component='span' variant='bodyM' className={styles.resend}>
          You may resend in {sendCooldown} seconds
        </Typography>
      )
    case SendState.CanSend:
      return (
        <Link underline='hover' onClick={onSendAgain} className={styles.sendAgain}>
          Send again
        </Link>
      )
  }
}
