import { Button } from '@compass/components'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Link, Popover, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import DateRangePicker from 'components/DateRangePicker/DateRangePicker'
import { Duration } from 'date-fns'
import { formatDatetime, ZonedDateRange } from 'features/commonUI'
import { DateRange } from 'helpers/dateRange'
import { formatTimezone } from 'helpers/format'
import { getLocale } from 'helpers/locale'
import { useRef, useState } from 'react'

import styles from './ChartDateRangePicker.module.scss'

interface ChartDateRangePickerProps {
  initialDateRange?: DateRange
  initialMinDate?: Date
  initialMaxDate?: Date
  maxRange?: Duration
  today: Date
  className?: string
  onOpen?: () => void
  onApplyRange: (range: { startDate: Date; endDate: Date }, label?: string) => void
  rangeInterpreter?: (range?: DateRange) => ZonedDateRange
  timezone?: string
}

export default function ChartDateRangePicker({
  initialDateRange,
  className,
  initialMinDate,
  initialMaxDate,
  maxRange,
  today,
  onOpen,
  onApplyRange,
  rangeInterpreter,
  timezone,
}: ChartDateRangePickerProps) {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [range, setRange] = useState(initialDateRange)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleDateRangeChange = (range: Required<DateRange>, isDefinedRange = false, label?: string) => {
    setRange(range)

    if (isDefinedRange) {
      setAnchorEl(null)
      onApplyRange(range, label)
    }
  }

  const handleOpen = () => {
    setAnchorEl(buttonRef.current)
    onOpen?.()
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (!range?.startDate || !range?.endDate) {
      return
    }

    onApplyRange({ startDate: range.startDate, endDate: range.endDate })
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const interpreted =
    rangeInterpreter != null && range != null ? (rangeInterpreter(range) as ZonedDateRange) : undefined

  return (
    <>
      <Button
        ref={buttonRef}
        aria-describedby={id}
        variant='secondary'
        onPress={handleOpen}
        data-testid='date-range-picker-button'
        className={className}
      >
        <DateRangeIcon fontSize='inherit' color='inherit' />
        {range
          ? `${range.startDate?.toLocaleDateString(getLocale(), {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })} - ${range.endDate?.toLocaleDateString(getLocale(), {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}`
          : 'Choose date range'}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRangePicker
          onChange={handleDateRangeChange}
          initialDateRange={range}
          initialMaxDate={initialMaxDate}
          initialMinDate={initialMinDate}
          maxRange={maxRange}
          today={today}
        />
        <div className={styles.actions}>
          {interpreted ? (
            <div className={styles.interpreted}>
              <Typography variant='body3' color='#0000008a'>
                {formatDatetime(interpreted.startDate?.toJSDate()!, undefined, timezone)} &ndash;{' '}
                {formatDatetime(interpreted.endDate?.toJSDate()!, undefined, timezone)}
              </Typography>{' '}
              {timezone ? (
                <Typography variant='body3'>
                  <Link href={AppRoute.ProfileSettings} underline='hover'>
                    {formatTimezone(timezone)}
                  </Link>
                </Typography>
              ) : null}
            </div>
          ) : null}
          <Button onPress={handleClose}>Apply</Button>
        </div>
      </Popover>
    </>
  )
}
