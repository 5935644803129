import 'swiper/swiper.min.css'

import { LinkButton } from '@compass/components'
import { CheckCircleOutline, DataUsage, InfoOutlined } from '@mui/icons-material'
import { Alert, Divider, Grid, Link, Skeleton, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { isBefore } from 'date-fns'
import { ConditionalOverduePaymentCallout } from 'features/billing'
import { appSettingNavItems, formatDate, Header, MainGrid, TabNavigation } from 'features/commonUI'
import { ChangePlanAction } from 'features/planManagement'
import { useCurrentSubscription } from 'features/subscription'
import { useRouteState } from 'hooks/router'
import {
  AnalyticsContext,
  BillingType,
  ContactSupportTopic,
  CouponDurationType,
  Discount,
  Promotion,
  SubscriptionStatus,
  SubscriptionTier,
  SubscriptionType,
} from 'models'
import { ampli } from 'models/ampli'
import { ReactNode, useCallback, useMemo, useState } from 'react'

import DashboardBanner from '../../../../components/DashboardBanner/DashboardBanner'
import StatusDialog from '../../../../components/StatusDialog/StatusDialog'
import Tag from '../../../../components/Tag/Tag'
import {
  DEFAULT_PRODUCT,
  IS_TEST_MODE,
  PRO_PLUS_99_BILLING_DATA,
  PRO_PLUS_BILLING_DATA,
  SUBSCRIPTION_ANALYTICS_PLAN_NAMES,
  SUBSCRIPTION_DISPLAY_PLANS,
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_TIER_TO_DISPLAY_PLAN,
  SubscriptionDisplayPlan,
} from '../../../../const'
import { date } from '../../../../helpers/data'
import { formatNum, formatPriceInDollars } from '../../../../helpers/format'
import { getSubscriptionDisplayPlan } from '../../../../helpers/subscription'
import { useAuth, useDocumentTitle, usePermissions, usePrice, useSubscription } from '../../../../hooks'
import { useUserContext } from '../../../../hooks/api/context'
import { useContactSupportMutation } from '../../../../hooks/api/support'
import { useUsers } from '../../../../hooks/api/users'
import { PlanDiscount } from '../../components/PlanDiscount/PlanDiscount'
import { PlanSelector } from '../../components/PlanSelector/PlanSelector'
import { PlanSummary } from '../../components/PlanSummary/PlanSummary'
import { PlanUsage } from '../../components/PlanUsage/PlanUsage'
import styles from './PlanManagementPage.module.scss'

export function PlanManagementPage() {
  useDocumentTitle('Plan')
  const { isImpersonating } = useAuth()

  const today = new Date()
  const routeState = useRouteState<{ didContactSupport?: boolean }>()
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription(subscriptionId)
  const { data: users, isLoading: isLoadingUsers } = useUsers()
  const { data: userContext, isLoading: isLoadingUserContext } = useUserContext()
  const { subscriptionPermissions } = usePermissions()
  const { price: proBillingData } = usePrice(DEFAULT_PRODUCT)

  const { mutate: contactSupport, isLoading: isContactingSupport } = useContactSupportMutation()

  const canCancelSubscription = subscription && subscriptionPermissions.canCancel(subscription)
  const plan = subscription ? SUBSCRIPTION_DISPLAY_PLANS[getSubscriptionDisplayPlan(subscription)].name : undefined
  const currentPlan =
    (!isLoadingSubscription && subscription && getSubscriptionDisplayPlan(subscription)) || SubscriptionDisplayPlan.Pro
  const isTrial = subscription?.type === SubscriptionType.TrialOnly
  const isTrialActive = subscription?.status === SubscriptionStatus.Trialing
  const isActive = subscription?.status === SubscriptionStatus.Active
  const isRestricted = subscription?.status === SubscriptionStatus.Restricted

  const promoTiers = subscription?.promotions?.reduce((acc, promo) => {
    if (promo?.priceLookupKey) {
      acc[SUBSCRIPTION_TIER_TO_DISPLAY_PLAN[promo?.priceLookupKey]] = promo
    }
    return acc
  }, {} as Record<SubscriptionDisplayPlan, Promotion>)

  let usageLimit: number | undefined
  if (isTrialActive) {
    usageLimit = Infinity
  } else if (subscription?.usageQuota?.quota) {
    usageLimit = subscription?.usageQuota?.quota
  } else if (subscription?.latestTier === SubscriptionTier.Pro) {
    usageLimit = proBillingData?.prepaidQuantity
  } else if (subscription?.latestTier === SubscriptionTier.Plus99) {
    usageLimit = PRO_PLUS_99_BILLING_DATA.prepaidQuantity
  } else {
    usageLimit = PRO_PLUS_BILLING_DATA.prepaidQuantity
  }
  const relatedVisitorsUsage = subscription?.relatedVisitorsQuota?.billed
  const relatedVisitorsLimit = subscription?.relatedVisitorsQuota?.quota
  const usageLimitLabel = (
    <>
      of {isLoadingSubscription ? <Skeleton width={48} sx={{ display: 'inline-block' }} /> : formatNum(usageLimit ?? 0)}
    </>
  )
  const relatedVisitorsLimitLabel =
    relatedVisitorsLimit === 0 ? (
      'Related Visitors API calls (Trial Period)'
    ) : (
      <>
        of{' '}
        {isLoadingSubscription ? (
          <Skeleton width={48} sx={{ display: 'inline-block' }} />
        ) : (
          formatNum(relatedVisitorsLimit ?? 0)
        )}
        {' Related Visitors API calls'}
      </>
    )
  const billingUnit = subscription?.billingType === BillingType.UniqueVisitors ? 'Unique visitors ' : 'API calls'
  const currentUsage = subscription?.usageQuota?.billed ?? 0
  const isEnterprise = subscription?.type === SubscriptionType.Prepaid
  const isSubscriptionPlan = subscription?.type === SubscriptionType.Paid
  const isPOC = subscription?.type === SubscriptionType.ProofOfConcept
  const email = userContext?.email ?? ''
  const willDowngrade = !!(
    subscription?.downgradeScheduledAt && isBefore(Date.now(), date(subscription?.downgradeScheduledAt))
  )
  const hasFinishedTrial =
    subscription &&
    subscription.type === SubscriptionType.TrialOnly &&
    subscription.status === SubscriptionStatus.Canceled

  const hasFinishedPOC =
    subscription &&
    subscription.type === SubscriptionType.ProofOfConcept &&
    subscription.status === SubscriptionStatus.Restricted &&
    subscription.currentPeriodEndsAt &&
    today > new Date(subscription.currentPeriodEndsAt)

  const isLoading = isLoadingSubscription || isLoadingUsers || isContactingSupport || isLoadingUserContext
  const isCancelingOrCanceled = !!(
    !hasFinishedTrial &&
    !hasFinishedPOC &&
    (subscription?.cancelAt || subscription?.status === SubscriptionStatus.Canceled)
  )
  const appliedDiscount = subscription?.discounts?.reduce<Discount | undefined>((acc, d) => {
    // Find discount with duration === 'repeating' and with the most percents off
    return d.coupon?.duration === CouponDurationType.Repeating &&
      (acc === undefined || (d.coupon?.percentOff ?? 0) > (acc.coupon?.percentOff ?? 0))
      ? d
      : acc
  }, undefined)

  const trackPlanChangeIntent = useMemo(
    () => (oldPlan: SubscriptionDisplayPlan, newPlan: SubscriptionDisplayPlan) => {
      if (newPlan === SubscriptionDisplayPlan.Free) {
        // This should never happen, but typescript is typescript.
        return
      }
      const isDowngrade = !isTrial && newPlan < oldPlan
      if (isDowngrade) {
        ampli.billingPlanDowngradeConsidered({
          newBillingPlanName: SUBSCRIPTION_ANALYTICS_PLAN_NAMES[newPlan],
        })
      } else {
        ampli.billingPlanUpgradeConsidered({
          context: AnalyticsContext.AccountSettings,
          newBillingPlanName: SUBSCRIPTION_ANALYTICS_PLAN_NAMES[newPlan],
        })
      }
    },
    [isTrial]
  )

  const trackCancelIntent = () => {
    const proPlusPlan = SUBSCRIPTION_DISPLAY_PLANS[SubscriptionDisplayPlan.Plus].name
    ampli.subscriptionCancellationConsidered({
      billingPlanName: plan === proPlusPlan ? 'Fingerprint Pro Plus' : 'Fingerprint Pro',
    })
  }

  const buildStatusLabel = () => {
    if (isPOC && isActive) {
      return 'Proof of Concept'
    } else if (isPOC && isRestricted) {
      return 'Restricted'
    } else if (hasFinishedTrial) {
      return 'Trial ended'
    } else if (subscription) {
      return SUBSCRIPTION_STATUSES[subscription.status].name
    }

    return ''
  }

  const getNextPaymentInfo = () => {
    if (isCancelingOrCanceled && subscription?.currentPeriodEndsAt) {
      return {
        key: 'Last payment on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'short'),
        action: 'View Invoices',
        actionTo: AppRoute.Billing,
      }
    } else if (isSubscriptionPlan && subscription?.currentPeriodEndsAt) {
      return {
        key: 'Next payment on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'short'),
      }
    } else if (isEnterprise && subscription?.currentPeriodEndsAt) {
      return {
        key: 'Renews on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'short'),
        action: 'Extend',
        onAction: () => handleContactSupport(ContactSupportTopic.ExtendContract),
      }
    } else if (isTrial && subscription?.currentPeriodEndsAt) {
      return {
        key: subscription?.status === SubscriptionStatus.Trialing ? 'Trial ends on' : 'Trial ended on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'short'),
        action: 'Upgrade now',
        actionTo: buildRoute(AppRoute.ChangePlan, { subscriptionId }, { action: ChangePlanAction.UpgradeToPlus99 }),
      }
    } else if (isPOC && subscription?.currentPeriodEndsAt) {
      return {
        key: hasFinishedPOC ? 'Trial ended on' : 'Trial ends on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'short'),
      }
    }

    // Default hidden type
    return { key: 'ignorable', hidden: true }
  }

  const status = buildStatusLabel()

  let planBadge: ReactNode | undefined
  if (willDowngrade) {
    planBadge = (
      <Tag
        label={`Downgrades ${formatDate(date(subscription?.downgradeScheduledAt), 'short')}`}
        tooltip={`The subscription's plan will downgrade to Fingerprint Pro on ${formatDate(
          date(subscription?.downgradeScheduledAt)
        )}`}
        color='yellow'
        compact
        round
      />
    )
  } else if (isPOC) {
    planBadge = (
      <Tag
        label={`Proof of concept`}
        tooltip={`Your app is trialing Fingerprint Enterprise as a proof of concept.`}
        color='yellow'
        compact
        round
      />
    )
  } else if (subscription?.billingType === BillingType.UniqueVisitors) {
    planBadge = (
      <Tag
        label='Unique visitors billing'
        tooltip={`You are billed per unique visitorId.${
          !isEnterprise ? ' Upgrade or contact support' : ' Contact support'
        } to be billed per API call.`}
        color='yellow'
        compact
        round
      />
    )
  } else if (appliedDiscount) {
    const date = new Date(appliedDiscount.createdAt)
    date.setMonth(date.getMonth() + (appliedDiscount?.coupon?.durationInMonths ?? 0))

    planBadge = (
      <Tag
        label='Discount applied'
        tooltip={`Your plan has a ${appliedDiscount?.coupon?.percentOff}% discount in price until ${formatDate(date)}.`}
        color='blue'
        compact
        round
      />
    )
  }

  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(routeState?.didContactSupport ?? false)

  const handleContactSupport = useCallback(
    (topic: ContactSupportTopic) => {
      contactSupport(
        {
          data: {
            email,
            message: `The user with email ${email} requested support through the plan page for ${subscriptionId}.`,
            formDetails: topic,
          },
        },
        {
          onSuccess: () => {
            setIsSupportDialogOpen(true)
          },
        }
      )
    },
    [contactSupport, setIsSupportDialogOpen, email, subscriptionId]
  )

  const isProPlus400Tier =
    subscription?.status === SubscriptionStatus.Active &&
    subscription?.type === SubscriptionType.Paid &&
    currentPlan === SubscriptionDisplayPlan.Plus

  const plans = useMemo(
    () => [
      {
        plan: SubscriptionDisplayPlan.Pro,
        promotion: promoTiers?.[SubscriptionDisplayPlan.Pro],
        valueLabel: `Basic device identification.`,
        // Pro trials can still be upgraded.
        action: 'Upgrade to Pro',
        actionHref: buildRoute(
          AppRoute.ChangePlan,
          { subscriptionId },
          {
            action:
              !isTrial && currentPlan > SubscriptionDisplayPlan.Pro
                ? ChangePlanAction.DowngradeToPro
                : ChangePlanAction.UpgradeToPro,
          }
        ),

        // November Pricing Experiment (Pro plan is disabled in November 2023)
        // https://www.notion.so/fingerprintjs/November-Pricing-Experiment-acc992ef0092444db2fa07b5f12c3c7b
        isHidden: currentPlan !== SubscriptionDisplayPlan.Pro && !IS_TEST_MODE && !isImpersonating,
        locked: willDowngrade || isCancelingOrCanceled,
        onAction: () => trackPlanChangeIntent(currentPlan, SubscriptionDisplayPlan.Pro),
        priceSubtitleContent: <>100,000 API calls incl. then $2 per 1000</>,
      },

      // Q1 2024 Pricing Experiment
      // https://www.notion.so/fingerprintjs/Pro-Plus-Q1-2024-Pricing-Update-e4904343c1eb44d198cf93c41d919e71
      {
        plan: SubscriptionDisplayPlan.Plus99,
        valueLabel: `Everything you need to get started.`,
        action: isProPlus400Tier ? 'Switch to $99/MO' : 'Upgrade to Pro Plus',
        actionHref: buildRoute(AppRoute.ChangePlan, { subscriptionId }, { action: ChangePlanAction.UpgradeToPlus99 }),
        locked: willDowngrade || isCancelingOrCanceled,
        onAction: () => trackPlanChangeIntent(currentPlan, SubscriptionDisplayPlan.Plus99),
        isHighlighted: currentPlan !== SubscriptionDisplayPlan.Plus99,
        topBadge:
          currentPlan !== SubscriptionDisplayPlan.Plus99
            ? currentPlan === SubscriptionDisplayPlan.Plus
              ? 'Current plan - new pricing available'
              : undefined
            : undefined,
        priceSubtitleContent: <>20,000 API calls incl. then $4 per 1000</>,
      },
      {
        plan: SubscriptionDisplayPlan.Plus,
        promotion: promoTiers?.[SubscriptionDisplayPlan.Plus],
        valueLabel: `Everything you need to get started.`,
        action: 'Upgrade to Pro Plus',
        actionHref: buildRoute(AppRoute.ChangePlan, { subscriptionId }, { action: ChangePlanAction.UpgradeToPlus }),
        // Q1 2024 Pricing Experiment ($400 Pro Plus is hidden unless it's an active plan)
        // https://www.notion.so/fingerprintjs/Pro-Plus-Q1-2024-Pricing-Update-e4904343c1eb44d198cf93c41d919e71
        isHidden: true,
        locked: willDowngrade || isCancelingOrCanceled,
        onAction: () => trackPlanChangeIntent(currentPlan, SubscriptionDisplayPlan.Plus),
        priceSubtitleContent: <>100,000 API calls incl. then $4 per 1000</>,
      },
      {
        plan: SubscriptionDisplayPlan.Enterprise,
        promotion: promoTiers?.[SubscriptionDisplayPlan.Enterprise],
        valueLabel: `Build your own plan.`,
        action: 'Request pricing',
        actionHref: AppRoute.ContactSalesEnterprise,
        locked: willDowngrade || isCancelingOrCanceled,
        onAction: () => {
          trackPlanChangeIntent(currentPlan, SubscriptionDisplayPlan.Enterprise)
          ampli.appCtaClicked({ 'App Page Path': AppRoute.SubscriptionPlan, ctaName: 'Contact us' })
        },
      },
    ],
    [
      subscriptionId,
      currentPlan,
      isTrial,
      promoTiers,
      isCancelingOrCanceled,
      trackPlanChangeIntent,
      willDowngrade,
      isImpersonating,
      isProPlus400Tier,
    ]
  )

  if (isSupportDialogOpen) {
    return (
      <>
        <Header title='App Settings' />
        <TabNavigation items={appSettingNavItems} />
        <MainGrid>
          <Grid item xs={12}>
            <StatusDialog
              icon={<CheckCircleOutline color='primary' />}
              title='We received your request!'
              subtitle='Support will reach out to you soon.'
              primaryAction={{
                name: 'Done',
                onAction: () => setIsSupportDialogOpen(false),
              }}
            />
          </Grid>
        </MainGrid>
      </>
    )
  }

  return (
    <>
      <Header title='App Settings' />
      <TabNavigation items={appSettingNavItems} />
      <MainGrid>
        <Grid item sm={12} container spacing={3}>
          <Grid item xs={12}>
            <ConditionalOverduePaymentCallout subscriptionId={subscription?.id} />
          </Grid>
          {isCancelingOrCanceled && (
            <Grid item xs={12}>
              {subscription.status === SubscriptionStatus.Canceled ? (
                <Alert severity='error' icon={<InfoOutlined />}>
                  Your subscription has been canceled. If you want to resume it,{' '}
                  <Link
                    component='button'
                    onClick={() => handleContactSupport(ContactSupportTopic.ResumeSubscription)}
                    underline='none'
                    target='_blank'
                  >
                    contact support
                  </Link>
                  .
                </Alert>
              ) : (
                <Alert severity='error' icon={<InfoOutlined />}>
                  Your subscription will be canceled on {formatDate(date(subscription.cancelAt))}. If you want to resume
                  it,{' '}
                  <Link
                    component='button'
                    onClick={() => handleContactSupport(ContactSupportTopic.ResumeSubscription)}
                    underline='none'
                    target='_blank'
                  >
                    contact support
                  </Link>
                  .
                </Alert>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <PlanSummary
              entries={[
                { key: 'Subscription status', value: status },
                {
                  key: 'Plan',
                  value: plan,
                  badge: planBadge,
                },
                getNextPaymentInfo(),
                {
                  key: 'Members',
                  value: users ? formatNum(users.length) : undefined,
                  action: 'Invite team member',
                  actionTo: AppRoute.UserManagement,
                },
              ]}
              isLoading={isLoading}
            />
            <Divider />
          </Grid>

          {!isLoading && subscription?.type !== SubscriptionType.Prepaid && (
            <>
              <Grid item xs={12}>
                <Typography variant='semiBody2' component='h2'>
                  Plans
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {appliedDiscount ? (
                  <>
                    <PlanDiscount />
                    <Divider />
                  </>
                ) : (
                  <PlanSelector currentPlan={currentPlan} isTrial={isTrial} plans={plans} isLoading={isLoading} />
                )}
              </Grid>
            </>
          )}

          {currentUsage > 0 && (
            <>
              {!isLoading && isEnterprise && (
                <Grid item xs={12}>
                  <DashboardBanner
                    icon={<DataUsage color='primary' />}
                    title='Are you expecting increase in usage?'
                    action='Contact Premium Support'
                    onClickAction={() => handleContactSupport(ContactSupportTopic.IncreaseUsage)}
                    variant='warning'
                    wideButton
                  >
                    Reach out to discuss your plan options.
                  </DashboardBanner>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant='semiBody2' component='h2'>
                  Current period usage
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <PlanUsage
                  entries={[
                    {
                      key: 'usage',
                      label: (
                        <>
                          {usageLimit !== Infinity && usageLimitLabel} {billingUnit}
                        </>
                      ),
                      value: formatNum(currentUsage),
                    },
                    {
                      key: 'projected',
                      label: 'Projected usage',
                      value: formatNum(subscription?.projectedUsage ?? 0),
                      hidden: !subscription?.projectedUsage,
                    },
                    {
                      key: 'upcoming',
                      label: 'Amount due',
                      value: appliedDiscount
                        ? formatPriceInDollars(
                            (subscription?.upcomingAmount ?? 0) * (1 - (appliedDiscount.coupon?.percentOff ?? 0) / 100)
                          )
                        : formatPriceInDollars(subscription?.upcomingAmount ?? 0),
                      hidden: !subscription?.upcomingAmount,
                      badge: appliedDiscount && {
                        text: (appliedDiscount.coupon?.percentOff ?? 0) + '%',
                        tooltip: `Without a discount, the original amount due would be ${formatPriceInDollars(
                          subscription?.upcomingAmount ?? 0
                        )}.`,
                      },
                    },
                  ]}
                  total={usageLimit}
                  current={currentUsage}
                  projected={subscription?.projectedUsage}
                  action={isEnterprise ? 'Extend limits' : undefined}
                  onAction={() => handleContactSupport(ContactSupportTopic.ExtendContract)}
                  isLoading={isLoading}
                />
              </Grid>
              {!!subscription?.relatedVisitorsQuota && (
                <Grid item xs={12} md={6}>
                  <PlanUsage
                    entries={[
                      {
                        key: 'Related Visitors API calls',
                        label: <>{relatedVisitorsLimit !== Infinity && relatedVisitorsLimitLabel}</>,
                        value: formatNum(relatedVisitorsUsage ?? 0),
                      },
                    ]}
                    total={relatedVisitorsLimit || usageLimit}
                    current={relatedVisitorsUsage}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
            </>
          )}

          {canCancelSubscription && (
            <>
              <Divider className={styles.dangerZoneDivider} />
              <Grid xs={12} item>
                <Typography variant='semiBody2' component='h2' className={styles.dangerZoneHeader}>
                  Danger Zone
                </Typography>
                <LinkButton
                  variant='destructive'
                  data-testid='cancelSubButton'
                  className={styles.dangerZoneButton}
                  onPress={() => trackCancelIntent()}
                  href={buildRoute(AppRoute.SubscriptionCancellationSurvey, { subscriptionId })}
                >
                  Cancel subscription
                </LinkButton>
              </Grid>
            </>
          )}
        </Grid>
      </MainGrid>
    </>
  )
}
