import { Button } from '@compass/components'
import { DeleteOutline, Edit as EditIcon } from '@mui/icons-material'
import { Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'
import { USER_ROLES } from 'const'
import { AccountTransferRequest, User, UserRole } from 'models'

import { UserInvitedSince } from '../UserInvitedSince/UserInvitedSince'
import { ManagedUser } from '../UserManagement/UserManagementData'
import { UserManagementActionMenu } from '../UserManagementActionMenu/UserManagementActionMenu'
import styles from './UsersCompactTable.module.scss'

export interface UsersCompactTableProps {
  currentUser?: User
  users?: ManagedUser[]
  onEditUser: (userId: string) => void
  onDeleteUser: (userId: string) => void

  pendingAccountTransfer?: AccountTransferRequest
  onTransferOwnership: (user: ManagedUser) => void
  onCancelOwnershipTransfer: (user: ManagedUser) => void
}

const ROLE_STYLING_MAP: Record<UserRole, string> = {
  [UserRole.Admin]: styles.roleAdmin,
  [UserRole.Administrator]: styles.roleAdministrator,
  [UserRole.Owner]: styles.roleOwner,
  [UserRole.Member]: styles.roleMember,
  [UserRole.ReadOnly]: styles.roleReadOnly,
}

export function UsersCompactTable({
  users,
  currentUser,
  onEditUser,
  onDeleteUser,

  pendingAccountTransfer,
  onTransferOwnership,
  onCancelOwnershipTransfer,
}: UsersCompactTableProps) {
  return (
    <table style={{ width: '100%' }}>
      {!users ? (
        <RowSkeleton />
      ) : (
        users.map((user) => {
          const { canReceiveOwnership, isPendingTransfer } = user
          const shouldShowExtraActions = canReceiveOwnership || isPendingTransfer

          return (
            <tr key={user.id} className={styles.userRow}>
              <td className={styles.userCell}>
                <Typography variant='bodyMMedium' className={styles.userName}>
                  {user.name} {currentUser?.id === user.id && '(you)'}
                </Typography>

                <div className={styles.property}>
                  <Typography variant='bodyM' className={styles.propertyValue}>
                    {user.email}
                  </Typography>
                  <Typography variant='bodyM' className={styles.propertyName}>
                    Email
                  </Typography>
                </div>
              </td>

              <td className={styles.userCell}>
                <div className={styles.property}>
                  <Typography variant='bodySMedium' className={styles.propertyValue}>
                    <UserInvitedSince pendingAccountTransfer={pendingAccountTransfer} user={user} />
                  </Typography>
                  <Typography variant='bodyS' className={styles.propertyName}>
                    Since
                  </Typography>
                </div>
              </td>

              <td className={styles.userCell}>
                <div className={styles.property}>
                  <Typography variant='bodySMedium' className={clsx(styles.propertyValue, ROLE_STYLING_MAP[user.role])}>
                    {USER_ROLES[user.role].name}
                  </Typography>
                  <Typography variant='bodyS' className={styles.propertyName}>
                    Role
                  </Typography>
                </div>
              </td>

              <td className={styles.userCell}>
                <div className={styles.actions}>
                  {user.isEditable && (
                    <Button
                      variant='secondary'
                      isIcon
                      aria-label='Edit user'
                      onPress={() => onEditUser(user.id)}
                      className={styles.actionButton}
                    >
                      <EditIcon fontSize='inherit' />
                    </Button>
                  )}

                  {user.isDeletable && (
                    <Button
                      variant='secondary'
                      isIcon
                      aria-label='Delete user'
                      onPress={() => onDeleteUser(user.id)}
                      className={styles.actionButton}
                    >
                      <DeleteOutline fontSize='inherit' />
                    </Button>
                  )}

                  {shouldShowExtraActions && (
                    <UserManagementActionMenu
                      isPendingTransfer={isPendingTransfer}
                      onTransfer={() => onTransferOwnership(user)}
                      onCancelTransfer={() => onCancelOwnershipTransfer(user)}
                      className={styles.actionButton}
                      variant='secondary'
                    />
                  )}
                </div>
              </td>
            </tr>
          )
        })
      )}
    </table>
  )
}

function RowSkeleton() {
  return (
    <tr data-testid='user-management-skeleton'>
      {Array(3)
        .fill(undefined)
        .map((_, index) => (
          <td key={index}>
            <Skeleton height='32px' />
          </td>
        ))}
    </tr>
  )
}
