import { Button } from '@compass/components'
import { Paper, Typography } from '@mui/material'
import { useToast } from 'hooks'
import { useCurrentUser, useUserUpdateMutation } from 'hooks/api/users'
import { TimeFormData, TimeSettings } from 'models'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import ControlledSelect from '../../../components/ControlledSelect/ControlledSelect'
import Loader from '../../../components/Loader/Loader'
import { GenericError, TIMEZONES } from '../../../const'
import { formatTimezone } from '../../../helpers/format'
import styles from './SettingsForms.module.scss'

export function TimeForm() {
  const { showToast } = useToast()
  const { data: currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser()
  const {
    mutate: updateTimeSettings,
    isLoading: isUpdatingTimeSettings,
    error: updateTimeSettingsError,
  } = useUserUpdateMutation()

  const handleUpdateTimeSettings = useCallback(
    (payload: TimeFormData) =>
      updateTimeSettings(
        { data: payload, params: { userId: currentUser?.id ?? '' } },
        {
          onSuccess: () => {
            showToast({
              message: 'Successfully updated time settings.',
              severity: 'success',
            })
          },
        }
      ),
    [currentUser?.id, showToast, updateTimeSettings]
  )

  return (
    <TimeFormContent
      onSubmit={handleUpdateTimeSettings}
      currentUser={currentUser}
      error={updateTimeSettingsError}
      isLoading={isLoadingCurrentUser || isUpdatingTimeSettings}
    />
  )
}

export interface TimeFormContentProps {
  currentUser?: TimeSettings
  isLoading?: boolean
  error?: GenericError | null
  onSubmit: (data: TimeFormData) => void
}

export function TimeFormContent({ onSubmit, currentUser, error, isLoading }: TimeFormContentProps) {
  const formMethods = useForm({
    defaultValues: currentUser,
    shouldUnregister: true,
  })
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods

  useEffect(() => {
    reset(currentUser)
  }, [currentUser, reset])

  return (
    <Paper className={styles.card}>
      <Typography variant='h2' className={styles.cardTitle}>
        Time
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <FormProvider {...formMethods}>
          <ControlledSelect
            title='Choose your timezone'
            name='timezone'
            defaultValue={currentUser?.timezone ?? 'UTC'}
            items={TIMEZONES.map((timezone) => ({ value: timezone, displayValue: formatTimezone(timezone) }))}
            rules={{ required: 'Timezone required.' }}
            error={error}
            className={styles.input}
          />

          <Button type='submit' aria-label='Save Time Settings' isDisabled={!isDirty} className={styles.button}>
            Save
          </Button>
        </FormProvider>
      </form>

      {isLoading ? <Loader testId='time-form-loader' /> : null}
    </Paper>
  )
}
