import { Button } from '@compass/components'
import { PersonAdd } from '@mui/icons-material'
import { Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import Loader from 'components/Loader/Loader'
import { useCurrentUser } from 'hooks/api/users'
import { AccountTransferRequest, User, UserRole, UserStatus } from 'models'
import { useMemo } from 'react'

import { UsersCompactTable } from '../UsersCompactTable/UsersCompactTable'
import { UsersList } from '../UsersList/UsersList'
import { UsersTable } from '../UsersTable/UsersTable'
import styles from './UserManagement.module.scss'
import { ManagedUser } from './UserManagementData'

export interface UserManagementPermissions {
  edit: boolean
  delete: boolean
  transferOwnership: boolean
}

export interface UserManagementProps {
  users?: User[]
  isLoading?: boolean
  isSsoEnabled?: boolean
  permissions: UserManagementPermissions
  onOpenAddDialog: () => void
  onOpenEditDialog: (userId: string) => void
  onOpenDeleteDialog: (userId: string) => void

  pendingAccountTransfer?: AccountTransferRequest
  onTransferAccount: (target: ManagedUser) => void
  onCancelTransferAccount: (target: ManagedUser) => void
}

export function UserManagement({
  users,
  isLoading,
  permissions,
  onOpenDeleteDialog,
  onOpenAddDialog,
  onOpenEditDialog,
  pendingAccountTransfer,
  onTransferAccount,
  onCancelTransferAccount,
}: UserManagementProps) {
  const isReadonly = !permissions.edit && !permissions.delete
  const { data: currentUser } = useCurrentUser()

  const theme = useTheme()

  const displayFullTable = useMediaQuery(theme.breakpoints.up('md'))
  const displayCompactTable = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const displayList = useMediaQuery(theme.breakpoints.down('sm'))

  const managedUsers = useMemo<ManagedUser[] | undefined>(
    () =>
      isLoading || !users
        ? undefined
        : users.map((user) => ({
            ...user,
            isEditable: permissions.edit,
            isDeletable: permissions.delete && currentUser?.id !== user.id && user.role !== UserRole.Owner,
            canReceiveOwnership:
              !pendingAccountTransfer &&
              permissions.transferOwnership &&
              user.role !== UserRole.Owner &&
              user.status === UserStatus.Active,
            isPendingTransfer: permissions.transferOwnership && pendingAccountTransfer?.newOwnerId === user.id,
          })),
    [isLoading, users, permissions, currentUser, pendingAccountTransfer]
  )

  return (
    <Paper className={styles.root}>
      <div className={styles.headingContainer}>
        <div>
          <Typography id='users' component='h2' variant='h3' className={styles.heading}>
            Users
          </Typography>
          <Typography variant='bodyM'>
            {isReadonly ? 'Review' : 'Manage'} access to the Fingerprint Dashboard.
          </Typography>
        </div>
        <div>
          {!isReadonly && (
            <Button onPress={onOpenAddDialog} isDisabled={isLoading} className={styles.button}>
              <PersonAdd fontSize='inherit' />
              Add people
            </Button>
          )}
        </div>
      </div>

      {displayFullTable && (
        <UsersTable
          currentUser={currentUser}
          onEditUser={onOpenEditDialog}
          onDeleteUser={onOpenDeleteDialog}
          users={managedUsers}
          pendingAccountTransfer={pendingAccountTransfer}
          onTransferOwnership={onTransferAccount}
          onCancelOwnershipTransfer={onCancelTransferAccount}
        />
      )}

      {displayCompactTable && (
        <UsersCompactTable
          currentUser={currentUser}
          onEditUser={onOpenEditDialog}
          onDeleteUser={onOpenDeleteDialog}
          users={managedUsers}
          pendingAccountTransfer={pendingAccountTransfer}
          onTransferOwnership={onTransferAccount}
          onCancelOwnershipTransfer={onCancelTransferAccount}
        />
      )}

      {displayList && (
        <UsersList
          currentUser={currentUser}
          onEditUser={onOpenEditDialog}
          onDeleteUser={onOpenDeleteDialog}
          users={managedUsers}
          pendingAccountTransfer={pendingAccountTransfer}
          onTransferOwnership={onTransferAccount}
          onCancelOwnershipTransfer={onCancelTransferAccount}
        />
      )}

      {isLoading && <Loader testId='user-management-loader' />}
    </Paper>
  )
}
