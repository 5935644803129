import { LinkButton } from '@compass/components'
import { ArrowForward } from '@mui/icons-material'
import { Paper, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { DOCS_BOTD_QUICK_START_URL, PRODUCT_BOTD } from 'const'
import { Header, MainContent } from 'features/commonUI'
import { ChangePlanAction } from 'features/planManagement'
import { useCurrentSubscription } from 'features/subscription'

import styles from './BotDIntroduction.module.scss'
import BotSvg from './goodBotBadBot.svg'

export default function BotDIntroduction() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  return (
    <>
      <Header title='Bot Detection' />
      <MainContent>
        <div className={styles.block}>
          <Paper className={styles.card}>
            <div className={styles.botImageWrapper}>
              <BotSvg className={styles.botImage} />
            </div>
            <div className={styles.details}>
              <Typography variant={'h5'} component={'h5'} className={styles.cardTitle}>
                Identify Sophisticated Bots in Real-Time.
              </Typography>
              <Typography variant='body1' className={styles.description}>
                Identify Malicious and Friendly Bots that execute JavaScript. Protect your website and users from fraud
                schemes with a few lines of code and one API call.
              </Typography>
              <LinkButton
                href={buildRoute(AppRoute.ChangePlan, { subscriptionId }, { action: ChangePlanAction.UpgradeToPlus99 })}
                variant='primary'
                size='lg'
                className={styles.getStartedButton}
              >
                Get started with Fingerprint Pro Plus
              </LinkButton>

              <div className={styles.info}>
                <InfoLink name='Developer guide' href={DOCS_BOTD_QUICK_START_URL} />
                <InfoLink name='Detect bots' href={PRODUCT_BOTD} />
              </div>
            </div>
          </Paper>
        </div>
      </MainContent>
    </>
  )
}

interface InfoLinkProps {
  name: string
  href: string
}

function InfoLink({ name, href }: InfoLinkProps) {
  return (
    <a href={href} target='_blank' className={styles.infoLink} rel='noreferrer'>
      <Typography variant='semiBody2' component='span'>
        {name}
      </Typography>
      <Typography variant='body3' component='span' className={styles.label}>
        Learn more
      </Typography>

      <ArrowForward className={styles.icon} />
    </a>
  )
}
